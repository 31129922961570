@import "variables";

@each $name, $color in $c_theme-colors {
  .background-#{$name} {
    background: $color !important;
  }
}

button.btn.btn-primary {
  --bs-btn-font-weight: 600;
  --bs-btn-font-family: 'Montserrat', sans-serif;
  --bs-btn-font-size: 14px;
  --bs-btn-color: #E0EDFA;
}

.modal.show {
  backdrop-filter: blur(8px);
}

.json-view {
  background: rgb(19, 25, 37);
  width: 100%;
  padding: 8px;
}
