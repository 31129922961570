@import "styles/variables";
@import "styles/mixins";

.leaderboard-table {

  &-row {
    display: flex;
    height: 50px;
    padding: 0 14px 3px 14px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background: $light;
  }

  &-place {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $headline-white;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    text-transform: uppercase;
  }

  &-left-side {
    display: flex;
    align-items: center;

    p {
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      text-transform: uppercase;
      margin: 0 0 0 19px;

      @include tablet-max {
        margin: 0 0 0 10px;
      }
    }
  }

  &-center {
    display: flex;
    width: 150px;
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    text-transform: uppercase;

    @include tablet-max {
      width: 135px;
    }

    @include mobile {
      width: auto;
    }
  }

  &-right-side {
    color: $primary;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    text-transform: uppercase;
  }
}
