@import "styles/variables";
@import "styles/mixins";

.leader-board {
  &-card {
    width: 297px;
    height: 417px;
    border-radius: 10px;
    border: 1px solid rgba($stroke-light, 0.20);
    box-shadow: 0 10px 60px 0 $deep-night;

    &-1 {
      height: 475px;
      order: 2;
      background: linear-gradient(159deg, rgba(253, 206, 49, 0.48) -19.58%, rgba(176, 97, 52, 0.00) 58.54%), #131A2D;

      @include tablet-max {
        height: 417px;
        order: unset;
      }
    }

    &-2 {
      order: 1;
      background: linear-gradient(159deg, rgba(225, 225, 225, 0.38) -23.46%, rgba(19, 26, 45, 0.00) 58.55%), #131A2D;

      @include tablet-max {
        order: unset;
      }
    }

    &-3 {
      order: 3;
      background: linear-gradient(159deg, rgba(182, 105, 35, 0.38) -23.46%, rgba(19, 26, 45, 0.00) 58.55%), #131A2D;

      @include tablet-max {
        order: unset;
      }
    }

    &-winner {
      width: 66px;
      height: 44px;
      margin-bottom: 20px;

      @include tablet-max {
        margin-bottom: 12px;
      }
    }

    &-logo {
      width: 121px;
      height: 121px;
      border-radius: 50%;
    }

    &-user {
      color: $headline-white;
      font-family: $font-primary;
      font-size: 20px;
      font-weight: 700;
      line-height: 100%;
      margin: 24px 0 24px;

      &-first {
        margin-bottom: 31px;

        @include tablet-max {
          margin: 6px 0 6px;
        }
      }
    }

    &-wagered {
      display: flex;
      flex-direction: column;
      align-items: center;

      h5 {
        color: $headline-white;
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 600;
        line-height: 100%;
        text-transform: uppercase;
        margin: 0;
      }

      h4 {
        color: $headline-white;
        font-family: $font-primary;
        font-size: 20px;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;
        margin: 16px 0 0;

        span {
          color: $green;
          font-family: $font-primary;
          font-size: 20px;
          font-weight: 700;
          line-height: 100%;
          text-transform: uppercase;
        }
      }
    }

    &-btn {
      width: 248px;
      height: 53px;
      font-size: 28px;
      font-weight: 800;
      margin: 4px 0 0;
      display: inline-flex;
      padding: 10px 32px;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background: $primary;
      color: $headline-color;
      font-family: $font-primary;
      line-height: 150%;
      border: none;
      user-select: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      text-transform: uppercase;

      &:hover {
        background: #743CEB;
      }
    }

    &-icon {
      width: 31px;
      height: 16px;
      margin: 24px 0 0;
    }

    &-footer {
      width: 100%;
      padding: 24px 23px;
      border-top: 1px solid rgba($stroke-light, 0.20);
    }

    &-place {
      position: absolute;
      display: flex;
      justify-content: center;
      top: 0;
      left: 24px;
      width: 39px;
      height: 59px;

      img {
        position: absolute;
        top: 0;
        left: 0;
      }

      span {
        position: absolute;
        top: 4px;
        color: $light;
        font-family: $font-primary;
        font-size: 32px;
        font-weight: 700;
        line-height: 125%;
      }
    }
  }
}
