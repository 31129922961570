@import "styles/variables";

.aside-collapse {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  background: $block-light;
  color: $headline-white;
  cursor: pointer;

  &-header {
    padding: 8px 16px;
    height: 50px;
    align-items: center;
  }

  &-content {
    width: 100%;
  }

  &-trigger {
    width: 100%;
    display: flex;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
    background: transparent;
    color: $headline-white;
    border: none;
  }

  &-img {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba($secondary, .25);
    border-radius: 50%;

  }

  &-nav-link {
    width: 100%;
    display: flex;
    height: 50px;
    align-items: center;
    align-self: stretch;
    text-decoration: none;
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
    border-bottom: 1px solid $deep-night;
    background: $light;
    padding: 8px 16px;

    &-active {
      background: $block-hover;
      color: $primary;
    }
  }

  &-active {
    rotate: 180deg;
  }
}

