@import "styles/variables";
@import "styles/mixins";

.custom-header {
  background: $light;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
  padding: 0 25px;

  &-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    position: sticky;
    z-index: 5;
    top: 0;
    left: 0;
  }

  &-mobile {
    min-height: 102px;
    background: $light;
    display: flex;

    &-block {
      height: 102px;
      padding: 0 32px;
      border-bottom: 1px solid $bg-light;
      background: $light;

      :nth-child(3) {
        margin-left: auto;
      }
    }

    &-toggle {
      border: 0;
      outline: 0;
      background: $deep-night;
      border-radius: 8px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      margin-left: 10px;
    }

    &-content {
      width: 100%;
      height: 100vh;

      &-cell {
        width: 100%;
        height: 45px;
      }
    }
  }

  &-logo {
    margin-right: 5px;
    height: 40px;

    @include laptop {
      margin-right: 24px;
    }
    @include tablet-max {
      margin-right: 20px;
    }
    @include mobile {
      margin-right: 13px;
    }
  }

  &-cell {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    @include wide {
      border-right: 3px solid rgba($black, 0.5);
    }

    &--logo {
      padding: 0 20px 0 0;
    }

    &-link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      text-decoration: none;
      text-transform: uppercase;
      padding: 0 27px;

      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;

      @include mobile {
        width: 100%;
        text-align: left;
      }

      &--btn {
        background: transparent;
        outline: none;
        border: 0;
      }

      &.active {
        background: $block-hover;
        color: $primary;
      }

      &:hover {
        color: $primary;
      }

      &-dropdown {
        gap: 32px;
      }
    }
  }

  &-menu {
    position: absolute;
    top: -20px;
    left: -103px;
    width: 157px;
    padding: 0;
    background: $light;
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden;
    transition: 0.3s;
    z-index: 0;

    &-container {
      width: 160px;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 48px;
      padding: 0 10px;

      &-link {
        text-decoration: none;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        font-family: $font-primary;
        color: rgba($secondary, 0.75);
        font-size: 14px;
        font-weight: 600;
        line-height: 100%;
        text-transform: uppercase;

        &:hover {
          color: $primary;
          background: $block-hover;
        }

        &-active {
          color: $primary;
          background: $block-hover;
        }
      }

      &:hover {
        color: $primary;
        background: $block-hover;
      }

      &-active {
        color: $primary;
        background: $block-hover;
      }
    }

    &-active {
      top: 56px;
      left: -103px;
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }

    &-btn {
      display: inline-flex;
      height: 32px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: none;
      background: transparent;

      &-img {
        width: 10px;
        height: 10px;
        transform: rotate(0);
        transition: all 0.3s linear;

        &-active {
          transform: rotate(-180deg);
        }
      }
    }
  }
}
