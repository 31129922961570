@import 'styles/variables';
@import 'styles/mixins';

.loot-boxes {
  background: $deep-night;

  &-container {
    max-width: 1280px;

    @include laptop-max {
      padding: 0 50px;
    }

    @include tablet-max {
      padding: 0 80px;
    }

    @include mobile {
      padding: 0 45px;
    }
  }

  &-input {
    width: 950px;

    @include laptop-max {
      width: 604px;
    }
    @include tablet-max {
      width: 284px;
    }

    &-admin {
      flex: auto;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 62px 0 67px;

    &-warning {
      //   position: absolute;
      height: 0;
      flex: none;
      text-align: center;
      color: $warning;
    }

    &-header {
      width: 100%;
      max-width: 1280px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 35px;

      &-case {
        width: 100%;
        max-width: 1280px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-direction: row-reverse;
        gap: 35px;
      }

      @include laptop-max {
        justify-content: center;
      }

      @include mobile {
        flex-wrap: wrap;
        gap: 26px 35px;
      }

      &-select {
        width: 284px;
        display: flex;
        justify-content: flex-end;
      }
    }

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      margin-top: 30px;
      gap: 35px;

      @include laptop {
        width: 923px;
      }

      @include tablet {
        width: 603px;
      }

      @include mobile {
        width: 284px;
      }

      &-stub {
        display: flex;
        width: 100%;
        height: 390px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 17px;
        background: $light;

        &-text {
          color: rgba($secondary, 0.4);
          text-align: center;
          font-family: $font-secondary;
          font-size: 55px;
          font-style: normal;
          font-weight: 800;
          margin: 0;

          @include tablet {
            font-size: 30px;
          }

          @include mobile {
            font-size: 22px;
          }
        }
      }

      &-admin {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 30px;
        gap: 35px;
        min-height: 675px;
      }
    }
  }

  &-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 210px;
    height: 290px;
    background: $light;
    border: 1px solid rgba($stroke-light, 0.2);

    &.lb-small {
      width: 210px;
      height: 220px;

      .loot-boxes-item-card-description-container {
        min-height: 40px;
      }
    }

    &-header {
      display: flex;
      width: 100%;
      height: 30px;
      justify-content: flex-end;
      //   padding: 0 5px;

      //   background: rgba($stroke-light, 0.05);
      gap: 2px;

      &-btn {
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        align-items: center;
        border: none;
        // border-radius: 4px;
        // border: 1px solid rgba($secondary, 0.05);
        // background: rgba($secondary, 0.05);
        height: 26px;
        width: 26px;
        margin: 2px 2px;
        background: rgba($secondary, 0);
        &.refresh {
          width: 20px;
          height: 20px;
          padding: 1px 0 0 5px;
          img {
            width: 20px;
            height: 20px;
          }
        }

        &.edit {
          padding: 2px 0 0 0;
        }
        &.close {
          width: 30px;
          height: 30px;
          padding: 0 0 5px 0;
        }
        img {
          width: auto;
          height: auto;
        }
      }
    }

    &-card {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 20px 20px;
      gap: 3px;

      //   @include laptop-max {
      //     width: 250px;
      //   }

      &-description-container {
        min-height: 70px;
      }

      &-label {
        position: absolute;
        left: 10px;
        top: 5px;
        display: flex;

        &-text {
          color: rgba($secondary, 0.75);
          font-family: $font-primary;
          font-size: 14px;
          font-weight: 200;
          line-height: 150%;
          border: 1px solid rgba($secondary, 0.05);
          border-radius: 2px;
          min-width: 30px;
          display: flex;
          justify-content: center;
          padding: 2px 5px;

          &.lb-text-orange {
            background: rgba(255, 166, 0, 0.299);
            color: orange;
          }
          &.lb-text-red {
            background: rgba(255, 0, 0, 0.14);
            color: rgb(255, 0, 0);
          }

          .unlimited {
            font-size: 25px;
          }
        }
      }

      &-img {
        width: 100px;
        height: 100px;
      }

      &-name {
        width: 100%;
      }

      &-caption {
        position: relative;
        width: 195px;

        &:hover .loot-boxes-item-card-caption-tooltip {
          visibility: visible;
        }

        &-tooltip {
          width: 100%;

          position: absolute;
          bottom: 100%;
          left: 0;
          display: flex;
          padding: 10px;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          visibility: hidden;
          z-index: 1;
          border-radius: 14px;
          background: rgba(0, 0, 0, 0.9);

          .lb-description {
            color: $headline-white;
            font-family: $font-primary;
            font-size: 12px;
            font-weight: 500;
            line-height: 150%;
            margin-bottom: 3px;
          }
        }
      }

      &-footer {
        &-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 15px;
        }
        &-cta {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 29px;
          //   padding: 9px 16px;
          justify-content: center;
          align-items: center;

          border-radius: 4px;
          border: 1px solid rgba($secondary, 0.05);
          background: rgba($secondary, 0.05);

          &.lb-shared-text {
            font-family: 'Montserrat', sans-serif;
            font-size: 12px;
            font-weight: 500;
            line-height: 150%;
            color: $headline-white;
            font-weight: 200;
          }
        }

        &-content {
          display: flex;
          flex: auto;
          width: 100%;
          justify-content: center;
          align-items: center;
          gap: 3px;
          font-size: 12px;

          //   &-chance {
          //     font-size: 14px;
          //   }

          //   &-percent {
          //     font-size: 14px;
          //   }

          input.lb-number-input {
            width: 50px;
            height: 20px;
            border: 1px solid rgba($block-hover, 0.2);
            color: rgba($secondary, 0.75);
            font-family: $font-primary;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            text-align: center;

            border-radius: 10px;
            background: rgba($block-hover, 0.2);

            &-error {
              border-color: $red;
            }

            &::placeholder {
              color: rgba($faded, 0.4);
            }

            &.form-control {
              background: inherit;
              border-radius: inherit;
              border: inherit;
              color: inherit;
            }

            &-after {
              position: absolute;
              bottom: 10px;
              right: 10px;
            }

            &-wrapper {
              width: 100%;
              min-width: 402px;
              height: 40px;
              position: relative;
            }

            &:focus-visible {
              outline: none;
              border-color: $primary;

              &:before {
                background: $light;
              }
            }
          }
        }
      }

      &-price {
        width: 100%;
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        border-radius: 4px;
        border: 1px solid rgba($secondary, 0.05);
        background: $block-light;

        img {
          width: 24px;
          height: 24px;
        }
      }

      &-btn.shared-btn-primary {
        width: 100%;
        height: 48px;
        border-radius: 4px;
      }

      &-cta {
        display: flex;
        width: 100%;
        height: 29px;
        justify-content: center;
        align-items: center;

        border-radius: 4px;
        border: 1px solid rgba($secondary, 0.05);
        background: rgba($secondary, 0.05);

        &.lb-disabled {
          //background: rgba($secondary, 0.55);
          //border: 1px solid rgba($secondary, 0.05);
          color: rgba($secondary, 0.1) !important;
        }

        &-delete {
          width: 36px;
          padding: 0 17px;

          img {
            width: 15px;
            height: 15px;
          }
        }

        &-wrapper {
          display: flex;
          gap: 8px;
        }
      }
    }
  }

  &-card {
    position: relative;
    width: 290px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 15px;
    background: $light;
    border: 1px solid rgba($stroke-light, 0.2);

    @include laptop-max {
      width: 284px;
    }

    &-label-id {
      position: absolute;
      left: 10px;
      top: 5px;
    }

    &-img {
      width: 133px;
      height: 133px;
    }

    &-name {
      width: 100%;
    }

    &-caption {
      position: relative;
      width: 195px;

      &:hover .loot-boxes-card-caption-tooltip {
        visibility: visible;
      }

      &-tooltip {
        width: 100%;
        position: absolute;
        bottom: 100%;
        left: 0;
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        visibility: hidden;
        z-index: 1;
        border-radius: 14px;
        background: rgba(0, 0, 0, 0.8);
      }
    }

    &-footer-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 15px;
    }

    &-price {
      width: 100%;
      display: flex;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;

      border-radius: 4px;
      border: 1px solid rgba($secondary, 0.05);
      background: $block-light;

      img {
        width: 24px;
        height: 24px;
      }
    }

    &-btn.shared-btn-primary {
      width: 100%;
      height: 48px;
      border-radius: 4px;
    }

    &-cta {
      display: flex;
      width: 100%;
      height: 29px;
      padding: 9px 16px;
      justify-content: center;
      align-items: center;

      border-radius: 4px;
      border: 1px solid rgba($secondary, 0.05);
      background: rgba($secondary, 0.05);

      &-delete {
        width: 36px;
        padding: 0 17px;

        img {
          width: 15px;
          height: 15px;
        }
      }

      &-wrapper {
        display: flex;
        gap: 8px;
      }
    }
  }

  &-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }

  &-text {
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;

    &.white {
      color: $headline-white;
    }

    &.gray {
      color: rgba($secondary, 0.4);
    }

    &--middle {
      font-size: 14px;
    }

    &--small {
      font-size: 12px;
    }
  }

  &-agreement {
    margin: 34px 0 10px;
    border-radius: 8px;
    border: 1px solid rgba($stroke-light, 0.2);
    background: $light;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px 15px;

    @include mobile {
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
    }

    &-content {
      width: 600px;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;

      @include mobile {
        align-items: center;
        width: 100%;
      }

      &-title {
        color: $headline-white;
        font-family: $font-primary;
        font-size: 20px;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;

        @include mobile {
          text-align: center;
        }
      }

      &-text.shared-text {
        color: rgba($secondary, 0.75);
        margin: 16px 0 0;

        @include laptop-max {
          margin: 10px 0 0;
        }

        @include tablet {
          width: 342px;
          margin: 13px 33px 0 0;
        }

        @include mobile {
          margin: 15px 0;
          text-align: center;
          width: 248px;
        }
      }

      &-btn {
        min-width: 180px;
      }
    }
  }

  &-header {
    margin-top: 34px;
    margin-bottom: 10px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    &-img {
      width: 75px;
      height: 75px;
      margin-right: 20px;

      &--small {
        width: 40px;
        height: 40px;
      }
    }

    &-content {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;

      &-title {
        color: $headline-white;
        font-family: $font-primary;
        font-size: 20px;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }

      &-text.shared-text {
        color: rgba($secondary, 0.75);
        margin: 16px 0 0;
      }
    }
  }

  &-statistic {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 35px;
    margin-top: 43px;

    @include tablet {
      flex-flow: row wrap;
      margin-top: 32px;
    }

    @include mobile {
      margin-top: 36px;
      flex-flow: column nowrap;
    }

    &-card {
      flex: 1;
      overflow: hidden;
      height: 151px;
      border-radius: 4px;
      border: 1px solid rgba($stroke-light, 0.2);

      @include tablet {
        min-width: 40%;
      }

      @include mobile {
        width: 100%;
      }

      &-admin {
        border: 1px solid $light;
      }

      &-header {
        height: 44px;
        border-bottom: 1px solid rgba($stroke-light, 0.2);
        background: $light;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        color: rgba($secondary, 0.75);
        font-family: $font-primary;
        font-size: 16px;
        font-weight: 700;
        line-height: 150%; /* 24px */
        text-transform: uppercase;

        &-admin {
          border-bottom: 1px solid $light;
        }
      }

      &-body {
        width: 100%;
        height: 107px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }

      &-title {
        color: $headline-color;
        text-align: center;
        font-family: $font-primary;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
      }

      &-img {
        max-width: 86px;
        max-height: 86px;

        @include laptop {
          max-width: 60px;
          max-height: 60px;
        }
      }

      &-text.shared-text {
        color: $headline-color;
        text-transform: uppercase;
        margin: 0 0 0 24px;
        width: 123px;

        @include laptop {
          font-size: 14px;
          width: 109px;
          margin: 0 0 0 6px;
        }
      }

      &-avatar {
        width: 44px;
        height: 44px;
        margin-right: 8px;
        border-radius: 36px;
        border: 1px solid $light;
      }

      &-username.shared-text {
        color: $headline-color;
        max-width: 196px;
      }

      &-amount {
        margin: 8px 0 0 0;
        padding: 0 16px;
        height: 32px;
        border-radius: 80px;
        border: 1px solid rgba($stroke-light, 0.2);
        color: $prize-green;
        font-family: $font-primary;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 21px */
        text-transform: uppercase;

        @include laptop {
          margin: 9px 0 0 0;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }

        &-admin {
          border: 1px solid rgba($secondary, 0.75);
          width: fit-content;

          &-text {
            color: rgba($secondary, 0.75);
          }
        }
      }
    }
  }

  &-purchase {
    &-body {
      padding: 32px 0 24px !important;

      @include mobile {
        padding: 25px 20px 20px !important;
      }
    }

    &-title.shared-text {
      font-weight: 700;
      color: $headline-color;
      margin-bottom: 8px;
    }

    &-text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin-top: 16px;

      @include mobile {
        margin-top: 14px;
      }

      span {
        width: 24px;
        height: 24px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        border: 1px solid rgba($secondary, 0.75);
        color: rgba($secondary, 0.75);
        font-family: $font-primary;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 1;
        margin-right: 8px;
      }

      .shared-text {
        margin: 0;

        @include mobile {
          font-size: 12px;
        }
      }
    }

    &-footer {
      &.modal-footer {
        flex-flow: column nowrap;
        border-top: 1px solid rgba($stroke-light, 0.2);
        background: $block-hover;
        padding: 30px 32px 32px;
      }

      &-control {
        margin-top: 50px !important;
        gap: 24px;

        @include mobile {
          gap: 11px;
          margin-top: 20px !important;
        }
      }

      &-item {
        &-asset {
          max-width: 142px;
          max-height: 142px;
        }

        &-name.shared-text {
          width: 189px;
          margin: 16px 0;
          color: rgba($secondary, 0.75);
          font-weight: 700;
          text-align: center;
        }
      }

      &-submit {
        gap: 8px;

        img {
          width: 20px;
        }
      }
    }
  }
}

.exclamation-mark {
  color: rgba($secondary, 0.75);
  margin-right: 8px;

  &-gold {
    color: $yellow;
  }
}
