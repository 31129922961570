@import "styles/variables";

.custom-table {
  &-header {
    &-cell {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      &-sort {
        height: 22px;
        width: 22px;
        outline: none;
        padding: 0;
        border: 0;
        background: transparent;
        margin: 0 9px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        transition: all linear 0.3s;
        color: rgba($secondary, 0.75);

        &.rotated {
          transform: rotate(-180deg);
        }

        &.active {
          color: $primary;
        }

        &-icon {
          width: 10px;
          color: inherit;
        }
      }
    }
  }

  &-cell {
    flex: 1;
    min-height: 50px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    text-transform: uppercase;

    &-extra {
      min-height: 32px;
      min-width: 32px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 58px;
      border: 1px solid $block-light;
      background: $deep-night;
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 21px */
      text-transform: uppercase;
    }

    &-in-row {
      background: transparent;
      border-right: inherit;
      border-left: inherit;

      &:nth-child(1) {
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        border-left: 0;
      }

      &:nth-last-child(1) {
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
        border-right: 0;
      }
    }
  }

  &-row {
    border-radius: 20px;
    margin-bottom: 12px;
    border: 1px solid rgba($stroke-light, 0.20);
    background: #131B26;
  }
}
