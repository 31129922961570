@import "styles/variables";
@import "styles/mixins";

.earn-bonus-card {
  width: 333px;
  height: 310px;
  background: $light;
  border-radius: 10px;
  border: 1px solid rgba($stroke-light, .20);
  box-shadow: 0 10px 60px 0 $deep-night;

  @include laptop {
    width: 280px;
    height: 310px;
  }
  @include tablet-max {
    width: 327px;
    height: 357px;
  }

  h4 {
    color: $orange-accent;
    font-family: $font-secondary;
    font-size: 32px;
    font-weight: 800;
    text-transform: uppercase;
    margin: 7px 0 69px 0;
  }

  h5 {
    color: $headline-white;
    font-family: $font-secondary;
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
    margin-top: 34px;
    margin-bottom: 0;
    line-height: 100%;
  }

  &-icon {
    width: 126px;
    height: 126px;
  }

  &-number {
    position: absolute;
    bottom: -26px;
    display: flex;
    width: 64px;
    height: 64px;
    padding: 21px 22px 22px 21px;
    justify-content: center;
    align-items: center;
    font-family: $font-secondary;
    background: $orange-accent;
    color: $block-light;
    font-size: 32px;
    font-weight: 800;
    text-transform: uppercase;
    border-radius: 10px;
  }

}
