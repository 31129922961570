@import "styles/variables";
@import "styles/mixins";

.leaderboard-history-card {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 320px;
  height: 389px;
  border-radius: 10px;
  border: 1px solid rgba($stroke-light, 0.20);
  background: $light;
  box-shadow: 0 10px 60px 0 $deep-night;

  @include laptop {
    width: 270px;
  }

  &-for-history {
    @include laptop-max {
      width: 300px;
    }
  }

  &:hover .leaderboard-history-card-hover {
    opacity: 1;
    transition: opacity 0.6s;
  }

  &-hover {
    width: 100%;
    height: 100%;
    background: rgba($deep-night, 0.75);
    backdrop-filter: blur(9px);
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 1;

    &-btn {
      display: flex;
      height: 40px;
      padding: 0 24px;
      justify-content: center;
      align-items: center;
      line-height: 15%;
      color: $headline-white;
      margin-top: 40px;
    }

    &-date {
      position: unset !important;
      margin-top: 8px;

      &-admin {
        margin-top: 16px;
      }
    }

    &-title {
      color: $headline-white;
      font-family: $font-primary;
      font-size: 20px;
      font-weight: 700;
      line-height: 100%;
      text-transform: uppercase;
      margin: 20px 0 0;
    }

    &-countdown {
      position: relative;
      display: inline-flex;
      flex-direction: column;
      height: 56px;
      padding: 24px;
      justify-content: center;
      align-items: center;
      margin-top: 21px;
      border-radius: 8px;
      border: 1px solid var(--faded-text, rgba(226, 241, 255, 0.40));

      &:before {
        position: absolute;
        content: " ";
        top: -1px;
        height: 1px;
        width: 50%;
        background: $deep-night;
        z-index: 2;
      }

      &-title {
        position: absolute;
        top: -6px;
        text-align: center;
        font-family: $font-primary;
        color: rgba($secondary, 0.75);
        font-size: 12px;
        font-weight: 600;
        line-height: 100%;
        text-transform: uppercase;
        margin: 0;
        z-index: 2;
      }
    }
  }

  &-winner {
    width: 52px;
    height: 35px;
  }

  &-date {
    padding: 8px;
    border-radius: 49px;
    border: 1px solid rgba($stroke-light, 0.20);
    color: $headline-white;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 60%;
    text-transform: uppercase;

    @include laptop {
      font-size: 12px;
    }

    &-admin {
      font-size: 12px;
    }

    &-current {
      color: $primary;
      border-color: $primary;
    }

    &-wrapper {
      width: 100%;
      padding: 16px;
      margin-bottom: auto;
      display: flex;
      justify-content: space-between;

      &-admin {
        padding: 16px 10px 0;
      }
    }
  }

  &-logo {
    border-radius: 50%;
    margin-top: 10px;
  }

  &-name {
    color: $headline-white;
    font-family: $font-primary;
    font-size: 20px;
    font-weight: 700;
    line-height: 100%;
    margin: 12px 0 0;
  }

  &-footer {
    border-top: 1px solid rgba($stroke-light, 0.20);
    padding: 28px 24px 24px;
    margin-top: 40px;

    @include laptop {
      padding: 28px 15px 24px;
    }

    &-title {
      position: absolute;
      top: -8px;
      color: $headline-white;
      font-size: 15px;
      font-weight: 700;
      line-height: 100%;
      text-transform: uppercase;
      background: $light;
      margin: 0;
      padding: 0 8px;

      @include laptop {
        font-size: 12px;
      }

      @include tablet-max {
        font-size: 14px;
      }
    }
  }

  &-btn.shared-btn-primary {
    width: 272px;
    height: 53px;
    font-size: 28px;
    font-weight: 800;

    @include laptop {
      width: 240px;
    }
  }
}
