@import "styles/variables";
@import "styles/mixins";

.landing {
  // padding-top: 102px;
  background: $deep-night;
  @include tablet-max {
    background: $dark;
  }

  &-hero {
    margin-top: 48px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    @include tablet-max {
      flex-flow: column-reverse;
    }

    @include laptop-max {
      margin-top: 0;
    }

    &-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;

      @include laptop {
        padding-bottom: 46px;
      }

      @include tablet-max {
        padding-bottom: 25px;
      }
    }

    &-decoration {
      position: absolute;
      left: -36px;
      top: 90px;
      z-index: 0;
    }

    &-content {
      max-width: 456px;
      margin-right: 84px;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      z-index: 1;

      @include laptop {
        margin-right: 65px;
      }

      @include tablet-max {
        width: 343px;
        margin: 0;
        align-items: center;
        justify-content: center;
      }

      &-title {
        color: $headline-white;
        font-family: $font-secondary;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 1;
        margin: 0;

        span {
          font-size: inherit;
          line-height: inherit;
          font-weight: inherit;
          color: $orange;
        }

        @include tablet-max {
          font-size: 20px;
          margin-top: 17px;
          text-align: center;
        }
      }

      &-subtitle {
        color: $orange;
        font-family: $font-primary;
        font-size: 71px;
        font-weight: 900;
        line-height: 1;
        letter-spacing: -2px;
        margin: 23px 0 0 0;
        text-transform: uppercase;

        @include tablet-max {
          font-size: 49px;
        }
      }

      &-text {
        margin: 68px 0 0 0;
        font-family: $font-primary;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        color: rgba($secondary, 0.75);

        @include tablet-max {
          margin-top: 28px;
          text-align: center;
          font-size: 14px;
        }
      }

      &-btn {
        margin-top: 39px;

        @include tablet-max {
          width: 343px;
          margin-top: 28px;
          height: 48px !important;
        }
      }
    }

    &-img {
      width: 505px;
      z-index: 1;

      @include laptop {
        width: 400px;
      }

      @include tablet-max {
        width: 277px;
      }
    }

    &-scroll {
      margin: 15px auto 11px;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      p {
        color: rgba($secondary, 0.75);
        font-family: $font-primary;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }

      img {
        margin-bottom: 0;
        margin-top: 27px;
        animation: lift 2.5s linear 1s infinite;

      }
    }
  }

  &-leader {
    padding-top: 141px;
    padding-bottom: 176px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: $deep-night;
    @include tablet-max {
      flex-flow: column-reverse;
    }

    &-title {
      h1 {
        line-height: 80%;
        @include tablet-max {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include tablet-max {
        flex-flow: column-reverse;
        padding-top: 40px;
      }
      @include mobile {
        padding: 40px 16px 0 16px;
      }
    }

    &-content {

      @include laptop {
        padding-left: 50px;
      }
      @include tablet-max {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      @include mobile {

      }
    }

    &-board {
      height: 700px;
      width: 60%;
      @include laptop {
        height: 556px;
      }
      @include tablet-max {
        width: 370px;
        height: 351px;
      }

      @include mobile {
        overflow: hidden;
      }

      &-cup {
        z-index: 2;
        position: absolute;
        left: 28px;
        top: 35px;

        @include laptop {
          width: 156px;
          height: 156px;
          left: 66px;
          top: 10px;
        }

        @include tablet-max {
          width: 132px;
          height: 132px;
          left: 16px;
          top: -12px;
        }
      }

      &-dice {
        z-index: 2;
        position: absolute;
        left: 270px;
        bottom: 45px;

        @include laptop {
          height: 64px;
          width: 62px;
          left: 240px;
          bottom: 60px;
        }
        @include tablet-max {
          height: 44px;
          width: 42px;
          left: 160px;
          bottom: 0;
        }
      }

      &-crown {
        &-top {
          z-index: 4;
          position: absolute;
          left: 374px;
          top: 86px;
          width: 107px;

          @include laptop {
            width: 82px;
            height: 72px;
            left: 309px;
            top: 46px;
          }
          @include tablet-max {
            width: 58px;
            height: 50px;
            left: 214px;
            top: 24px;
          }
        }

        &-btm {
          z-index: 2;
          position: absolute;
          left: 387px;
          top: 72px;
          width: 107px;

          @include laptop {
            width: 82px;
            height: 67px;
            left: 316px;
            top: 39px;
          }
          @include tablet-max {
            width: 58px;
            height: 50px;
            left: 219px;
            top: 19px;
          }
        }
      }

      &-description {
        width: 378px;
        padding-bottom: 46px;
        padding-top: 27px;
        @include mobile {
          padding-bottom: 31px;
          padding-top: 32px;
          width: 370px;
        }
      }


      &-card {
        display: flex;
        flex-direction: column;
        margin-top: 72px;
        width: 274px;
        border-radius: 9px;
        background: linear-gradient(159deg, rgba(253, 206, 49, 0.48) -19.58%, rgba(176, 97, 52, 0.00) 58.54%), #131A2D;
        box-shadow: 0 9px 55px 0 #0B1523;
        @include laptop {
          width: 200px;
        }
        @include tablet-max {
          width: 150px;
        }

        &-first {
          z-index: 3;
          position: absolute;
          height: 438px;
          top: 50px;
          left: 170px;

          @include laptop {
            height: 320px;
            top: 5px;
          }
          @include tablet-max {
            height: 238px;
            left: 106px;
            top: 46px;
            margin-top: 0;
          }
        }

        &-second {
          z-index: 1;
          position: absolute;
          height: 384px;
          top: 75px;
          left: 50px;
          transform: rotate(-26deg);
          opacity: 0.75;
          background: linear-gradient(159deg, rgba(225, 225, 225, 0.38) -23.46%, rgba(19, 26, 45, 0.00) 58.55%), #131A2D;
          box-shadow: 0 9px 55.31836px 0 #0B1523;
          @include laptop {
            height: 280px;
            left: 100px;
            top: 30px;
          }
          @include tablet-max {
            height: 209px;
            left: 36px;
            top: -10px;
          }
        }

        &-third {
          z-index: 0;
          position: absolute;
          height: 384px;
          top: 75px;
          left: 300px;
          transform: rotate(32deg);
          opacity: 0.3;
          background: linear-gradient(159deg, rgba(182, 105, 35, 0.38) -23.46%, rgba(19, 26, 45, 0.00) 58.55%), #131A2D;
          @include laptop {
            height: 280px;
            left: 250px;
            top: 30px;
          }
          @include tablet-max {
            height: 209px;
            left: 186px;
            top: -10px;
          }
        }

        &-icon {
          margin-bottom: 3px;
        }

        &-place {
          &-first {
            position: absolute;
            top: -68px;
            right: 143px;
            @include laptop {
              top: -24px;
              right: 109px;
              width: 26px;
              height: 40px;
            }
            @include tablet-max {
              top: -18px;
              right: 82px;
              width: 20px;
              height: 30px;
            }
          }

          &-second {
            position: absolute;
            top: -40px;
            right: 130px;
            transform: rotate(26deg);

            @include laptop {
              top: -35px;
              right: 99px;
              width: 40px;
              height: 54px;
            }
            @include tablet-max {
              top: -24px;
              right: 72px;
              width: 31px;
              height: 34px;
            }
          }

          &-third {
            position: absolute;
            top: -40px;
            right: 125px;
            transform: rotate(-32deg);

            @include laptop {
              top: -35px;
              right: 105px;
              width: 40px;
              height: 54px;
            }
            @include tablet-max {
              top: -24px;
              right: 70px;
              width: 31px;
              height: 34px;
            }
          }
        }

        &-logo {
          border-radius: 50%;
          border: 2px solid $deep-night;

          @include laptop {
            width: 100px;
            height: 100px;
          }
          @include tablet-max {
            width: 75px;
            height: 75px;
          }

          &-second {
            width: 110px;
            height: 110px;
            border-radius: 50%;
            border: 2px solid $deep-night;
            @include laptop {
              width: 80px;
              height: 80px;
            }
            @include tablet-max {
              width: 60px;
              height: 60px;
            }
          }

          &-third {
            width: 110px;
            height: 110px;
            border-radius: 50%;
            border: 2px solid $deep-night;
            @include laptop {
              width: 80px;
              height: 80px;
            }
            @include tablet-max {
              width: 60px;
              height: 60px;
            }
          }
        }

        &-btn {
          display: flex;
          width: 229px;
          height: 49px;
          margin: 0 22px 22px 22px;
          padding: 12px 60px 12px 60px;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          border: 1px solid $primary;
          background: $primary;
          color: $secondary;
          font-family: $font-primary;
          font-size: 24px;
          font-weight: 800;


          @include laptop {
            width: 166px;
            height: 35px;
            border-radius: 5px;
            margin: 0 16px 16px 16px;
            padding: 12px 37px 12px 37px;
            font-size: 17px;
          }
          @include tablet-max {
            width: 125px;
            height: 27px;
            border-radius: 4px;
            margin: 0 12px 12px 12px;
            padding: 8px 28px;
            font-size: 13px;
          }


          span {
            padding-right: 2px;
          }
        }

        &-user {
          color: rgba($secondary, .75);
          font-family: $font-primary;
          font-size: 18px;
          font-weight: 700;
          margin-top: 7px;
          margin-bottom: 51px;

          @include laptop {
            margin-top: 5px;
            margin-bottom: 37px;
          }
          @include tablet-max {
            font-size: 10px;
            margin-bottom: 27px;
          }

          &-second {
            @include laptop {
              margin-top: 5px;
              margin-bottom: 12px;
            }
            @include tablet-max {
              margin-bottom: 10px;
            }
          }

          &-third {
            @include laptop {
              margin-top: 5px;
              margin-bottom: 18px;
            }
            @include tablet-max {
              margin-bottom: 10px;
            }
          }
        }

        &-wagered {
          color: rgba($secondary, .75);
          font-family: $font-primary;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 22px;

          @include laptop {
            margin-bottom: 16px;
          }
          @include tablet-max {
            margin-bottom: 12px;
            font-size: 10px;
          }

          span {
            color: $green;
          }
        }

        h5 {
          color: rgba($secondary, .75);
          font-family: $font-primary;
          font-size: 13px;
          font-weight: 600;
          line-height: 150%;
          text-transform: uppercase;
          margin-bottom: 6px;
          @include tablet-max {
            font-size: 7px;
          }
        }
      }
    }

    h1 {
      line-height: 60%;
    }

    span {
      line-height: 100%;
      position: relative;
    }

    &-medal {
      position: absolute;
      left: 30px;
      top: 40px;

      @include mobile {
        width: 98px;
        height: 98px;
        left: 35px;
      }
    }

    &-description {
      width: 378px;
    }

  }

  &-virtual-avatar {
    position: relative;
    z-index: 1;

    &-wrapper {
      width: 100%;
      background: $deep-night;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding-top: 46px;
      @include laptop {
        padding: 46px 51px;
        overflow: hidden;
      }
      @include tablet-max {
        min-height: auto;
      }
    }

    &-bg {
      position: absolute;
      left: 0;
      top: 15%;
      width: 100%;
      z-index: 0;
    }

    h2 {
      font-family: $font-secondary;
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 150%;
    }

    &-description {
      padding-bottom: 34px;
      padding-top: 20px;
    }

    &-title {
      line-height: 90%;

      h1 {
        padding-bottom: 16px;
      }
    }

    &-img {
      @include laptop {
        width: 617px;
        height: auto;
      }
      position: relative;
      z-index: 2;
    }

    &-decor {
      transform: rotate(-1deg);
      position: absolute;
      left: -150px;
      top: -70px;
      z-index: 1;
    }

    &-label {
      background: $success;
      display: inline-flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      color: $light;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 100%;
    }
  }


  &-point-store {
    padding: 100px 0 0 0;
    @include laptop {
      padding: 76px 61px 100px 61px;
    }
    @include tablet-max {
      flex-flow: column-reverse;
      padding-bottom: 32px;
      padding-top: 35px;
      background: $deep-night;
    }

    &-description {
      margin: 0;
      padding-bottom: 52px;

      @include laptop {
        padding-top: 25px;
      }
      @include tablet-max {
        padding-bottom: 31px;
        padding-top: 10px;
      }
    }

    &-section {
      width: 441px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      h1 {
        margin: 0;

        img {
          position: relative;
          top: -8px;
        }
      }

      @include tablet-max {
        width: 310px;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
      }
    }

    &-img {
      @include laptop {
        width: 454px;
      }
      @include tablet-max {
        width: 342px;
      }
    }

    &-title {
      line-height: 100%;
    }
  }

  &-slots {
    z-index: 1;
    padding-top: 100px;
    padding-bottom: 117px;

    @include laptop {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    @include tablet-max {
      padding-top: 40px;
      padding-bottom: 18px;
    }

    &-wrapper {
      width: 100%;
      background: $deep-night;
      display: flex;
      flex-direction: column;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      overflow: hidden;

    }

    &-decoration {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      height: 100%;

      @include laptop {
        height: 750px;
        top: -50px;
        left: -155px;
      }
      @include tablet-max {
        height: 730px;
        top: 0;
        left: -110px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%);
      }
      @include mobile {
        height: 1000px;
        transform: rotate(22deg);
        top: 180px;
        left: -240px;
      }
    }

    &-section {
      &::-webkit-scrollbar {
        display: none;
      }

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-flow: nowrap;
      margin-top: 38px;
      column-gap: 36px;
      padding-bottom: 42px;

      @include laptop {
        justify-content: flex-start;
        padding-left: 38px;
        margin-top: 111px;
      }

      @include tablet-max {
        justify-content: flex-start;
        column-gap: 36px;
        overflow-x: scroll;
        padding-top: 80px;
        padding-bottom: 66px;
        padding-left: 53px;
        margin: 0;
      }

      @include laptop-max {
        padding-left: 21px;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      h2 {
        margin: 0;
      }

      @include laptop {
        margin: 0;
      }

      @include tablet-max {
        flex-direction: column;
      }
    }

    &-btn {
      width: 174px;
      height: 48px;

      @include tablet-max {
        width: 343px;
      }
    }

    &-description {
      @include tablet-max {
        width: 303px;
        padding-top: 10px;
      }
    }
  }


  &-raffles {
    z-index: 1;
    padding-top: 100px;
    @include laptop {
      padding: 62px 61px 0 61px;
    }
    @include tablet-max {
      flex-flow: column-reverse;
      padding-bottom: 32px;
      padding-top: 32px;
    }

    &-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      @include tablet-max {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &-title {
      margin: 0;
      position: absolute;
      bottom: -29px;
      left: 0;

      @include tablet-max {
        bottom: -24px;
      }

      &-purple {
        position: relative;
        padding-left: 132px;
        margin-bottom: 22px;

        @include tablet-max {
          padding-left: 109px;
          margin-right: 20px;
        }

        img {
          width: 93px;
          height: 59px;

          @include laptop {
            height: 58px;
          }

          @include tablet-max {
            width: 100px;
            height: 48px;

          }
        }
      }

      &-gold {
        position: relative;
        margin-bottom: 47px;

        @include tablet-max {
          margin-right: 155px;
        }

        h1 {
          padding-left: 68px;

          @include tablet-max {
            padding-left: 60px;
          }
        }

        img {
          width: 90px;
          height: 57px;

          @include tablet-max {
            width: 80px;
            height: 46px;
            margin-bottom: 1px;
          }
        }
      }
    }

    &-description {
      width: 368px;
      padding-bottom: 71px;

      @include tablet-max {
        width: 343px;
        padding-bottom: 20px;
      }
    }

    &-img {
      z-index: 1;
      @include laptop {
        width: 538px;
        padding-bottom: 60px;
      }
      @include tablet-max {
        width: 350px;
      }
    }

    &-decor {
      position: absolute;
      left: 618px;
      z-index: 0;
    }
  }

  &-socials {
    display: flex;
    flex-direction: column;
    padding-top: 100px;

    @include laptop {
      padding: 78px 38px 0;
    }
    @include tablet-max {
      padding-top: 40px;
    }
    @include mobile {
      padding-top: 66px;
    }

    &-section {
      display: flex;
      flex-direction: row !important;
      justify-content: center;
      margin-top: 70px;
      column-gap: 30px;

      @include tablet-max {
        align-items: center;
        justify-content: space-between;
        flex-direction: column !important;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 13px;

      h2 {
        margin: 0;
      }

      @include tablet-max {
        flex-direction: column;
        margin-bottom: 0;
      }
    }
  }

  &-faq {
    padding-top: 140px;
    padding-bottom: 214px;
    @include laptop {
      padding: 60px 0 58px;
    }
    @include tablet-max {
      padding: 64px 0 80px;
    }
    @include mobile {
      padding-bottom: 39px;
    }

    &-title {
      display: flex;
      align-items: center;

      h3 {
        margin: 0;
      }

      @include laptop {
        margin-top: 0;
      }
      @include tablet-max {
        flex-direction: column;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      padding: 57px 125px 0;

      @include laptop {
        padding: 60px 105px 0;
      }
      @include tablet-max {
        padding: 67px 212px 0;
      }
      @include mobile {
        padding: 63px 0 0;
        width: 343px;
      }
    }
  }

  .big-headline {
    font-stretch: 100%;
    color: $headline-white;
    font-family: $font-secondary;
    font-size: 85px;
    font-weight: 800;
    margin: 0;

    &-gold {
      font-stretch: 100%;
      background: $gradient-gold;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: $font-secondary;
      font-size: 85px;
      font-weight: 800;
      margin: 0;
    }

    &-primary {
      color: $primary;
    }

    @include tablet-max {
      font-size: 70px;
    }
  }

  .mid-headline {
    color: $headline-white;
    font-family: $font-secondary;
    font-size: 55px;
    font-weight: 800;

    @include tablet-max {
      font-size: 42px;
    }

    &-primary {
      color: $primary;
    }
  }

  .small-headline {
    color: $headline-white;
    font-family: $font-secondary;
    font-size: 30px;
    font-weight: 800;
    line-height: 150%;

    &-primary {
      color: $primary;
    }

    @include tablet-max {
      font-size: 25px;
    }
  }

  .description {
    color: rgba($secondary, .75);
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    margin: 0;

    @include tablet-max {
      text-align: center;
      font-size: 14px;
    }
  }

  &-creators {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 43px;
    padding-bottom: 137px;
    margin-top: 140px;
    overflow: hidden;

    @include tablet-max {
      margin-top: 40px;
      padding-bottom: 30px;
    }

    &-bg {
      position: absolute;
      width: auto;
      height: 100%;
      top: 0;
      z-index: 0;
    }

    &-title {
      &-text {
        color: $daylight;
        text-align: center;
        font-family: $font-secondary;
        font-size: 60px;
        font-weight: 800;
        line-height: normal;
        margin-bottom: 0;

        @include laptop {
          font-size: 55px;
        }

        @include tablet-max {
          font-size: 42px;
        }

        &-wrapper {
          display: flex;
          margin-top: 34px;
          gap: 14px;

          @include tablet-max {
            justify-content: center;
            flex-direction: column;
            gap: 0;
          }
        }
      }

      &-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        z-index: 1;
      }
    }

    &-list {
      display: flex;
      gap: 32px;
      margin-top: 95px;

      @include laptop {
        margin-top: 73px;
      }
      @include tablet-max {
        flex-direction: column;
        margin-top: 20px;
      }
    }

    &-card {
      display: flex;
      position: relative;
      overflow: hidden;
      width: 317px;
      height: 349px;

      border-radius: 10px;
      border: 1px solid $bg-light;
      background: $light;
      transition: transform 0.3s ease-in-out 0s;

      &:hover {
        transform: scale(1.04);
      }

      &-live {
        position: absolute;
        top: 14px;
        left: 27px;
        z-index: 0;
      }

      &-content {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        &-img {
          width: 100px;
          height: 100px;
          margin-top: 38px;

          &-big {
            width: 150px;
            height: 150px;
            margin-top: 25px;
          }
        }

        &-name {
          color: $daylight;
          text-align: center;
          font-family: $font-secondary;
          font-size: 24px;
          font-weight: 800;
          line-height: normal;
          text-transform: uppercase;
          margin-bottom: 0;
          margin-top: 38px;
        }

        &-socials {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          gap: 8px;
          padding: 0 22px 24px 22px;
          margin-top: auto;

          &-link {
            display: flex;
            width: 62px;
            height: 40px;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            border-radius: 26px;
            border: 1px solid rgba($secondary, 0.25);
            color: rgba($secondary, 0.25);

            &.active {
              border-color: $prize-green;
              color: $prize-green;
            }

            &:hover {
              border-color: rgba($secondary, 0.75);
              color: rgba($secondary, 0.75);
            }

            &-icon {
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      &-bg {
        position: absolute;
        top: 0;
        width: auto;
        height: 100%;
        z-index: -1;
      }
    }
  }

  &-about {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 123px;
    @include laptop {
      padding: 114px 23px 0;
    }

    &-section {
      &-title {
        padding-bottom: 51px;
        margin: 0;
      }

      &-description {
        max-width: 524px;
        padding-bottom: 51px;
      }

    }
  }

  &-explore-btn {
    @include laptop {
      width: 251px;
    }

    @include tablet-max {
      width: 343px;
      height: 48px !important;
    }
  }

  &-terms {
    &-title {
      color: $secondary;
      font-family: $font-primary;
      font-weight: 600;
      line-height: 150%;
      font-size: 30px;
      margin-top: 60px;
    }

    &-subtitle {
      font-size: 12px;
      margin-top: 16px;
      color: $secondary;
      font-family: $font-primary;
      font-weight: 600;
      line-height: 150%;
    }

    &-content {
      margin-top: 16px;
      padding: 16px;
      background: $bg-dark;
      border-radius: 12px;
      margin-bottom: 60px;

      h5 {
        font-size: 16px;
        font-family: $font-primary;
        color: $secondary;
        font-weight: 500;
        line-height: 150%;
        margin-top: 24px;
      }

      p {
        font-size: 12px;
        font-family: $font-primary;
        color: rgba($secondary, 0.75);
        font-weight: 400;
        line-height: 150%;
        margin-top: 12px;
      }
    }
  }
}

@keyframes lift {
  0% {
    margin: 27px 0 0;
  }
  50% {
    margin: 0 0 27px;
  }
  100% {
    margin: 27px 0 0;
  }
}
