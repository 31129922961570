// media aliases and breakpoints
$screen-mobile-min: 375px;
$screen-mobile-max: 767px;

$screen-tablet-min: 768px;
$screen-tablet-max: 1023px;

$screen-laptop-min: 1024px;
$screen-laptop-max: 1280px;

$screen-wide: 1919px;

// media devices
@mixin mobile {
  @media screen and (min-width: #{$screen-mobile-min}) and (max-width: #{$screen-mobile-max}) {
    @content;
  }
}

@mixin tablet-min {
  @media screen and (min-width: #{$screen-tablet-min}) {
    @content;
  }
}

@mixin tablet-max {
  @media screen and (max-width: #{$screen-tablet-max}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: #{$screen-tablet-min}) and (max-width: #{$screen-tablet-max}) {
    @content;
  }
}

@mixin laptop {
  @media screen and (min-width: #{$screen-laptop-min}) and (max-width: #{$screen-laptop-max}) {
    @content;
  }
}

@mixin laptop-min {
  @media screen and (min-width: #{$screen-laptop-min}) {
    @content;
  }
}

@mixin laptop-max {
  @media screen and (max-width: #{$screen-laptop-max}) {
    @content;
  }
}

@mixin wide {
  @media screen and (min-width: #{$screen-laptop-max}) {
    @content;
  }
}

@mixin wide-max {
  @media screen and (max-width: #{$screen-laptop-max}) {
    @content;
  }
}

@mixin admin-laptop {
  @media screen and (min-width: #{$screen-laptop-min + 240px}) and (max-width: #{$screen-laptop-max + 240px}) {
    @content;
  }
}

@mixin admin-laptop-min {
  @media screen and (min-width: #{$screen-laptop-min + 240px}) {
    @content;
  }
}

@mixin admin-laptop-max {
  @media screen and (max-width: #{$screen-laptop-max + 240px}) {
    @content;
  }
}

@mixin admin-tablet-min {
  @media screen and (min-width: #{$screen-tablet-min + 240px}) {
    @content;
  }
}

@mixin admin-tablet-max {
  @media screen and (max-width: #{$screen-tablet-max + 240px}) {
    @content;
  }
}

@mixin admin-tablet {
  @media screen and (min-width: #{$screen-tablet-min + 240px}) and (max-width: #{$screen-tablet-max + 240px}) {
    @content;
  }
}

.mobile-only {
  @include tablet-min {
    display: none !important;
  }
}

.tablet-only {
  @include laptop-min {
    display: none !important;
  }
  @include mobile {
    display: none !important;
  }
}

.laptop-only {
  @include wide {
    display: none !important;
  }
  @include tablet-max {
    display: none !important;
  }
}

.wide-only {
  @include wide-max {
    display: none !important;
  }
}

.mobile-hide {
  @include mobile {
    display: none !important;
  }
}

.tablet-hide {
  @include tablet {
    display: none !important;
  }
}

.laptop-hide {
  @include laptop {
    display: none !important;
  }
}

.wide-hide {
  @include wide {
    display: none !important;
  }
}

.wide-max-hide {
  @include wide-max {
    display: none !important;
  }
}

.tablet-min-hide {
  @include tablet-min {
    display: none !important;
  }
}

.tablet-max-hide {
  @include tablet-max {
    display: none !important;
  }
}

.laptop-max-hide {
  @include laptop-max {
    display: none !important;
  }
}

.laptop-min-hide {
  @include laptop-min {
    display: none !important;
  }
}
