@import "styles/variables";
@import "styles/mixins";

.slots-reviews {
  background: $deep-night;

  &-title {
    margin-bottom: 10px;

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 35px;

      @include mobile {
        width: 283px;
      }
    }

    &-img {
      margin-bottom: 10px;
    }
  }

  &-area-counter {
    display: flex;
    align-items: center;
    position: absolute;
    opacity: 0;
    z-index: 1;
    right: 16px;
    bottom: 12px;
  }

  &-top-picks {
    width: 100%;
    max-width: 1020px;
    display: flex;
    flex-direction: column;
    padding-top: 40px;

    @include laptop {
      max-width: 816px;
    }

    @include tablet-max {
      max-width: 672px;
    }

    @include mobile {
      padding: 30px 18px 0 18px;
    }

    &-tilte {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @include mobile {
        justify-content: center;
      }
    }

    &-search {
      display: flex;
      width: 100%;
      margin-bottom: 49px;

      @include laptop-max {
        margin-bottom: 30px;
      }
    }

    &-list {
      width: 100%;
      min-height: 266px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 34px;
      gap: 16px;

      @include laptop-max {
        margin-top: 30px;
        min-height: 166px;
      }

      @include mobile {
        margin-top: 30px;
        min-height: 166px;
        flex-wrap: wrap;
        justify-content: center;
      }

      &-stub {
        height: 298px !important;
      }
    }

    &-card {
      width: 191px;
      height: fit-content;
      border-radius: 5px;
      background: $light;
      overflow: hidden;
      border: 1px solid $bg-light;

      @include laptop {
        width: 150px;
        height: fit-content;
      }

      @include tablet-max {
        width: 120px;
        height: 166px;
      }

      &-img {
        width: 100%;
        height: 168px;
        border-bottom: 1px solid $bg-light;
        cursor: pointer;

        @include laptop {
          height: 132px;
        }

        @include tablet-max {
          height: 105px;
        }
      }

      &-title {
        margin: 10px 16px;
        max-width: 161px;

        @include laptop {
          max-width: 127px;
          margin: 10px 12px;
        }

        @include tablet-max {
          max-width: 101px;
          margin: 4px 10px;
        }

        h4 {
          color: $secondary;
          font-family: $font-primary;
          font-size: 14px;
          font-weight: 600;
          line-height: 150%;
          margin: 0 0 8px 0;

          @include laptop-max {
            font-size: 11px;
            margin: 0 0 6px 0;
          }

          @include tablet-max {
            font-size: 9px;
          }
        }

        h5 {
          color: rgba($secondary, 0.50);
          font-family: $font-primary;
          font-size: 12px;
          font-weight: 600;
          line-height: 150%;
          margin: 0;

          @include laptop {
            font-size: 9px;
          }

          @include tablet-max {
            font-size: 7px;
          }
        }
      }

      &-stars {
        display: flex;
        align-items: center;
        align-content: center;
        margin: 0 16px 15px 16px;
        gap: 6px;

        @include laptop {
          margin: 0 12px 10px 12px;
        }

        @include tablet-max {
          margin: 0 10px 6px 10px;
        }
      }

      &-star-icon {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        align-content: center;

        @include laptop {
          width: 12px;
          height: 12px;
        }

        @include tablet-max {
          width: 10px;
          height: 10px;
        }

        &:not(:last-child) {
          margin-right: 8px;
        }

        &-wrapper {
          @include tablet-max {
            height: 10px;
          }
        }
      }

      &-cta {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px 10px;
        gap: 6px;

        &-provider {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 11px 4px;
          gap: 6px;
        }

        &-edit {
          display: flex;
          width: 100%;
          height: 29px;
          padding: 9px 10px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          border: 1px solid rgba($stroke-light, 0.20);
          background: $block-light;
        }

        &-delete {
          display: flex;
          width: 36px;
          height: 29px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          border: 1px solid rgba($stroke-light, 0.20);
          background: $block-light;

          img {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }

  &-featured-providers {
    width: 100%;
    max-width: 1020px;
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    @include laptop {
      max-width: 816px;
      margin-top: 30px;
    }

    @include tablet-max {
      max-width: 672px;
      margin-top: 30px;
    }

    @include mobile {
      padding: 0 18px;
    }

    &-list {
      width: 100%;
      min-height: 96px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 24px;
      gap: 16px;

      @include tablet-max {
        flex-wrap: wrap;
        justify-content: center;
      }

      @include mobile {
        justify-content: center;
      }

      &-btn {
        width: 200px;
      }
    }
  }

  &-providers {
    width: 100%;
    max-width: 1020px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @include laptop {
      max-width: 848px;
    }

    @include tablet-max {
      max-width: 675px;
    }

    @include mobile {
      max-width: 366px;
      padding: 0 18px;
    }

    &-search {
      width: 100%;
      max-width: 156px;
      display: flex;
    }

    &-list {
      width: 100%;
      min-height: 133px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 24px;
      gap: 16px;

      &-stub {
        height: 136px !important;
      }
    }

    &-card {
      width: 191px;
      border-radius: 6px;
      border: 1px solid $bg-light;
      background: $light;
      padding: 0 29px;

      @include laptop {

      }

      &-with-footer {
        min-width: 156px;
        border-radius: 6px;
        border: 1px solid $bg-light;
        background: $light;
      }

      &-img {
        height: 96px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include laptop {
          height: 77px;
        }

        img {
          width: 100%;
          max-height: 100%;
          object-fit: contain;
        }

        &-with-footer {
          height: 96px;
          width: 154px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 29px;

          img {
            width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }
      }

      &-footer {
        width: 154px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-top: 1px solid $bg-light;
        padding: 8px 0 8px;

        &-name {
          width: 132px;
          text-align: center;
        }
      }
    }

    &-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;

      @include mobile {
        margin-top: 18px;
      }
    }

    &-footer {
      margin-top: 68px;

      @include laptop {
        margin-top: 57px;
      }

      @include tablet {
        margin-top: 37px;
      }

      @include mobile {
        margin-top: 34px;
      }
    }
  }

  &-provider {
    &-img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 210px;
      height: 133px;
      padding: 0 29px;

      background: $light;
      border: 1px solid $bg-light;
      border-radius: 6px;

      img {
        width: 100%;
        height: 100%;
      }

      &-wrapper {
        width: 100%;
        max-width: 1020px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 15px;
        gap: 10px;

        @include laptop {
          margin-top: 25px;
          max-width: 845px;
          gap: 24px;
        }

        @include tablet {
          margin-top: 26px;
          max-width: 672px;
          gap: 26px;
        }

        @include mobile {
          align-items: center;
          justify-content: center;
          margin-top: 37px;
          gap: 24px;
          padding: 0 28px;
        }
      }
    }

    &-about {
      display: flex;
      width: 100%;
      max-width: 1020px;
      padding-top: 32px;

      gap: 32px;

      @include laptop {
        max-width: 845px;
        padding-top: 42px;
      }

      @include tablet {
        max-width: 672px;
        padding-top: 42px;
      }

      @include mobile {
        align-items: center;
        flex-direction: column-reverse;
        max-width: 328px;
        padding-top: 42px;
      }

      &-line {
        width: 100%;
        max-width: 1024px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid $bg-light;
        margin-top: 32px;

        @include laptop {
          max-width: 100%;
        }
      }

      &-text {
        ol {
          list-style: decimal;
        }

        ul {
          list-style: unset;
        }
      }
    }

    &-attributes {
      min-width: 300px;
      width: 300px;
      height: fit-content;
      border-radius: 8px;
      border: 1px solid $bg-light;
      background: $light;

      @include mobile {
      }

      &-title {
        padding: 16px;
        border-bottom: 1px solid $bg-light;
      }

      &-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 16px;
      }

      &-link {
        text-decoration: none;

        &:not(:last-child):after {
          content: ", ";
        }
      }
    }

    &-recommended {
      display: flex;
      width: 100%;
      max-width: 1024px;
      justify-content: center;
      border-top: 1px solid $bg-light;
      margin-top: 32px;

      @include laptop {
        max-width: 100%;
      }

      &-margin {
        margin-top: 32px !important;
      }
    }
  }

  &-all-slots {
    width: 100%;
    max-width: 1020px;
    display: flex;
    flex-direction: column;
    margin-top: 45px;

    @include laptop {
      max-width: 816px;
    }

    @include tablet-max {
      max-width: 672px;
    }

    @include mobile {
      padding: 0 18px;
    }

    &-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include mobile {
        flex-direction: column;
      }
    }

    &-search {
      width: 200px;

      @include mobile {
        width: 255px;
        margin-top: 15px;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      margin-top: 24px;
      gap: 16px;

      @include laptop {
        max-width: 812px;
      }
      @include tablet {
        max-width: 606px;
      }
      @include mobile {
        max-width: 190px;
      }

      &-stub {
        margin-top: 24px;
        height: 339px !important;
      }

      &-wrapper {
        display: flex;
        width: 100%;
        min-height: 156px;
        align-items: center;
        justify-content: flex-start;

        @include laptop-max {
          justify-content: center;
        }
      }
    }

    &-card {
      position: relative;
      display: flex;
      width: 191px;
      height: 191px;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 4px;
      border: 1px solid $bg-light;

      &-img {
        width: 100%;
        height: 100%;
      }

      &-rating {
        position: absolute;
        display: flex;
        top: 8px;
        left: 8px;
        padding: 7px 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 6px;
        border: 1px solid $bg-light;
        background: $light;

        color: rgba($secondary, 0.75);
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 600;
        line-height: 100%;

        &-small {
          @include mobile {
            padding: 5px 6px !important;
            font-size: 10px;
          }
        }
      }

      &-star {
        width: 15px;
        height: 15px;
        @include mobile {
          width: 12px;
          height: 12px;
        }
      }
    }

    &-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
    }
  }

  &-slot {
    &-back-wrapper {
      width: 100%;
      max-width: 1020px;
      display: flex;
      align-items: center;
      margin-top: 17px;

      @include laptop-max {
        max-width: 632px;
        margin-top: 26px;
      }

      @include tablet {
        margin-top: 17px;
      }

      @include mobile {
        margin-top: 16px;
        padding-left: 24px;
      }
    }

    &-modal {
      @include mobile {
        width: 100%;
        height: 100%;
      }

      &-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 32px 32px 29px;

        input[type=radio] {
          display: none;
        }

        textarea {
          display: flex;
          width: 100%;
          height: 124px;
          padding: 16px;
          align-items: flex-start;
          gap: 8px;
          border-radius: 6px;
          border: 1px solid rgba($stroke-light, 0.20);
          background: $block-hover;
          resize: none;
          margin-top: 18px;

          color: rgba($daylight, 0.75);
          font-family: $font-primary;
          font-size: 16px;
          font-weight: 500;
          line-height: 150%;

          &::placeholder {
            color: rgba($daylight, 0.25);
          }
        }

        &-star {
          width: 35px;
          height: 35px;
          cursor: pointer;
          color: rgba($secondary, 0.20);
          transition: color 0.5s;

          &-hover {
            color: $yellow;
          }

          &-wrapper {
            display: flex;
            align-items: center;
            margin-top: 16px;
          }
        }

        &-btn.shared-btn-primary {
          width: 248px;
          height: 48px;
          margin-top: 26px;
        }
      }

      &-rating {
        display: flex;
        align-items: flex-start;
        width: 45px;
        height: 45px;
        padding-right: 8px;
      }
    }

    &-footer {
      margin-top: 102px;

      @include laptop {
        margin-top: 67px;
      }

      @include tablet {
        margin-top: 72px;
      }

      @include mobile {
        margin-top: 128px;
      }
    }

    &-recommended-list {
      display: flex;
      width: 100%;
      max-width: 1020px;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;

      @include laptop {
        max-width: 845px;
      }

      @include tablet {
        max-width: 672px;
      }

      @include mobile {
        max-width: 328px;
      }

      &-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        gap: 40px;

        @include mobile {
          margin-top: 23px;
          gap: 23px;
        }

        &-border {
          margin-top: 32px;
          width: 100%;
          max-width: 1020px;
          border-top: 1px solid $bg-light;

          @include laptop-max {
            max-width: 100%;
          }
        }
      }
    }

    &-info {
      &-title {
        color: $secondary;
        font-family: $font-primary;
        font-size: 18px;
        font-weight: 700;
        line-height: 150%;

        @include mobile {
          font-size: 13px;
        }
      }

      &-subtitle {
        color: $secondary;
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        margin: 0;

        @include mobile {
          font-size: 10px;
        }
      }

      &-text {
        color: rgba($secondary, 0.50);
        font-family: $font-primary;
        font-size: 12px;
        font-weight: 600;
        line-height: 150%;
        margin: 0;

        @include mobile {
          font-size: 9px;
        }
      }

      &-provider {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include mobile {
          margin-top: 12px;
        }
      }

      &-rating {
        display: flex;
        flex-direction: column;
        gap: 6px;

        &-stars {
          display: flex;
          align-items: center;
          gap: 8px;

          @include mobile {
            gap: 5px;
          }

          &-icon {
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            align-content: center;

            @include mobile {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      &-footer {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 40px;

        @include tablet {
          gap: 48px;
        }

        @include mobile {
          margin-top: 12px;
          gap: 29px;
        }
      }

      &-description {
        display: flex;
        width: 100%;
        padding: 19px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
        border-radius: 10px;
        border: 1px solid rgba($stroke-light, 0.20);
        background: $light;

        @include mobile {
          gap: 24px;
        }

        &-info {
          display: flex;
          flex-direction: column;
          gap: 24px;

          @include laptop-max {
            flex-direction: row;
            gap: 32px;
          }

          @include mobile {
            flex-direction: column;
          }

          &-statistic {
            min-width: 300px;
            width: 300px;
            height: fit-content;
            border-radius: 8px;
            border: 1px solid $bg-light;
            background: $light;

            &-best {
              width: 300px;
              border: 1px solid $primary;
              border-radius: 8px;
              background: $light;
            }
          }

          &-card {
            @include mobile {
              width: 114px;
              height: 114px;
            }
          }

          &-btn {
            @include mobile {
              width: 300px !important;
            }
          }
        }

        &-wrapper {
          width: 100%;
          max-width: 1020px;
          display: flex;
          gap: 40px;
          margin-top: 40px;

          @include laptop-max {
            width: 632px;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
          }

          @include mobile {
            width: 325px;
            margin-top: 23px;
          }
        }
      }

      &-wrapper {
        display: flex;
        width: 100%;
        max-width: 1020px;
        min-height: 156px;
        margin-top: 19px;

        @include laptop-max {
          width: 632px;
          margin-top: 15px;
        }

        @include mobile {
          width: 325px;
          align-items: center;
          flex-direction: column;
          margin-top: 11px;
        }
      }

      &-container {
        gap: 24px;
        display: flex;
        width: 100%;

        @include mobile {
          gap: 18px;
        }
      }
    }

    &-comments {
      display: flex;
      width: 100%;
      padding: 19px 20px;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 19px;
      gap: 40px;

      border-radius: 10px;
      border: 1px solid rgba($stroke-light, 0.20);
      background: $light;

      &-list {
        display: flex;
        width: 100%;
        padding: 19px 20px;
        flex-direction: column;
        align-items: center;
        gap: 40px;

        border-radius: 10px;
        border: 1px solid rgba($stroke-light, 0.20);
        background: $light;
      }
    }

    &-comment {
      display: flex;
      width: 100%;
      padding: 19px 20px;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 19px;

      border-radius: 10px;
      border: 1px solid rgba($stroke-light, 0.20);
      background: $light;

      &-input {
        display: flex;
        width: 100%;
        height: 65px;
        border-radius: 10px;
        border: 1px solid $bg-light;
        background: $block-hover;
        padding-left: 54px;
        padding-top: 21px;
        resize: none;
        color: rgba($secondary, 0.75);
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        transition: height 0.5s;

        &-focus {
          outline: none;
          height: 165px;
        }

        &-length {
          display: flex;
          align-items: center;
          position: absolute;
          opacity: 0;
          z-index: -1;
          right: 32px;
          bottom: 80px;
          transition: opacity 1s;

          &-focus {
            z-index: 0;
            opacity: 1;
          }
        }

        &-img {
          position: absolute;
          left: 36px;
          top: 36px;
          border: 2px solid $deep-night;
        }
      }

      &-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 10px;
        border: 1px solid $bg-light;
        background: $block-hover;
        padding: 18px;
      }

      &-submit.shared-btn-primary {
        height: 37px;
      }

      &-cancel.shared-btn-secondary {
        height: 37px;
        border-radius: 5px;
      }

      &-submit-wrapper {
        width: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 16px;
        gap: 19px;
        opacity: 0;
        transition: opacity 1s;
        z-index: -1;

        &-focus {
          position: relative;
          z-index: 0;
          opacity: 1;
        }
      }

      &-header {
        display: flex;
        max-width: 638px;
        align-items: center;
        justify-content: space-between;
      }

      &-body {
        color: rgba($secondary, 0.75);
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
      }

      &-rating {
        display: flex;
        margin-top: 12px;
        gap: 8px;
      }

      &-user-info {
        display: flex;
        align-items: center;
        gap: 11px;

        &-name {
          color: rgba($secondary, 0.75);
          font-family: $font-primary;
          font-size: 10px;
          font-weight: 600;
          line-height: 150%;
          text-transform: uppercase;
          margin: 0;
        }

        &-date {
          color: rgba($secondary, 0.40);
          font-family: $font-primary;
          font-size: 10px;
          font-weight: 600;
          line-height: 150%;
          text-transform: uppercase;
          margin: 0;
        }
      }

      &-img {
        width: 30px;
        height: 30px;
        border-radius: 63px;
        border: 2px solid $deep-night;
      }

      &-btn {
        &-wrapper {

        }

        &-like {
          display: inline-flex;
          height: 30px;
          padding: 10px 8px;
          align-items: center;
          justify-content: center;
          gap: 4px;

          border-radius: 5px;
          border: 2px solid rgba($stroke-light, 0.20);
          background: $block-hover;
        }

        &-reply {
          display: flex;
          width: 42px;
          height: 30px;
          padding: 10px 11px;
          align-items: center;
          gap: 4px;

          border-radius: 5px;
          border: 2px solid rgba($stroke-light, 0.20);
          background: rgba($secondary, 0.75);
        }
      }
    }
  }

  &-footer {
    margin-top: 53px;
  }

  &-btn.shared-btn-secondary {
    width: 130px;
    height: 40px;

    &:disabled {
      background: rgba($secondary, 0.10);
      box-shadow: none;
      cursor: pointer;
    }
  }

  &-btn--default.shared-btn-secondary {
    height: 40px;
  }

  &-rated {
    img {
      margin-left: 12px;
    }
  }

  &-go-back {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 49px;
    border: 1px solid rgba($secondary, 0.40);
    background: transparent;
    margin-right: 8px;
  }
}
