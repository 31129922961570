@import "styles/variables";

.custom-collapse {
  color: rgba($secondary, .75);
  border-radius: 8px;
  border: 1px solid $bg-light;
  background: $light;

  &-header {
    height: 48px;
    padding: 0 16px;
    border-bottom: 1px solid transparent;

    &.active {
      border-bottom: 1px solid $bg-light;
    }
  }

  &-title {
    color: rgba($secondary, .75);
    font-family: $font-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;

    &.active {
      color: $primary !important;
    }
  }

  &-trigger {
    border-radius: 100%;
    width: 24px;
    height: 24px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba($secondary, .25);
    background: transparent;

    &:hover {
      border: 1px solid rgba($secondary, .25);
    }

    img {
      width: 8px;
      height: 8px;
    }

    &.active {
      border: 1px solid $primary;

      img {
        rotate: 180deg;
      }
    }
  }

  &-content {
    color: rgba($secondary, .75);

    p {
      margin: 16px 16px 32px;
    }
  }
}
