@import "styles/variables";
@import "styles/mixins";

.user-avatar {
  &-img {
    border-radius: 100%;
    overflow: hidden;
    background: transparent;
  }

  &-initials {
    border-radius: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: rgba($headline-color, 0.75);
    border: 1px solid rgba($headline-color, 0.75);
    font-family: $font-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    text-transform: uppercase;

    justify-content: center;
    background: transparent;
  }
}
