@import "styles/variables";
@import "styles/mixins";

.leaderboard {
  background: $deep-night;

  &-stub {
    display: flex;
    width: 100%;
    height: 500px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-leaders {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 67px;

      @include laptop {
        padding-top: 25px;
      }

      @include tablet-max {
        padding-top: 31px;
      }
    }

    &-decoration {
      position: absolute;
      left: 0;
      top: -132px;
      width: 100%;
      z-index: 0;

      @include laptop {
        top: 0;
      }

      @include tablet-max {
        top: 0;
        left: unset;
        width: auto;
      }
    }

    &-container {
      display: flex;
      align-items: flex-end;
      margin-top: 64px;
      gap: 64px;

      @include laptop {
        gap: 30px;
        padding-bottom: 25px;
      }

      @include tablet-max {
        flex-direction: column;
        gap: 10px;
      }
    }

    &-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;

      &-stake {
        width: 125px;
        height: 62px;
        color: $headline-white;
      }

      h1 {
        color: $headline-white;
        font-family: $font-secondary;
        font-size: 85px;
        font-weight: 800;
        line-height: 80%;
        margin: 0;

        @include laptop-max {
          font-size: 70px;
        }
      }
    }

    &-medal {
      position: absolute;
      left: 30px;
      top: -25px;

      @include laptop-max {
        width: 90px;
        height: 90px;
        left: 30px;
        top: -15px;
      }
    }
  }

  &-random-winners {

    &-text {
      color: $headline-white;
      font-family: $font-primary;
      font-size: 55px;
      font-weight: 800;
      margin: 0;

      @include tablet-max {
        font-size: 42px;
      }

      &-gold {
        background: $leaderboard-gold;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &-mobile {
        @include tablet-max {
          font-size: 25px;
        }
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 37px;

      @include laptop {
        padding-top: 0;
      }

      @include tablet-max {
        padding-top: 35px;
      }
    }

    &-container {
      width: 1019px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 28px 0 24px 0;
      border-radius: 10px;
      border: 1px solid rgba($stroke-light, 0.20);
      background: $light;
      margin-top: 37px;

      @include laptop {
        margin-top: 25px;
      }

      @include tablet-max {
        width: 360px;
        flex-direction: column;
      }

      &-side {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-text {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }

        p {
          width: 245px;
          color: rgba($secondary, 0.75);
          text-align: center;
          font-family: $font-primary;
          font-size: 12px;
          font-weight: 600;
          line-height: 150%;
          text-transform: uppercase;
        }

        &-users {
          margin-top: 28px;

          &-text {
            @include tablet-max {
              margin-bottom: 0;
              padding: 0 16px;
            }
          }

          @include tablet-max {
            margin-top: 6px;
          }
        }
      }

      &-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 20px 0 46px;

        @include tablet-max {
          margin: 0;
        }

        img {
          @include tablet-max {
            width: 330px;
          }
        }

        h3 {
          color: $headline-white;
          font-family: $font-secondary;
          font-size: 30px;
          font-weight: 800;
          line-height: 100%;
          margin: 23px 0 0 0;

          span {
            color: $headline-white;
          }

          @include tablet-max {
            font-size: 25px;
          }
        }

        &-icon {
          width: 19px !important;
          height: 28px;
          margin-bottom: 6px;
        }
      }
    }

    &-title {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        color: rgba($secondary, 0.75);
        font-family: $font-primary;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 17px 0 0 0;
      }
    }
  }

  &-active-liderboard {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 96px;
    border-top: 1px solid rgba($secondary, 0.75);
    z-index: 0;

    @include laptop-max {
      border: none;
      margin-top: 25px;
    }

    @include tablet-max {
      margin-top: 21px;
    }

    &-wrapper {

    }

    &-title {
      position: relative;
      width: 452px;
      padding: 0 96px 0 96px;

      @include laptop-max {
        padding: 0;
        width: 257px;
      }

      div {
        position: absolute;
        background: $deep-night;
        width: 452px;
        top: -54px;
        left: 0;
        z-index: 2;

        @include laptop-max {
          position: unset;
          background: none;
          width: 257px;
        }
      }
    }

    &-countdown {
      margin-top: 39px;

      @include laptop-max {
        margin-top: 28px;
      }
    }

    &-table-wrapper {
      margin-top: 47px;

      @include laptop {
        margin-top: 29px;
      }

      @include tablet {
        margin-top: 21px;
      }
    }
  }

  &-section-title {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      margin: 17px 0 0 0;
    }
  }

  &-history {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 37px 0 0;

    @include laptop-max {
      margin: 37px 0 0;
    }

    @include tablet {
      margin-top: 80px;
    }

    &-text {
      width: 491px;
      margin: 20px 0 0;
      color: rgba($secondary, 0.75);
      text-align: center;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      text-transform: uppercase;

      @include tablet {
        width: 354px;
      }

      @include mobile {
        width: 277px;
      }
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;
      gap: 32px;

      @include tablet-max {
        flex-direction: column;
        gap: 20px;
      }

      @include mobile {
        margin-top: 19px;
      }
    }

    &-btn {
      width: 174px;
      height: 48px;
      margin-top: 38px;

      @include laptop {
        margin-top: 32px;
      }
    }
  }

  &-footer {
    margin-top: 33px;

    @include laptop {
      margin-top: 32px;
    }

    @include tablet {
      margin-top: 47px;
    }

    @include mobile {
      margin-top: 121px;
    }
  }
}

.current-leaderboard {
  background: $deep-night;

  &-section {
    border-top: 1px solid rgba($secondary, 0.75);
    margin-top: 93px;
    margin-bottom: 52px;

    @include laptop {
      margin-top: 65px;
    }

    @include tablet-max {
      margin-top: 93px;
      margin-bottom: 30px;
      border: none;
    }

    @include mobile {
      margin-top: 70px;
      margin-bottom: 32px;
    }

    &-title {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        position: absolute;
        top: -48px;
        z-index: 2;
      }

      h2 {
        width: 452px;
        position: absolute;
        top: -35px;
        color: rgba($secondary, 0.75);
        background: $deep-night;
        font-family: $font-primary;
        border: 1px solid rgba($secondary, 0.40);
        border-radius: 8px;
        padding: 24px;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        z-index: 1;

        @include tablet-max {
          width: 344px;
          font-size: 14px;
        }
      }

    }

    &-table-wrapper {
      margin-top: 77px;
    }
  }

  &-random-winners {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 37px;

    &-content {
      width: 1019px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 37px;
      gap: 21px;

      @include tablet-max {
        width: 342px;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 0;
      }
    }
  }
}

.leaderboard-history-page {
  background: $deep-night;

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 67px;
    z-index: 1;
  }

  &-decoration {
    position: absolute;
    left: 0;
    top: -132px;
    width: 100%;
    z-index: 0;
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-top: 40px;

    @include laptop-max {
      margin-top: 35px;
    }

    @include mobile {
      flex-direction: column;
      margin-top: 41px;
    }
  }

  &-title {
    margin-top: 45px;

    h1 {
      color: $headline-white;
      text-align: center;
      font-family: $font-secondary;
      font-size: 55px;
      font-weight: 800;
      line-height: 100%;
      margin: 0;

      @include mobile {
        font-size: 35px;
      }
    }
  }

  &-text {
    width: 491px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 27px;

    @include mobile {
      width: 348px;
    }

    h2 {
      margin: 20px 0 0;
      color: rgba($secondary, 0.75);
      text-align: center;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      text-transform: uppercase;
    }
  }

  &-pagination {
    margin: 54px 0 52px;

    @include laptop-max {
      margin: 32px 0 36px;
    }

    @include mobile {
      margin: 39px 0 33px;
    }
  }
}
