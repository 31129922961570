@import "variables";

@each $abbr, $name in ('t': 'top', 'r': 'right', 'b': 'bottom', 'l': 'left') {
  @for $i from 0 through 12 {
    .p-#{$abbr}-#{$i} {
      padding-#{$name}: 5px * $i !important;
    }
    .m-#{$abbr}-#{$i} {
      margin-#{$name}: 5px * $i !important;
    }
    .p-all-#{$i} {
      padding: 5px * $i !important;
    }
    .m-all-#{$i} {
      margin: 5px * $i !important;
    }
  }

  .m-#{$abbr}-auto {
    margin-#{$name}: auto;
  }
}


// Sizes Variable for height and width mixins
$sizes-px: (
  // in px
        15: 15px,
        20: 20px,
        25: 25px,
        50: 50px,
        100: 100px,
        150: 150px,
        200: 200px,
        250: 250px,
        300: 300px,
        350: 350px,
        400: 405px,
        450: 455px,
        500: 500px,
        550: 550px,
        600: 600px,
        650: 650px,
        700: 700px,
        750: 750px,
        800: 800px
) !default;

$sizes-per: (
  // in %
        5: 5%,
        10: 10%,
        15: 15%,
        20: 20%,
        25: 25%,
        30: 30%,
        35: 35%,
        40: 40%,
        45: 45%,
        50: 50%,
        55: 55%,
        60: 60%,
        65: 65%,
        70: 70%,
        75: 75%,
        80: 80%,
        85: 85%,
        90: 90%,
        95: 95%
) !default;

// height & width in PX
@each $name, $size in $sizes-px {
  .height-#{$name} {
    height: $size !important;
  }

  .min-height-#{$name} {
    min-height: $size !important;
  }

  .width-#{$name} {
    width: $size !important;
  }

  .min-width-#{$name} {
    min-width: $size !important;
  }
}

// height & width in %
@each $name, $size in $sizes-per {
  .height-#{$name}-per {
    height: $size !important;
  }

  .width-#{$name}-per {
    width: $size !important;
  }
}

//-------------- Z-index --------------
//-------------------------------------
.zindex-1 {
  z-index: 1 !important;
}

.zindex-2 {
  z-index: 2 !important;
}

.zindex-3 {
  z-index: 3 !important;
}

.zindex-4 {
  z-index: 4 !important;
}
.zindex-max {
  z-index: 998 !important;
}

.zindex-0 {
  z-index: 0 !important;
}

//-------------- Cursor --------------
//------------------------------------
.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.cursor-default {
  cursor: default;
}

.cursor-progress {
  cursor: progress;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.text-primary {
  font-family: var(--font-primary);
}

.text-secondary {
  font-family: var(--font-secondary);
}

//-------------- Font Weight --------------
//-----------------------------------------
.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.text-body {
  font-size: 14px;
  line-height: 17px;
}

.bg-transparent {
  background: transparent !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.position-fixed {
  position: fixed;
}

.position-static {
  position: static;
}

.position-sticky {
  position: sticky;
}

.d-block {
  display: block !important;
}

.d-inline {
  display: inline !important;
}

.d-grid {
  display: grid !important;
}

.d-flex {
  display: flex !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.flex-cell {
  flex: 1;
}

.spinner {
  display: inline-block;
  border: 3px solid var(--color-bright-50);
  border-radius: 50%;
  border-top-color: var(--color-primary);
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.no-overflow {
  overflow: hidden !important;
}

.toast-wrapper {
  background: $deep-night !important;

  .Toastify__toast-body > div {
    color: rgba($secondary, 0.75);
  }

  .Toastify--animate-icon {
    svg {
      fill: $primary;
    }
  }

  .Toastify__progress-bar--success {
    background: $primary;
  }

  .Toastify__close-button {
    color: rgba($secondary, 0.75);
  }
}

.no-user-select {
  user-select: none !important;
}

.text-daylight {
  color: $daylight !important;
}

.text-color-primary {
  color: $primary !important;
}

.text-green {
  color: $prize-green !important;
}

.text-ban-red {
  color: $ban-red !important;
}

.text-blue {
  color: $blue !important;
}

.text-yellow {
  color: $yellow
}

.text-headline-white {
  color: $headline-white !important;
}

.text-main-bg {
  color: $deep-night !important;
}

.text-daylight {
  color: $daylight !important;
}

.text-secondary-100 {
  color: $secondary !important;
}

.text-secondary-75 {
  color: rgba($secondary, 0.75) !important;
}

.text-secondary-50 {
  color: rgba($secondary, 0.50) !important;
}

.text-secondary-40 {
  color: rgba($secondary, 0.40) !important;
}

.text-secondary-25 {
  color: rgba($secondary, 0.25) !important;
}

.text-secondary-15 {
  color: rgba($secondary, 0.15) !important;
}

.w-20 {
  width: 20% !important;
}

.text-inherit {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-transform: inherit;
}

.color-inherit {
  color: inherit;
}

.border-none {
  border: none !important;
}

.width-fit-content {
  width: fit-content !important;
}
