@import "styles/variables";
@import "styles/mixins";

.custom-social-card {
  width: 291px;
  height: 357px;
  background: $light;
  border-radius: 10px;
  border: 1px solid rgba($stroke-light, .20);
  transition: transform 0.3s ease-in-out 0s;

  &:hover {
    transform: scale(1.04);
  }

  @include laptop {
    width: 217px;
    height: 267px;
  }

  @include tablet-max {
    margin-bottom: 40px;
  }

  img {
    @include laptop {
      height: 101px;
      width: 101px;
    }
  }

  &-footer {
    background: $block-light;
    margin-top: 24px;
    padding: 24px;
    border-top: 1px solid rgba($stroke-light, .20);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    @include laptop {
      padding: 18px;
    }
  }

  &-link.shared-btn-primary {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    text-decoration: none;

    @include laptop {
      height: 36px;
    }
  }

  h4 {
    color: rgba($secondary, .75);
    font-family: $font-primary;
    font-size: 20px;
    font-weight: 700;
    line-height: 150%;
    margin: 24px 0 0 0;
    @include laptop {
      font-size: 14px;
      margin: 18px 0 0 0;
    }
  }

  h5 {
    color: rgba($secondary, .75);
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
    margin: 0;
    @include laptop {
      font-size: 10px;
    }
  }

  &-icon {
    position: absolute;
    height: 48px;
    width: 48px;
    color: rgba($secondary, .75);

    @include laptop {
      height: 36px;
      width: 36px;
    }
  }

  &-halo {
    width: 136px;
    height: 136px;

    @include laptop {
      height: 101px;
      width: 101px;
    }
  }

  .facebook {
    color: $facebook;
  }

  .kick {
    color: $kick;
  }

  .youtube {
    color: $youtube;
  }

  .instagram {
    fill: url(#paint0_linear_274_2753);
  }


}
