@import "variables";

.custom-select-wrapper {
  min-width: 160px;

  .custom-select {
    &__indicators {
      width: 24px;
      height: 24px;
      border: 1px solid rgba($secondary, 0.75);
      border-radius: 50%;
    }

    &__dropdown-indicator {
      width: 22px;
      height: 22px;
      align-items: center;
      color: rgba($secondary, 0.75);
      transition: all linear 0.2s;
      padding: 2px;
    }

    &__control {
      display: flex;
      cursor: pointer;
      height: 40px;
      padding: 8px;
      gap: 34px;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      border-radius: 4px;
      background: $block-light;

      &:hover {
        border-color: rgba($secondary, 0.05);
      }

      &--menu-is-open {
        .custom-select__dropdown-indicator {
          transform: rotate(-180deg);
        }
      }
    }

    &__value-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
    }

    &__single-value {
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      text-transform: uppercase;
    }

    &__menu {
      background-color: $block-light;
      z-index: 2;
    }

    &__option {
      border-radius: 4px;
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
      text-transform: uppercase;
      cursor: pointer;

      &:active {
        background-color: rgba($secondary, 0.1);
      }

      &--is-selected {
        background-color: rgba($secondary, 0.1);
        color: $headline-white;
      }
    }
  }
}
