@import 'styles/variables';
@import 'styles/mixins';

.daily-case,
.dc {
  font-family: $font-primary;
  color: $grey;

  &-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 100vh;
    background: $deep-night;
  }

  &-footer {
    margin-top: 112px;

    @include laptop {
      margin-top: 154px;
    }

    @include tablet {
      margin-top: 70px;
    }

    @include mobile {
      margin-top: 69px;
    }
  }

  &-container {
    text-align: center;
    margin: 20px 0;
  }
  &-text {
    color: $secondary;
    font-weight: 500;
    font-family: $font-primary;

    &-upper {
      text-transform: uppercase;
    }

    &-secondary {
      color: $headline-white;
      font-family: $font-secondary;
    }
  }

  &-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    align-items: center;
    border: 1px solid rgba($secondary, 0.15);
    border-radius: 4px;
    background-color: rgba($secondary, 0.05);
    padding: 15px 30px;
    transition: background-color 0.3s;
    font-weight: 500;
    color: rgba($white, 0.5);
    margin: 15px 0;

    &:hover {
      background-color: rgba($secondary, 0.1);
    }

    &-active {
      background-color: rgba($success, 0.75);
      cursor: default;
      &:hover {
        background-color: rgba($success, 0.75);
      }
    }

    &-primary {
      color: $light;
      background-color: $primary;
      border: none;
      font-weight: 700;
      &:hover {
        background-color: rgba($primary, 0.75);
      }
    }

    &-wide {
      width: 370px;
      height: 48px;
      @include mobile {
        width: 318px;
      }
    }
  }

  .daily-case-open,
  .dco {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;

    &-open {
      &-btn {
        img {
          width: 15px;
          height: 15px;
          margin-left: -10px;
        }
      }
    }

    &-roulette {
      position: relative;
      min-width: 100%;
      min-height: 200px;
      margin-bottom: 30px;
      width: 100%;
      border: 1px solid rgba($secondary, 0.15);
      &-arrow {
        position: absolute;
        left: 50%;
        height: 100%;
        width: 1px;
        &-top {
          position: absolute;
          top: 0;
          left: -3px;
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 11px solid rgba($secondary, 0.5);
        }
        &-bottom {
          position: absolute;
          bottom: 0;
          left: -3px;
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 11px solid rgba($secondary, 0.5);
        }
      }

      &-item {
        width: 200px;
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        border-right: 1px solid rgba($secondary, 0.05);
        span {
          font-size: 16px;
          color: $secondary;
          font-weight: 500;
          font-family: $font-secondary;
          margin-top: 20px;
          text-transform: uppercase;
        }
        img {
          width: 120px;
          height: 120px;
        }
      }
    }

    &-case-image {
      width: 250px;
      height: 250px;
      margin: 20px 0;
      img {
        width: 250px;
        height: 250px;
      }
    }

    &-connection {
      width: 120px;
      height: 83px;
      flex-direction: column;
      padding: 0;
      gap: 0;
      margin: 0;

      &-container {
        display: flex;
        justify-content: space-around;
        width: 290px;
        margin: 10px 0;
        gap: 5px;
      }

      &-separator {
        width: 100%;
        height: 1px;
        background: rgba($secondary, 0.15);
      }

      &-img {
        width: 100%;
        height: 48px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        img {
          width: 24px;
          height: 24px;
        }
      }

      &-caption {
        padding: 5px 10px;
        font-weight: 500;
        color: rgba($secondary, 0.75);
        font-size: small;
      }
    }
  }

  .daily-case-list,
  .dcl {
    width: 100%;
    padding: 20px;
    background: $deep-night;

    &-list-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 15px;
      justify-items: center;
      padding: 20px;
    }

    &-item {
      width: 154px;
      height: 188px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      background-color: rgba($secondary, 0.05);
      padding: 8px;
      border-radius: 5px;
      color: #fff;

      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: translateY(-10px);
      }

      &-chance {
        &-wrapper {
          padding: 0;
          margin: 0;
          width: 100%;
          display: flex;
          flex-direction: row-reverse;
          margin-bottom: 10px;
          gap: 15px;

          .dc-stock-msg {
            color: $red;
            font-weight: 700;
            font-size: 11px;
          }
        }

        &-text {
          color: rgba($secondary, 0.75);
          font-size: 12px;
          border: 1px solid rgba($secondary, 0.25);
          border-radius: 40px;
          width: 50px;
        }
      }
      &-img {
        width: 75px;
        height: 75px;
        margin-bottom: 10px;
        img {
          width: 75px;
          height: 75px;
        }
      }

      &-title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 2px;
      }

      &-description {
        font-size: 12px;
        max-width: 90%;
      }

      &.dcl-quality-1 {
        border-bottom: 3px solid $yellow;
      }
      &.dcl-quality-2 {
        border-bottom: 3px solid $primary;
      }
      &.dcl-quality-3 {
        border-bottom: 3px solid $cyan;
      }
      &.dcl-quality-4 {
        border-bottom: 3px solid $purple-light;
      }
      &.dcl-quality-5 {
        border-bottom: 3px solid $silver;
      }
    }
  }

  .modal-header {
    border: 0;
  }

  .daily-case-modal,
  .dcm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 100vh;

    &-content {
      margin: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-header {
      color: $white;
      font-weight: 500;
      margin-bottom: 20px;
    }

    &-img {
      img {
        height: 100px;
        width: 100px;
      }
      margin-bottom: 20px;
    }
    &-item-title {
      font-weight: 700;
      margin-bottom: 5px;
    }
    &-item-price {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 10px;

      &-label {
        color: rgba($secondary, 0.5);
      }

      &-number {
        color: rgba($secondary, 0.75);
        font-size: 14px;
        font-weight: 600;
        border: 1px solid rgba($secondary, 0.25);
        padding: 3px 12px;
        border-radius: 40px;
      }
    }
    &-item-alert {
      width: 370px;
      text-align: center;
      margin-bottom: 5px;
    }
  }
}
