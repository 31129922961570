@import "styles/variables";
@import "styles/mixins";

.raffles {
  background: $deep-night;

  &-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 32px;
    margin-top: 35px;

    @include laptop {
      width: 956px;
    }

    @include tablet {
      width: 624px;
    }

    @include mobile {
      width: 296px;
    }

    &-stub {
      display: flex;
      width: 100%;
      height: 379px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 17px;
      background: $light;

      &-wrapper {
        display: flex;
        width: 100%;
        height: 379px;
        justify-content: center;
        align-items: center;
      }

      &-text {
        color: rgba($secondary, 0.40);
        text-align: center;
        font-family: $font-secondary;
        font-size: 55px;
        font-style: normal;
        font-weight: 800;
        margin: 0;

        @include tablet {
          font-size: 30px;
        }

        @include mobile {
          font-size: 22px;
        }
      }
    }

    &-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-title {
    width: 100%;
    color: $headline-white;
    font-family: $font-primary;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;

    &-trust {
      display: flex;
      width: 234px;
      height: 78px;
      padding: 11px 13px;
      align-items: center;
      gap: 16px;
      flex-shrink: 0;

      border-radius: 10px;
      border: 1px solid rgba($secondary, 0.75);
      opacity: 0.3;

      @include tablet-max {
        width: 198px;
        height: 64px;
        padding: 9px 11px;
      }

      &-text {
        color: rgba($secondary, 0.75);
        font-family: $font-primary;
        font-size: 15px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;

        @include tablet-max {
          font-size: 12px;
        }
      }

      img {
        width: 56px;
        height: 56px;
      }

      &-wrapper {
        position: absolute;
        right: 0;

        @include mobile {
          position: unset;
        }
      }
    }

    &-img {
      width: 65px;
      height: 65px;
      margin-right: 16px;
    }

    &-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }

  &-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include laptop {
      padding: 0 36px;
    }

    @include tablet {

    }

    @include mobile {

    }

    &-current {
      margin-top: 28px;
    }

    &-header {
      width: 100%;
      display: flex;

      @include laptop {
        width: 956px;
      }

      @include tablet {
        width: 624px;
      }

      @include mobile {
        width: 296px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 34px;
      }
    }

    &-ended {
      margin-top: 35px;

      &-pagination {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 47px;

        @include laptop {
          margin-top: 43px;
        }

        @include tablet {
          margin-top: 52px;
        }
      }
    }
  }

  &-card {
    width: 296px;
    height: 379px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid rgba($stroke-light, 0.20);
    background: $raffle-bg;
    padding: 20px 24px 24px;

    &-border {
      &:after {
        content: " ";
        width: 64px;
        height: 40px;
        border: 1px solid rgba($stroke-light, 0.20);
        background: $deep-night;
        border-radius: 24px;
        position: absolute;
        left: -32px;
        bottom: 95px;
        z-index: 0;
      }

      &:before {
        content: " ";
        width: 64px;
        height: 40px;
        border: 1px solid rgba($stroke-light, 0.20);
        background: $deep-night;
        border-radius: 24px;
        position: absolute;
        right: -32px;
        bottom: 95px;
        z-index: 0;
      }

    }

    &-img {
      height: 88px;
      margin-bottom: 10px;
    }

    &-title {
      width: 246px;
      color: $headline-white;
      text-align: center;
      font-family: $font-secondary;
      font-size: 22px;
      font-weight: 800;
      line-height: normal;
      text-transform: uppercase;
      margin: 0;
    }

    &-description {
      width: 100%;
      max-width: 246px;
      color: rgba($secondary, 0.75);
      text-align: center;
      font-family: $font-primary;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      margin: 0;
    }

    &-entries {
      width: 100%;
      position: relative;
      display: inline-flex;
      flex-direction: column;
      height: 46px;
      justify-content: center;
      align-items: center;
      margin-top: auto;
      border-radius: 4px;
      border: 1px solid rgba($stroke-light, 0.20);
      background: $block-hover;

      &-title {
        display: flex;
        position: absolute;
        align-items: center;
        top: -8px;
        text-align: center;
        font-family: $font-primary;
        color: rgba($secondary, 0.75);
        font-size: 12px;
        font-weight: 600;
        line-height: 100%;
        text-transform: uppercase;
        padding: 0 8px;
        margin: 0;
        gap: 4px;
        z-index: 2;

        &:before {
          position: absolute;
          content: " ";
          top: 7px;
          left: 0;
          height: 1px;
          width: 100%;
          background: $deep-night;
          z-index: -1;
        }
      }
    }

    &-countdown {
      width: fit-content;
      display: flex;
      align-items: center;
      height: 32px;
      border-radius: 24px;
      gap: 8px;

      &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: auto;
      }

      &-hourglass {
        display: flex;
        width: 32px;
        height: 32px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        border-radius: 39px;
        border: 1px solid rgba($stroke-light, 0.20);
        background: $block-hover;
        margin-left: auto;

        &-primary {
          color: $primary;
        }

        &-secondary {
          color: rgba($secondary, 0.40);
        }
      }
    }

    &-btn {
      height: 48px !important;
      margin-top: 28px;

      &-img {
        margin-bottom: 2px;
      }

      &-ended {
        margin-top: 17px;
        display: flex;
        width: 100%;
        height: 48px;
        padding: 9px 24px;
        justify-content: center;
        align-items: center;

        border-radius: 4px;
        border: 1px solid rgba($secondary, 0.05);
        background: rgba($secondary, 0.05);

        &:hover {
          background: rgba($secondary, 0.10);
        }

        &:active {
          background: transparent;
        }
      }
    }
  }

  &-footer {
    margin-top: 112px;

    @include laptop {
      margin-top: 154px;
    }

    @include tablet {
      margin-top: 70px;
    }

    @include mobile {
      margin-top: 69px;
    }
  }

  &-modal {

    &-body {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 21px 24px 24px;
    }

    &-footer {
      padding: 0 24px 44px;
    }

    &-countdown {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;

      border-radius: 4px;
      border: 1px solid $bg-light;
      background: $deep-night;
    }

    &-time {
      width: 100%;
      position: relative;
      display: inline-flex;
      flex-direction: column;
      height: 60px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid $bg-light;
      background: $deep-night;
      margin-top: 21px;

      &-title {
        display: flex;
        position: absolute;
        align-items: center;
        top: -8px;
        text-align: center;
        font-family: $font-primary;
        color: rgba($secondary, 0.75);
        font-size: 12px;
        font-weight: 600;
        line-height: 100%;
        text-transform: uppercase;
        padding: 0 8px;
        margin: 0;
        gap: 4px;
        z-index: 2;

        &:before {
          position: absolute;
          width: 100%;
          content: " ";
          top: 7px;
          left: 0;
          height: 1px;
          background: $light;
          z-index: -1;
        }
      }
    }
  }

  &-btn-all {
    width: 174px;
    height: 48px;
    margin-top: 40px;

    @include laptop {
      margin-top: 29px;
    }

    @include tablet {
      margin-top: 46px;
    }
  }
}

.selected-raffle {
  width: 1020px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $deep-night;
  margin-top: 20px;

  @include tablet {
    width: 630px;
  }

  @include mobile {
    width: 315px;
  }

  &-cta {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      flex-direction: column;
    }
  }

  &-btn {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 49px;
    border: 1px solid rgba($secondary, 0.40);
    background: transparent;

    &-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;

      &-back {
        justify-content: flex-start;
      }

      &-help {
        justify-content: flex-end;
      }
    }
  }

  &-info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    gap: 32px;

    @include laptop {
      margin-top: 40px;
    }

    @include tablet {
      flex-direction: column;
      width: 414px;
      margin-top: 32px;
    }

    @include mobile {
      flex-direction: column;
      width: 314px;
      margin-top: 11px;
    }

    &-headline {
      display: flex;
      width: fit-content;
      color: $headline-white;
      font-family: $font-secondary;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      text-transform: uppercase;
      margin: 0;

      &-wrapper {
        margin-top: 40px;

        @include laptop {
          margin-top: 35px;
        }

        @include tablet {
          margin-top: 47px;
        }

        @include mobile {
          margin-top: 16px;
        }
      }
    }

    &-caption {
      width: 100%;
      font-family: $font-secondary;
      color: rgba($secondary, 0.75);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin: 0;
      @include mobile {
        font-size: 14px;
      }
    }

    &-img {
      min-height: 240px;
      height: 260px;
      width: 100%;
      max-width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid rgba($stroke-light, 0.20);
      background: $deep-night;
      padding: 7px 30px;
      align-self: flex-start;

      @include tablet-max {
        max-height: 240px;
        min-height: 188px;
        height: unset;
        flex: unset;
      }

      img {
        max-height: 100%;
        width: 100%;
        object-fit: contain;

        @include tablet-max {
          max-height: 188px;
        }
      }
    }

    &-raffle {
      min-height: 260px;
      display: flex;
      flex-direction: column;
      max-width: 500px;
      width: 100%;
      justify-content: space-between;
      gap: 24px;

      @include tablet-max {
        height: fit-content;
        min-height: unset;
      }

      &-title {
        width: 100%;
        display: flex;
        align-items: center;
        color: $headline-white;
        font-family: $font-primary;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 21px;
          padding: 2px 8px;
          border-radius: 49px;
          border: 1px solid rgba($secondary, 0.40);
        }
      }

      &-row {
        display: flex;
        align-items: center;
        gap: 24px;
      }

      &-cell {
        background: transparent;
        padding: 0 12px;

        @include mobile {
          padding: 0 6px;
        }
      }

      &-entry {
        width: fit-content;
        max-width: 75px;
        min-width: 40px;
        display: flex;
        align-items: center;
        border-radius: 3px;
        padding: 5px;
        background: $block-light;
        gap: 6px;
        color: rgba($secondary, 0.75);
        font-family: $font-primary;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;

        &-block {
          min-width: fit-content;
        }

        &-hide-last-child {
          @include tablet-max {
            &:last-child {
              display: none;
            }
          }
        }

        &-img {
          width: 15px;
          height: 11px;

          &-modal {
            width: 21px;
            height: 15px;
          }
        }

        &-wrapper {
          display: flex;
          margin-right: auto;
          gap: 5px;
        }

        &-btn {
          display: flex;
          width: fit-content;
          height: 19px;
          padding: 4px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          border: 1px solid rgba($stroke-light, 0.20);
          background: $deep-night;
          margin-left: 4px;

          color: rgba($secondary, 0.75);
          font-family: $font-primary;
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          text-transform: capitalize;
        }
      }
    }
  }

  &-entries {
    width: 100%;
    position: relative;
    border-top: 1px solid rgba($stroke-light, 0.20);
    margin-top: 77px;
    z-index: 1;

    @include laptop {
      margin-top: 38px;
    }

    @include tablet {
      margin-top: 59px;
    }

    @include mobile {
      margin-top: 38px;
      width: 246px;
    }

    &-title {
      position: absolute;
      top: -11px;
      left: 0;
      padding-right: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      z-index: 2;

      &:before {
        position: absolute;
        width: 100%;
        content: " ";
        top: 10px;
        left: 0;
        height: 1px;
        background: $light;
        z-index: -1;
      }
    }

    &-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;
      gap: 16px;
    }

    &-card {
      display: flex;
      width: 113px;
      height: 150px;
      padding: 20px 13px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      border-radius: 4px;
      border: 1px solid rgba($secondary, 0.05);
      background: rgba($secondary, 0.05);

      &-winner {
        background: #18202E;
        box-shadow: 0 1px 21px 0 rgba(247, 224, 65, 0.30);
        position: relative;
        z-index: 1;

        &-wrapper {
          max-width: 852px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          column-gap: 15px;
          row-gap: 26px;
          margin-top: 31px;
          margin-bottom: 3px;

          @include laptop {
            margin-bottom: 14px;
          }

          @include tablet {
            max-width: 630px;
            margin-top: 15px;
            margin-bottom: 8px;
          }

          @include mobile {
            max-width: 100%;
            flex-direction: column;
            margin-top: 10px;
            margin-bottom: 12px;
          }
        }

        &-container {
          width: 200px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-top: 20px;
          gap: 32px;
        }

        &-title {
          color: $primary;
          font-family: $font-secondary;
          font-size: 30px;
          font-weight: 800;
          line-height: 150%;
          margin-top: 38px;
          margin-bottom: 0;

          @include laptop {
            margin-top: 39px;
          }

          @include tablet {
            margin-top: 32px;
          }

          @include mobile {
            margin-top: 42px;
          }
        }
      }

      &-decoration {
        &-top {
          position: absolute;
          top: -16px;
          right: -14px;
          width: 46px;
          height: 40px;
          z-index: 2;
        }

        &-bottom {
          top: 0;
          right: 26px;
          position: absolute;
          width: 45px;
          height: 38px;
          z-index: 1;
        }
      }

      &-avatar {
        width: 64px;
        height: 64px;
        min-width: 64px;
        min-height: 64px;
        max-width: 64px;
        max-height: 64px;
        border-radius: 75px;
        border: 2px solid $deep-night;

        &-empty {
          background: $deep-night;
        }

        &-wrapper {
          width: 64px;
          height: 64px;
        }

        &-stub {
          background: $deep-night;
        }
      }

      &-number {
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        width: 24px;
        height: 24px;
        padding: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-radius: 49px;
        border: 1px solid rgba($stroke-light, 0.20);
        background: $light;

        &-winner {
          background: $gradient-gold;
        }
      }

      &-name {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &-pagination {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-top: 33px;

      @include laptop {
        margin-top: 36px;
      }

      @include tablet {
        margin-top: 31px;
      }

      @include mobile {
        margin-top: 35px;
        flex-direction: column;
        gap: 19px;
      }
    }
  }

  &-footer {
    margin-top: 68px;

    @include laptop {
      margin-top: 44px;
    }

    @include tablet {
      margin-top: 65px;
    }

    @include mobile {
      margin-top: 35px;
    }
  }
}

.instruction-modal {
  &-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px;
    gap: 24px;
  }

  &-title {
    color: $primary;
    font-family: $font-primary;
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
    margin: 0;
  }

  &-subtitle {
    color: $daylight;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    margin: 0;
  }

  &-signature {
    color: rgba($faded, 0.40);
    font-family: $font-primary;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    width: 100%;
    max-width: 100%;
    overflow-wrap: break-word;
  }
}

.tickets-modal {
  width: 100%;
  max-width: 438px;
  margin: auto;

  @include mobile {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  &-content {
    @include mobile {
      height: 100vh;
      border-radius: 0 !important;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    gap: 24px;
  }

  &-avatar {
    width: 79px;
    height: 79px;
    border-radius: 108px;
    border: 2px solid $deep-night;
    margin-bottom: 10px;
  }

  &-text {
    color: $daylight;
    font-family: $font-primary;
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
    margin: 0;
  }

  &-container {
    width: fit-content;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(5, minmax(40px, auto));
    align-items: center;
    justify-content: flex-start;
    padding: 4px;
    flex-wrap: wrap;
    row-gap: 6px;
    column-gap: 10px;
    background: radial-gradient(152.76% 130.7% at 50% 0%, rgba(101, 101, 101, 0.05) 0%, rgba(101, 101, 101, 0.00) 100%), #151C2C;

    @include mobile {
      max-width: 336px;
      grid-template-columns: auto auto auto auto;
    }

    &-few-entries {
      width: fit-content;
      display: flex;
      row-gap: 6px;
      column-gap: 10px;
    }
  }
}

.fireworks-modal {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  &-content {
    position: relative;
    width: 100%;
    max-width: 1360px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 63px;
    z-index: 2;

    @include mobile {
      gap: 48px
    }
  }

  &-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 6;
    overflow-y: scroll;
    background: linear-gradient(116deg, rgba(255, 255, 255, 0.24) 3.3%, rgba(217, 217, 217, 0.09) 57.65%);
    backdrop-filter: blur(7px);
  }

  &-title {
    margin-top: 20px;
    margin-bottom: -20px;
    font-family: $font-secondary;
    font-size: 115px;
    font-style: normal;
    font-weight: 800;
    line-height: 115px;
    background: $gradient-gold;
    background-clip: text;
    text-transform: uppercase;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include laptop-max {
      font-size: 100px;
      line-height: 100px;
    }

    @include mobile {
      font-size: 45px;
      line-height: 45px;
    }
  }

  &-list {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    gap: 63px 63px;

    @include laptop {
      gap: 52px 72px;
    }

    @include tablet {
      gap: 72px 40px;
    }

    @include mobile {
      gap: 48px;
    }

    &-item {
      width: 212px;
      height: 246px;

      @include laptop-max {
        width: 173px;
        height: 230px;
      }

      @include mobile {
        width: 102px;
        height: 173px;
      }

      &-avatar {
        width: 143px;
        height: 143px;
        min-width: 143px;
        min-height: 143px;
        max-width: 143px;
        max-height: 143px;
        border-radius: 100%;
        border: 2px solid $deep-night;

        @include laptop-max {
          width: 98px;
          height: 98px;
          min-width: 98px;
          min-height: 98px;
          max-width: 98px;
          max-height: 98px;
        }

        @include mobile {
          width: 58px;
          height: 58px;
          min-width: 58px;
          min-height: 58px;
          max-width: 58px;
          max-height: 58px;
        }
      }

      &-decoration {
        right: -16px;
        top: -20px;
      }
    }
  }
}
