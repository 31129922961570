@import "styles/variables";
@import "styles/mixins";

.admin-leaderboards {
  background: $deep-night;
  padding: 60px 40px 0 40px;

  &-modal {
    width: 586px;
    margin-left: auto;
    margin-right: auto;

    &-btn.shared-btn-primary, &-btn.shared-btn-secondary {
      width: 172px;
      height: 41px;
      border-radius: 5px;
    }

    &-title {
      color: $daylight;
      font-family: $font-primary;
      font-size: 18px;
      font-weight: 700;
      line-height: 100%;
      text-transform: uppercase;
      margin-top: 15px;

      &-area {
        margin: 25px 13px 10px 0;
        color: rgba($daylight, 0.50);
        font-family: $font-primary;
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
      }
    }

    &-text {
      color: rgba($daylight, 0.75);
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
      margin: 25px 0 0;
    }

    &-body {
      padding: 16px 43px 28px 43px;

      &-unban {
        padding: 65px 29px 44px 31px;
        text-align: center;
      }

      textarea {
        width: 100%;
        height: 124px;
        background: $block-hover;
        border-radius: 6px;
        border: 1px solid $primary;
        resize: none;
        color: rgba($daylight, 0.75);
        font-family: $font-primary;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        padding: 10px 16px;

        &::placeholder {
          color: rgba($daylight, 0.25);
          font-family: $font-primary;
          font-size: 16px;
          font-weight: 400;
          line-height: 100%;
        }
      }
    }

    &-footer {
      border: none !important;
      display: flex;
      justify-content: space-between;
      margin: 0 94px 39px;
      padding: 0;
    }
  }

  &-content {
    max-width: 1024px;
  }

  &-title {
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

    &--margin {
      margin: 36px 0 0;
    }
  }

  &-subtitle {
    margin: 16px 0 0 0;
    color: rgba($faded, 0.4) !important;
  }

  &-btn {
    &-add {
      width: 254px;
      height: 48px !important;
      padding: 0 !important;

      &--margin {
        margin: 34px 0 0;
      }
    }

    &-random {
      width: 283px;
      height: 48px;
      border-radius: 5px !important;
      margin-left: auto;

      img {
        margin-right: 8px;
      }
    }

    &-future {
      margin-top: 16px;
      width: 254px;
      height: 48px;
      border-radius: 0 !important;
      padding: 0 !important;
    }
  }

  &-icon {
    &-add {
      width: 24px;
      height: 24px;
      padding: 6px;
      margin-right: 8px;
      border-radius: 32px;
      border: 1px solid rgba($faded, 0.4);
    }

    &-future {
      margin-right: 8px;
      width: 26px;
      height: 26px;
    }
  }

  &-future {
    &-table {
      &--startDate {
        width: 80px;
      }

      &--endDate {
        width: 75px;
      }

      &--duration {
        width: 80px;
      }
    }
  }

  &-list {
    display: flex;
    max-width: 1024px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 28px;
    gap: 32px;

    @include admin-laptop {
      max-width: 672px;
    }

    @include admin-tablet {
      max-width: 672px;
    }
  }

  &-pagination {
    margin: 36px 0 34px;
  }

  &-create {
    min-width: 402px;

    &-title {
      font-family: $font-primary;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 27px */
      text-transform: uppercase;
      color: $headline-white;

      &--small {
        font-size: 14px;
      }
    }

    &-date-wrapper {
      display: flex;
      width: 100%;
      margin-top: 24px;
      gap: 24px;
    }

    &-content {
      max-width: 395px;
    }

    &-prizes {
      gap: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      // grid-template-columns: repeat(5, 1fr);

      &-input {
        width: 120px !important;
        min-width: unset !important;
      }
    }

    &-add-btn {
      display: flex;
      width: fit-content;
      height: 37px;
      padding: 8px 8px;
      justify-content: center;
      align-items: center;

      border-radius: 8px;
      border: 1px solid $block-light;
      background: $block-light;
      cursor: pointer;

      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 177%;
      text-transform: uppercase;
      transition: background-color 0.2s;

      &:hover {
        background: $gray-light;
      }

      &:disabled {
          background-color: $block-light;
          cursor: not-allowed;
      }
    }
  }
}

.admin-current-leaderboard {
  width: 100%;
  // max-width: 1195px;
  margin: 0 auto;

  &-back {
    position: absolute;
    top: 31px;
    left: 0;
    z-index: 1;

    &-wrapper {
      width: 100%;
      max-width: 1195px;
      margin: 0 auto;
    }
  }

  &-top {
    position: relative;
    padding: 40px 0 58px;
    gap: 25px;

    &-current {
      border-bottom: 1px solid rgba($stroke-light, 0.20);
    }
  }

  &-countdown {
    background: $deep-night;
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    bottom: -27px;
    height: 56px;
    padding: 24px;
    justify-content: center;
    align-items: center;
    margin-top: 21px;
    border-radius: 8px;
    border: 1px solid rgba($stroke-light, 0.20);

    &-title {
      position: absolute;
      top: -6px;
      text-align: center;
      font-family: $font-primary;
      color: rgba($secondary, 0.75);
      font-size: 12px;
      font-weight: 600;
      line-height: 100%;
      text-transform: uppercase;
      margin: 0;
    }
  }

  &-title {
    color: $headline-white;
    font-family: $font-secondary;
    font-size: 55px;
    font-weight: 800;
    margin: 0;
    line-height: 90%;
  }

  &-date {
    padding: 8px;
    border-radius: 49px;
    border: 1px solid rgba($stroke-light, 0.20);
    color: $headline-white;
    font-family: $font-primary;
    font-size: 12px;
    font-weight: 600;
    line-height: 60%;
    text-transform: uppercase;

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 13px;
    }

    &-current {
      color: $primary;
      border-color: $primary;

      &-ended {
        color: $blue;
        border-color: $blue;
      }
    }
  }

  &-table {
    margin-bottom: 46px;
    border-radius: 10px;

    &-tab {
      display: flex;
      width: 128px;
      height: 45px;
      padding: 5px 0;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      text-transform: uppercase;
      border-bottom: 1px solid transparent;
      transition: all linear 0.2s;

      &:hover {
        border-bottom-color: rgba($stroke-light, 0.2);
      }

      &-active {
        border-bottom-color: $primary !important;
      }
    }

    &-header {
      display: flex;
      width: 210px;
      color: $headline-white;
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 600;
      line-height: 100%;
      justify-content: flex-start;
      text-transform: uppercase;
      padding-left: 10px;

      &:first-child {
        width: 240px;
        justify-content: center;
        margin-right: auto;
      }

      &:last-child {
        width: 150px;
      }

      &-ended {
        width: 170px;
        justify-content: center;
        padding: 0;

        &:last-child {
          width: 170px;
        }
      }
    }

    &-row {
      position: relative;
      height: 55px;
      border-radius: 10px;
      margin-bottom: 10px;
      border: 1px solid rgba($stroke-light, 0.20);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: $admin-table;
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 100%;
      text-transform: uppercase;
      z-index: 0;

      &:after {
        content: " ";
        position: absolute;
        inset: 0;
        border-radius: 10px;
        background: $admin-table;
        z-index: -1;
      }

      &-first {
        border-color: transparent;

        &:before {
          content: " ";
          position: absolute;
          inset: -1px;
          background: $gradient-gold;
          border-radius: 10px;
          z-index: -2;
        }
      }

      &-second {
        border-color: transparent;

        &:before {
          content: " ";
          position: absolute;
          inset: -1px;
          background: $gradient-silver;
          border-radius: 10px;
          z-index: -2;
        }
      }

      &-third {
        border-color: transparent;

        &:before {
          content: " ";
          position: absolute;
          inset: -1px;
          background: $gradient-bronze;
          border-radius: 10px;
          z-index: -2;
        }
      }

      &-banned {
        border-color: $ban-red !important;

        &:before {
          content: '' !important;
          display: none;
        }
      }
    }

    &-cell {
      width: 210px;

      &-ended {
        display: flex;
        width: 170px;
        justify-content: center;
      }

      &-place {
        position: absolute;
        top: -9px;
        left: -15px;
        display: flex;
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
        padding: 11px 12px 11px 11px;
        border-radius: 50%;
        border: 1px solid rgba($stroke-light, 0.20);
        background: $block-light;

        &-first {
          background: $gradient-gold;
          color: $light;
        }

        &-second {
          background: $gradient-silver;
          color: $light;
        }

        &-third {
          background: $gradient-bronze;
          color: $light;
        }

        &-banned {
          color: rgba($secondary, 0.75) !important;
          background: $ban-red !important;
        }
      }

      &--user {
        width: 250px;
        margin-right: auto;
        justify-content: center;
      }

      &--wagered {
        img {
          padding-right: 8px;
        }
      }

      &--prize {
        color: $prize-green !important;

        &-blue {
          color: $blue;
        }
      }

      &--ban {
        width: 150px;
        justify-content: flex-start;
      }
    }

    &-pagination {
      margin: 36px 0;
    }
  }

  &-ban-btn {
    width: 85px;
    height: 32px;
    padding: 9px 8px;
    border-radius: 4px;
    border: 1px solid $block-light;
    background: $block-light;
    color: $headline-white;
    font-family: $font-primary;
    font-size: 12px;
    font-weight: 600;
    line-height: 100%;
    text-transform: uppercase;

    &:hover {
      background-color: $gray-light;
    }
  }
}


