@import "styles/variables";
@import "styles/mixins";

.admin-points-store {
  padding: 0 50px 88px;

  @include laptop {
    padding: 0 0 88px;
  }

  &-btn.shared-btn-primary {
    height: 40px;
    display: flex;
    width: 156px !important;
    padding: 0 24px !important;
  }

  &-restock {
    height: 40px;
    display: flex;
    width: 141px;
    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: $block-light;
    border: none;
    gap: 8px;

    &-img {
      width: 24px;
      height: 24px;
    }
  }

  &-select {
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }

  &-list-padding {
    padding: 24px 0 36px !important;

    @include laptop {
      padding: 24px 50px 36px !important;
    }
  }
}

