@import "styles/variables";
@import "styles/mixins";

.admin-slots-reviews {
  width: 100%;
  max-width: 1020px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 63px 0 10px;

  @include laptop {
    max-width: 812px;
  }

  &-title {
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
  }

  &-search {
    margin-top: 18px;
  }

  &-card {
    width: 191px !important;
    height: fit-content !important;
  }

  &-list {
    width: 100%;
    min-height: 266px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 27px;
    gap: 16px;

    &-providers {
      min-height: unset;

      @include laptop-max {
        max-width: 676px;
      }

      @include tablet-max {
        max-width: 500px;
      }

      @include mobile {
        max-width: 328px;
      }
    }
  }

  &-btn {
    &-add.shared-btn-primary {
      display: flex;
      width: 221px;
      height: 48px;
      padding: 0 16px;
      border-radius: 4px;
      margin-left: 38px;

      img {
        width: 24px;
        height: 24px;
        padding: 6px;
        margin-right: 8px;
        border-radius: 32px;
        border: 1px solid rgba($faded, 0.4);
      }
    }
  }

  &-add-slot {
    width: 100%;
    max-width: 1308px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 68px 10px 10px 60px;

    @include admin-laptop-max {
      margin: 68px 60px 10px 60px;
    }

    &-tab.shared-tab {
      width: 191px;
    }

    &-form {
      &-space {
        margin-top: 18px;
      }

      &-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 96px;
        gap: 18px;

        &-save.shared-btn-primary {
          width: 174px;
          height: 41px;
        }

        &-exit.shared-btn-secondary {
          width: 174px;
          height: 41px;
          border-radius: 4px;
        }
      }

      &-content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 40px;
        gap: 50px;

        &-block {
          width: 402px;
        }
      }
    }
  }

  &-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0 130px 0;
    gap: 24px;
  }
}

.admin-comment-review {
  &-wrapper {
    margin: 0 auto;
    max-width: 1280px;
  }

  &-table {
    &-title {
      max-width: 220px;
    }

    &-user {
      max-width: 160px;
    }

    &-date {
      max-width: 170px;
      min-width: 80px;
    }

    &-comment {
      max-width: 410px;
      min-width: 410px;

      @media screen and (max-width: 1280px + 50px) {
        max-width: 250px;
        min-width: 250px;
      }

      &-text {
        margin: 0;
        max-width: 390px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &-modal {
    &-comment {
      padding: 14px 11px;
      background: radial-gradient(152.76% 130.7% at 50% 0%, rgba(101, 101, 101, 0.05) 0%, rgba(101, 101, 101, 0.00) 100%), #151C2C;
      color: rgba($daylight, 0.75);
      font-family: $font-primary;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }
}
