@import 'variables';
@import 'mixins';

body {
  background: $light;
}

.shared {
  &-bg-decoradion {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  &-container {
    max-width: 1280px;
  }

  &-table-container {
    max-width: 1195px;
  }

  &-stub {
    display: flex;
    width: 100%;
    height: 379px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 17px;
    background: $light;

    &-text {
      color: rgba($secondary, 0.4);
      text-align: center;
      font-family: $font-secondary;
      font-size: 55px;
      font-style: normal;
      font-weight: 800;
      margin: 0;

      @include tablet {
        font-size: 30px;
      }

      @include mobile {
        font-size: 22px;
      }
    }
  }

  &-tab {
    display: flex;
    height: 35px;
    padding: 8px 16px;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    transition: all linear 0.2s;

    &:hover {
      border-bottom-color: rgba($stroke-light, 0.2);
    }

    &-active {
      border-bottom-color: $primary !important;
    }
  }

  &-text {
    color: $primary;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;

    &--small {
      font-size: 14px;
    }

    &--extra-small {
      font-size: 12px;
    }

    &.white {
      color: rgba($secondary, 0.75);
    }

    &.faded-text {
      color: rgba($secondary, 0.4);
    }
  }

  &-headline {
    color: $headline-white;
    font-family: $font-secondary;
    font-size: 55px;
    font-weight: 800;
    line-height: 120%;

    &--small {
      font-size: 30px;
    }

    &--large {
      font-size: 75px;
    }

    &--extra-large {
      font-size: 80px;
    }

    &.primary {
      color: $primary;
    }
  }

  &-block {
    border-radius: 8px;
    border: 1px solid rgba($stroke-light, 0.2);
    padding: 0 24px 24px;
    background: $light;
  }

  &-btn {
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    transition: all linear 0.2s;

    &-primary {
      display: inline-flex;
      height: 51px;
      padding: 10px 32px;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background: $primary;
      color: $headline-color;
      font-family: $font-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      border: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        background: #743ceb;
      }

      /*&:focus,*/
      &:active {
        border: 1px solid $primary;
        color: $primary;
        background: transparent;
      }

      &:disabled {
        cursor: not-allowed;
        background: rgba($white, 0.3);
        box-shadow: 0 1px 1px 0 rgba($white, 0.25) inset;
      }
    }

    &-secondary {
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      text-transform: uppercase;
      display: flex;
      padding: 18px 24px;
      justify-content: center;
      align-items: center;
      border-radius: 26px;
      background: transparent;
      border: 1px solid rgba($stroke-light, 0.2);
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      cursor: pointer;

      &--large {
        height: 48px;
      }

      &:hover {
        background: rgba(226, 241, 255, 0.1);
      }

      /*&:focus,*/
      &:active {
        color: #cce0eb;
        background: rgba(16, 17, 18, 0.1);
      }

      &:disabled {
        cursor: not-allowed;
        background: rgba($white, 0.3);
        box-shadow: 0 1px 1px 0 rgba($white, 0.25) inset;
      }
    }
  }

  &-modal {
    &-wrapper.modal-dialog {
      width: 100%;
      max-width: 586px;

      @include mobile {
        max-width: calc(100% - 16px);
      }

      &.small {
        width: 466px;

        .shared-modal-content {
          width: 402px;
        }
      }

      &.large {
        width: 608px;

        .shared-modal-content {
          width: 544px;
        }
      }

      &.full-size {
        width: 100vw;
        height: 100vh;
      }
    }

    &-content {
      width: 502px;
      margin: 0 auto;
    }

    &.modal-content {
      border-radius: 10px;
      border: 1px solid rgba($light, 0.5);
      background: $light;
    }

    &-body.modal-body {
      padding: 40px 0 16px;
    }

    &-title {
      color: $daylight;
      text-align: center;
      font-family: $font-primary;
      font-size: 18px;
      font-weight: 700;
      line-height: 150%; /* 27px */
      text-transform: uppercase;
    }

    &-check {
      color: rgba($daylight, 0.5);
      font-family: $font-primary;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      margin-left: 15px;
      line-height: 150%;

      span {
        color: rgba($daylight, 0.75);
      }

      &-input {
        width: 16px;
        height: 16px;
        border-radius: 2px;
      }
    }

    &-text {
      margin-top: 40px;
      color: rgba($secondary, 0.75);
    }

    &-footer.modal-footer {
      width: 395px;
      margin: 0 auto;
      padding: 28px 0 40px;
    }

    &-btn {
      width: 172px;
      height: 40px;
      padding: 0;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
    }

    &-close {
      top: 24px;
      padding: 0;
      right: 29px;
      height: 14px;
      position: absolute;
      line-height: 1;
      z-index: 2;

      img {
        width: 14px;
        height: 14px;
      }

      &.rounded {
        width: 16px;
        height: 16px;
        border-radius: 100% !important;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba($secondary, 0.25);

        right: 8px;
        top: 8px;

        img {
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  &-input {
    width: 100%;
    height: 100%;
    z-index: 1;
    min-height: 40px;
    border: 1px solid $block-hover;
    color: $headline-white;
    font-family: $font-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    padding-left: 16px;

    border-radius: 4px;
    background: $block-hover;

    &-error {
      border-color: $red;
    }

    &::placeholder {
      color: rgba($faded, 0.4);
    }

    &.form-control {
      background: inherit;
      border-radius: inherit;
      border: inherit;
      color: inherit;
    }

    &-after {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }

    &-wrapper {
      width: 100%;
      min-width: 402px;
      height: 40px;
      position: relative;
    }

    &:focus-visible {
      outline: none;
      border-color: $primary;

      .shared-input-label:before {
        background: $light;
      }
    }

    &-label {
      position: absolute;
      left: 8px;
      top: -9px;
      padding: 0 8px;
      z-index: 2;
      color: rgba($faded, 0.4);
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 500;
      line-height: 150%; /* 18px */
      text-transform: uppercase;

      &-error {
        &:before {
          content: ' - ';
        }
        color: $red;
      }

      &:before {
        position: absolute;
        left: 0;
        top: 9px;
        z-index: -1;
        width: 100%;
        height: 1px;
        background: $block-hover;
        content: ' ';
      }
    }
  }

  &-spinner {
    &-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
}
