@import "styles/variables";
@import "styles/mixins";

.text-editor {
  background-color: $block-hover;
  min-height: 360px;
  padding: 0 16px;

  &-wrapper {
    width: 100%;

    .rdw-option-wrapper {
      background: transparent;
      border: 0;
      box-shadow: none !important;
    }

    .rdw-dropdown-wrapper:hover {
      background-color: transparent;
    }

    .rdw-dropdownoption-default {
      min-height: 29px;
      padding: 0 9px;

      color: rgba($secondary,0.75);
      font-family: $font-primary;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }

    .rdw-dropdown-optionwrapper {
      background: $block-light;
      color: rgba($secondary, 0.75);
      border: none;
    }

    .rdw-dropdownoption-active {
      background: $block-hover;
    }

    .rdw-dropdownoption-highlighted {
      background: $block-hover;
    }

    .rdw-dropdown-optionwrapper:hover {
      box-shadow: none;
      background-color: $block-light;
    }

    .rdw-dropdown-wrapper {
      background: transparent;
      border: 0;
      box-shadow: none !important;
    }

    .rdw-dropdown-selectedtext {
      color: $primary;
      text-decoration: underline;
    }

    .rdw-dropdown-carettoopen {

      &:before {
          position: absolute;
          content: "";
        width: 10px;
        height: 10px;
        background-image: url("../../../public/assets/icons/primary-dropdown-arrow.svg");
        top: -7px;
        left: -5px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .rdw-dropdown-carettoclose {
      &:before {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        background-image: url("../../../public/assets/icons/primary-dropdown-arrow.svg");
        top: -3px;
        left: -5px;
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(180deg);
      }
    }

    .rdw-dropdown-optionwrapper {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .rdw-link-modal {
      width: 248px;
      height: fit-content;
      border: none;
      padding: 20px 16px;
      border-radius: 0;
      background: $block-light;
      box-shadow: none;
    }

    .rdw-link-modal-label {
      color: rgba($secondary, 0.50);
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 500;
      line-height: 30px;
      text-transform: uppercase;

      &:first-child {
        position: absolute;
        top: 11px;
        left: 21px;
      }

      &:nth-child(3) {
        position: absolute;
        top: 71px;
        left: 21px;
      }
    }

    .rdw-link-modal-input {
      border-radius: 4px;
      background: $bg-dark;
      padding: 0 16px;
      border: none;
      height: 40px;
      width: 100%;

      color: var(--moonlight-50, rgba(226, 241, 255, 0.75));
      font-family: $font-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 21px */
    }

    #openLinkInNewWindow {
      position: relative;

      &:before {
        position: absolute;
        z-index: 1;
        content: "";
        width: 16px;
        height: 16px;
        inset: -1px;
        top: -1px;
        border-radius: 2px;
        background: $block-hover;
        border: 1px solid $light;
      }

      &:after {
        z-index: 2;
        left: 50%;
        top: 50%;
        position: absolute;
        content: "";
        background-image: url("../../../public/assets/icons/check.svg");
        background-size: contain;
        background-repeat: no-repeat;
        transform: translate(-50%, -50%) scale(0);
        transition: 0.05s ease-in;
        width: 13px;
        height: 13px;
      }

      &:checked{
        &:after {
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }

    .rdw-link-decorator-icon{
      display: none;
    }

    .public-DraftStyleDefault-ul{
      li{
        color: rgba($secondary, 0.75);
      }
    }

    .public-DraftStyleDefault-ol {
      li{
        color: rgba($secondary, 0.75);
      }
    }

    .rdw-link-modal-target-option {
      display: flex;
      align-items: center;

      span {
        margin-left: 10px;
        color: rgba($secondary, 0.50);
        font-family: $font-primary;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }
    }

    .rdw-link-modal-buttonsection {
      margin: 0;
      display: flex;
      justify-content: space-between;
    }

    .rdw-link-modal-btn {
      &:first-child {
        display: inline-flex;
        height: 32px;
        width: 97px;
        padding: 18px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background: $primary;
        color: $headline-color;
        font-family: $font-primary;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        border: none;
        margin-left: 0;
        box-shadow: none;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          background: #743CEB;
        }

        &:active {
          border: 1px solid $primary;
          color: $primary;
          background: transparent;
        }

        &:disabled {
          cursor: not-allowed;
          background: rgba($white, 0.30);
          box-shadow: 0 1px 1px 0 rgba($white, 0.25) inset;
        }
      }

      &:last-child {
        color: rgba($secondary, .75);
        font-family: $font-primary;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        text-transform: uppercase;
        display: flex;
        width: 97px;
        height: 32px;
        padding: 18px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: transparent;
        border: 1px solid rgba($stroke-light, 0.20);
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        cursor: pointer;

        &--large {
          height: 48px;
        }

        &:hover {
          background: rgba(226, 241, 255, 0.10);
          box-shadow: none;
        }

        &:active {
          color: #CCE0EB;
          background: rgba(16, 17, 18, 0.10);
        }

        &:disabled {
          cursor: not-allowed;
          background: rgba($white, 0.30);
          box-shadow: 0 1px 1px 0 rgba($white, 0.25) inset;
        }
      }
    }
  }

  .public-DraftEditor-content {
    font-weight: 300;
    font-size: 20px;
  }

  &-toolbar {
    background: $block-light !important;
    margin-bottom: 0 !important;
    border: 0 !important;
  }

  &-content {
    h1, h2, h3, h4, h5, h6, p, span, b, em, i, ul, ol, strong {
      color: rgba($secondary, 0.75);
      font-family: $font-roboto;
      line-height: 30px;
    }

    h1 {
      font-size: 36px;
      font-weight: 700;
    }

    h2 {
      font-size: 30px;
      font-weight: 700;
    }

    h3 {
      font-size: 24px;
      font-weight: 500;
    }

    h4 {
      font-size: 24px;
      font-weight: 400;
    }

    h5 {
      font-size: 20px;
      font-weight: 400;
    }

    h6 {
      font-size: 18px;
      font-weight: 400;
    }

    span, p {
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
    }

    ol {
      list-style: decimal;
    }

    ul {
        list-style: unset;
    }
  }
}
