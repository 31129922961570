@import "styles/variables";

.per-page-select-wrapper {
  .per-page-select {
    &__indicators {
      svg {
        width: 16px;
        height: 16px;
      }
    }

    &__dropdown-indicator {
      width: 16px;
      height: 16px;
      padding: 0;
      display: flex;
      align-items: center;
      color: $primary;
      transition: all linear 0.2s;

      &:hover {
        color: $primary;
      }
    }

    &__control {
      display: flex;
      cursor: pointer;
      padding: 0 5px;
      gap: 4px;
      height: 32px;
      min-height: 32px;
      align-items: center;
      justify-content: space-between;
      border: 1px solid rgba($secondary, 0.05);
      border-radius: 4px;
      background: $deep-night;

      &:hover {
        border-color: rgba($secondary, 0.05);
      }

      &--menu-is-open {
        .per-page-select__dropdown-indicator {
          transform: rotate(-180deg);
        }
      }
    }

    &__value-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
    }

    &__single-value {
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;
    }

    &__menu {
      width: 62px;
      right: 0;
      background-color: $deep-night;
      border: 1px solid rgba($stroke-light, 0.2);
      z-index: 2;
    }

    &__input-container {
      display: none;
    }

    &__option {
      display: flex;
      justify-content: flex-start;
      color: $secondary;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
      text-transform: uppercase;
      cursor: pointer;

      &:active {
        background-color: $block-light;
      }

      &--is-selected {
        background-color: $block-light;
        color: $headline-white;
      }
    }
  }
}
