@import "styles/variables";
@import "styles/mixins";

.login-modal {
  &-text {
    margin-top: 32px;
  }

  &-check {
    max-width: 450px;

    @include mobile {
      max-width: 100%;
    }

    &-wrapper {
      margin-top: 35px;
    }
  }

  &-mobile {
    @include mobile {
      border-radius: 0 !important;
      width: 100% !important;
      height: 100% !important;
    }

    &-dialog {
      @include mobile {
        max-width: 100% !important;
        margin: 0 !important;
        justify-content: center;
      }
    }

    &-content {
      @include mobile {
        width: 100% !important;
        padding: 0 8px;
      }
    }
  }

  &-button {
    display: flex;
    width: 100%;
    height: 45px;
    outline: none;
    border: 0;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #F3F7FA;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    color: $deep-night;
    font-weight: 600;
    line-height: 150%;
    box-shadow: 0 1px 0 0 rgba(162, 130, 255, 0.54) inset;
    transition: all linear 0.2s;

    &:disabled {
      cursor: not-allowed;
      color: rgba($deep-night, 0.50);
    }
  }
}
