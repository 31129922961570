@import "styles/variables";

.custom-datepicker {
  width: 48%;

  &-timepicker {
    // border: 1px solid$input-border-color;
    height: 38px;
    padding: 1px 2px 1px 16px;
    color: $headline-white;
    font-family: $font-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    // color: $body-color;
    // outline-color: $green;
    border-radius: 6px;

    &::placeholder {
      color: rgba($faded, 0.4);
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
    }

    &.active {
      // border-color: $success;
    }

    &-btn {
      position: absolute;
      height: 38px;
      right: 0;
      top: 0;
      border: 0 !important;
      padding-right: 5px !important;

      svg {
        // color: $text-muted;
      }
    }
  }
}

.flatpickr-time input.flatpickr-hour {
  font-weight: normal;
}

.flatpickr-calendar {
  background: $light;
  font-family: $font-primary;

  span.flatpickr-weekday {
    background: $light;
  }

  .flatpickr-current-month .flatpickr-monthDropdown-months {
    background: $light;
  }

  .flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
    fill: $primary;
  }

  .flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
    border-color: $primary;
    background: $primary;
  }

  &.arrowTop:after {
    background: transparent;
    border-bottom-color: $light;
  }
}

.flatpickr-months .flatpickr-month {
  background: $light;
}
