@import "styles/variables";

.pagination {
  &-container {
    display: flex;
    list-style-type: none;
    padding: 0;
  }

  &-bg {
    position: absolute;
    width: 100%;
    height: 28px;
    background: transparent;
    border-radius: 16px;
  }

  &-content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    margin: 0 4px;

    .pagination-item {
      &:nth-child(-n + 2) {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }

      &:nth-last-child(1) {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }
  }

  &-text {
    color: rgba($secondary, 0.75);
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }

  &-item {
    height: 32px;
    text-align: center;
    // color: $body-color;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    padding: 0 8px;
    z-index: 1;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
      // color: $body-color;
    }

    &:hover {
      color: $primary;
      cursor: pointer;
    }

    &.selected {
      color: $primary;
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }

  &-arrow {
    color: rgba($stroke-light, 0.2);
    border: 1px solid rgba($stroke-light, 0.2);
    background: transparent;
    border-radius: 10px;
    width: 32px;
    height: 32px;

    &:hover {
      border: 1px solid $primary;
      background: rgba($primary, 0.15);
      color: rgba($secondary, 0.75);
    }

    &-icon {
      color: inherit;
    }
  }
}

