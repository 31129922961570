@import "styles/variables";
@import "styles/mixins";

.connect-kick-modal {
  width: 735px;
  height: 495px;
  margin: auto;

  @include mobile {
    width: 100%;
    margin: 30px auto;
    min-height: unset !important;
  }

  &-body {
    @include mobile {
      height: 100%;
      border-radius: 0 !important;
    }
  }

  &-icon {
    width: 24px;
    height: 24px;
    color: $kick-secondary;
  }

  &-close.shared-modal-close {
    top: 16px;
    right: 16px;

    img {
      width: 12px;
      height: 12px;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    margin: 41px 0 0 32px;
    gap: 14px;

    @include mobile {
      margin: 41px 0 0 20px;
    }

    p {
      color: var(--accent-purple, #8850FF);
      font-family: $font-primary;
      font-size: 18px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.54px;
      text-transform: uppercase;
      margin: 0;
    }
  }

  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 14px 32px 50px;
    gap: 45px;

    @include mobile {
      padding: 14px 20px 50px;
    }
  }

  &-verification {
    display: flex;
    flex-direction: row;
    width: 316px;
    padding: 10px 20px;
    align-items: center;
    justify-content: center;
    margin-top: 13px;
    gap: 16px;

    border-radius: 10px;
    border: 1px solid rgba($block, 0.50);
    background: rgba($block, 0.50);

    color: $expansive-green;
    font-family: $font-primary;
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.4px;
  }

  &-text {
    list-style: decimal !important;
    color: $daylight;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.32px;
    margin: 0;

    &::marker {
      padding: 20px !important;
    }
  }

  &-instructions {

    &-counter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
    }

    &-link {
      color: $expansive-green;
      text-decoration: none;
    }
  }

  &-cancel.shared-btn-secondary {
    width: 174px;
    height: 41px;
    border-radius: 0;
  }
}
