@import "styles/variables";

.image-uploader {
  &-img {
    width: 96px;
    height: 82px;
    border-radius: 8px;
    background: $bg-dark;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-content {
    width: 282px;
    margin-left: 24px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    &-input {
      width: 100%;
      height: 40px;
      padding-left: 15px;
      border-radius: 4px;
      border: 1px solid rgba($block, 0.50);
      background: $bg-dark;
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      margin-bottom: 12px;

      &::placeholder {
        color: rgba($secondary, 0.50);
      }
    }

    &-upload {
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      cursor: pointer;
      width: 238px;
      height: 29px;
      padding: 9px 16px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid rgba($secondary, 0.05);
      background: rgba($secondary, 0.05);
    }

    &-delete {
      padding: 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 29px;
      border-radius: 4px;
      border: 1px solid rgba($secondary, 0.05);
      background: rgba($secondary, 0.05);

      img {
        width: 16px;
      }
    }
  }
}
