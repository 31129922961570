@import "styles/variables";
@import "styles/mixins";

.bonus-hunt {
  background: $deep-night;

  &-decoration {
    top: -40px;
    position: absolute;
    width: 100%;
    z-index: 0;

    @include tablet {
      top: 5px;
    }

    @include mobile {
      top: 0;
    }
  }

  &-title-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 72px;
    z-index: 1;

    @include laptop {
      margin: 48px 0 62px;
    }

    @include tablet {
      margin: 48px 0 97px;
    }

    @include mobile {
      margin: 48px 0 62px;
    }

    h1 {
      margin: 0;
      line-height: 0.8;
    }

    &-img {
      position: relative;
      width: 65px;
      height: 65px;

      img {
        position: absolute;
        bottom: 8px;
      }
    }
  }

  &-description {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    gap: 8px;

    &-id {
      padding: 5px 8px;
      background: transparent;
      border-radius: 49px;
      border: 1px solid rgba($secondary, 0.40);
      line-height: 1 !important;
    }

    &-status {
      padding: 3px 8px;
      border-radius: 49px;
      background: rgba($stroke-light, 0.20);
    }
  }

  &-statistic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid rgba($daylight, 0.05);
    overflow: hidden;

    @include tablet-max {
      flex-wrap: wrap;
      justify-content: center;
      border: none;
    }

    &-card {
      display: flex;
      max-width: 100%;
      width: 258px;
      height: 80px;
      padding: 16px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      background: rgba($secondary, 0.05);

      @include laptop-max {
        min-width: 190px;
      }

      @include tablet-max {
        flex: 1;
      }

      @include mobile {
        min-width: 149px;
      }

      &:not(:last-child) {
        border-right: 1px solid $block-light;

        @include mobile {
          border: none;
        }
      }
    }

    &-top {
      display: flex;
      justify-content: space-between;
      gap: 16px;

      @include tablet-max {
        flex-wrap: wrap;
        justify-content: center;
      }

      &-card {
        display: flex;
        width: 200px;
        max-height: 100%;
        height: 80px;
        padding: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid rgba($secondary, 0.05);
        background: rgba($secondary, 0.05);
        gap: 8px;

        @include laptop-max {
          padding: 24px 8px;
          min-width: 142px;
        }

        @include tablet-max {
          width: 142px;
        }
      }
    }

    &-value {
      color: $secondary;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      text-transform: uppercase;
      margin: 0;
    }

    &-text {
      p {
        margin: 0;
      }
    }

    &-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 16px;
      z-index: 1;

      @include laptop {
        padding: 0 38px;
      }

      @include tablet {
        padding: 0 73px;
      }

      @include mobile {
        padding: 0 36px;
      }
    }
  }

  &-table {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    gap: 16px;

    @include laptop {
      padding: 0 38px;
    }

    @include tablet {
      padding: 0 73px;
    }

    &-search {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 16px;
      margin-bottom: 8px;

      @include mobile {
        padding: 0 36px;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 5px;

      @include mobile {
        overflow-x: scroll;
        padding: 0 36px;
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 16px;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      min-width: 622px;

      color: rgba($secondary, 0.75);
      text-align: center;
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
      text-transform: uppercase;
    }

    &-row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      min-width: 622px;
      height: 48px;
      border-radius: 4px;
      border: 1px solid rgba($secondary, 0.05);
      background: $admin-table;

      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 100%;
      text-transform: uppercase;
    }

    &-cell {
      width: 300px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include laptop {
        width: 160px;
      }

      &:not(:first-child):not(:last-child) {
        border-right: 1px solid rgba($secondary, 0.40);
      }

      &-place {
        position: absolute;
        top: 7px;
        left: -15px;
        display: flex;
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
        padding: 11px 12px 11px 11px;
        border-radius: 50%;
        border: 1px solid rgba($stroke-light, 0.20);
        background: $block-hover;
      }

      &--name {
        width: 200px;
        margin-right: auto;

        &-text {
          width: 100%;
          padding-left: 18px;
          text-align: center;
        }
      }

      &--balance {
        @include laptop {
          width: 200px;
        }

        @include tablet-max {
          width: 150px;
        }
      }

      &--provider {
        width: 250px;
        @include laptop {
          width: 200px;
        }

        @include tablet-max {
          width: 150px;
          padding-right: 10px;
          padding-left: 10px;
        }
      }

      &--multiplier {
        @include tablet-max {
          width: 100px;
        }

        &-current {
          width: 200px;
        }
      }

      &--payout {
        width: 200px;

        @include laptop {
          width: 170px;
        }

        @include tablet-max {
          width: 120px;
        }
      }
    }
  }

  &-stream {
    z-index: 1;
    position: relative;
    width: 892px;
    margin: 0 auto;

    @include tablet {
      width: 100%;
      padding: 0 35px;
    }

    @include mobile {
      width: 100%;
      padding: 0 16px;
    }

    &-wrapper {
      margin-top: 60px;
    }

    &-bg {
      width: 100%;
      position: absolute;
      left: 0;
      top: -120px;
      z-index: 0;
    }

    &-cta {
      width: 110px;
      height: 110px;
    }

    &-title {
      margin-top: 30px;
      color: $primary;
      font-family: $font-secondary;
      font-size: 56px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      text-transform: uppercase;

      span {
        color: $headline-color;
      }
    }

    &-controll {
      margin-top: 38px;
      gap: 32px;
      width: 100%;

      &-btn.shared-btn {
        background: rgba($stroke-light, 0.20);
        border: 1px solid $block-light;
        width: calc(50% - 16px);
        height: 48px;

        svg {
          height: 14px;
          margin-right: 8px;
        }

        &.active {
          background: $primary;
        }
      }
    }

    &-content {
      height: fit-content;
      width: 100%;
      margin-top: 16px;
      border-radius: 10px;
      background: $bg-dark-rich;

      @include mobile {
        height: calc((100vw - 32px) / 16 * 9);
      }

      &-placeholder {
        width: 100%;
        height: 500px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        @include mobile {
          height: calc((100vw - 32px) / 16 * 9);
        }

        h4 {
          color: $headline-color;
          font-family: $font-secondary;
          font-size: 56px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          text-transform: uppercase;

          @include tablet {
            font-size: 45px;
          }

          @include mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 30px;
          }
        }
      }
    }
  }

  &-footer {
    margin-top: 200px;
    @include laptop {
      margin-top: 120px;
    }
    @include tablet {
      margin-top: 102px;
    }
    @include mobile {
      margin-top: 90px;
    }
  }
}
