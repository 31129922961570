@import "styles/variables";
@import "styles/mixins";

.random-winner {

  &-card {
    width: 187px;
    height: 273px;
    border-radius: 10px;
    border: 1px solid rgba($stroke-light, 0.20);
    background: var(--block, #131A2D);
    box-shadow: 0 10px 60px 0 $deep-night;

    @include tablet-max {
      width: 160px;
      height: 273px;
    }

    &-place {
      position: absolute;
      top: 16px;
      left: 16px;
      align-items: start;
      border-radius: 49px;
      padding: 8px;
      border: 1px solid rgba($secondary, 0.75);
      color: rgba($secondary, 0.75);
      font-family: $font-tertiary;
      font-size: 12px;
      font-weight: 500;
      line-height: 50%;
      text-transform: uppercase;
    }

    &-logo {
      width: 101px;
      height: 101px;
      border-radius: 50%;
    }

    &-user {
      color: $headline-white;
      font-family: $font-primary;
      font-size: 20px;
      font-weight: 700;
      line-height: 100%;
      margin: 8px 0 0;
    }

    &-btn.shared-btn-primary {
      width: 139px;
      height: 53px;
      font-size: 28px;
      font-weight: 800;
      cursor: default;
      user-select: none;

      &:active {
        background: $primary;
        color: $headline-color;
        border: none;
      }

      @include tablet-max {
        width: 112px;
      }
    }

    &-icon {
      margin-top: 11px;
      width: 31px;
      height: 16px;
    }

    &-footer {
      width: 100%;
      padding: 4px 24px 24px;
    }
  }
}
