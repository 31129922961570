@import 'styles/variables';
@import 'styles/mixins';

.profile-modal {
  &.modal-content {
    border-radius: 0;
    padding: 29px 30px;
    background: $light;
    margin-left: auto;
    margin-right: auto;

    @include mobile {
      overflow: auto;
      width: 100%;
      height: 100%;
      padding: 8px 8px 28px 8px;
    }
  }

  &-container.modal-dialog {
    width: 640px;
    max-width: 100%;

    @include mobile {
      margin: 0;
      width: 100%;
      height: 100%;
      min-height: fit-content;
    }
  }

  &-close {
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid rgba($stroke-light, 0.2);
    background: transparent;

    img {
      width: 8px;
      height: 8px;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    border: none;
    padding: 0;

    h5 {
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      text-transform: uppercase;
    }

    button {
      padding: 0;
    }
  }

  &-tab {
    display: flex;
    height: 35px;
    padding: 8px 16px;
    align-items: center;
    background: transparent;
    border: none;
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    transition: all linear 0.2s;

    @include mobile {
      font-size: 12px;
    }

    &:hover {
      border-bottom-color: rgba($stroke-light, 0.2);
    }

    &-active {
      border-bottom-color: $primary !important;
    }

    &-wrapper {
      display: flex;

      @include mobile {
        width: 100%;
        justify-content: center;
      }
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 37px;
    gap: 37px;
  }

  &-user {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;

    @include mobile {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-avatar {
      border-radius: 80px;
      width: 96px;
      height: 96px;
      min-width: 96px;
      min-height: 96px;
      border: 2px solid var($deep-night);
    }

    &-name {
      max-width: 196px;
      color: rgba($secondary, 0.75);
      font-family: $font-tertiary;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      text-transform: uppercase;
      margin: 0 16px 0 0;

      &-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include mobile {
          align-items: center;
          flex-direction: column;
        }
      }

      &-edit-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: none;
        margin-right: auto;
        padding: 0;

        @include mobile {
          margin: 0;
        }
      }
    }

    &-date {
      display: flex;
      height: 32px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 49px;
      border: 1px solid rgba($stroke-light, 0.2);
      color: rgba($secondary, 0.4);
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 500;
      line-height: 130%;
      text-transform: capitalize;
      margin-left: 16px;

      @include mobile {
        margin-top: 16px;
      }
    }

    &-status {
      height: 28px;
      display: flex;
      padding: 9px 24px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid rgba($stroke-light, 0.2);
      background: $block-light;
      margin-left: 24px;

      &-pending {
        color: $blue !important;
      }

      &-rejected {
        color: $ban-red !important;
      }

      &-approved {
        color: $prize-green !important;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      color: rgba($secondary, 0.4);
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
      margin-top: 8px;
      gap: 4px;

      @include mobile {
        align-items: center;
      }

      p {
        margin: 0;
      }

      &-container {
        width: 100%;

        @include mobile {
          width: fit-content;
        }
      }
    }

    &-balance {
      // width: 216px;
      display: flex;
      padding: 9px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      margin-top: 20px;
      border: 1px solid rgba($stroke-light, 0.2);
      background: $block-light;
      color: rgba($secondary, 0.4);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;

      img {
        width: 24px;
        height: 24px;
      }

      &-btn {
        display: flex;
        width: 24px;
        height: 24px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        border-radius: 32px;
        border: 1px solid rgba($stroke-light, 0.2);
        background: rgba($stroke-light, 0.2);

        &:hover {
          background: rgba($stroke-light, 0.4);
        }

        img {
          height: 12px;
          width: 12px;
        }
      }
    }
  }

  &-statistics {
    @include mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-container {
      display: flex;
      width: 100%;
      gap: 15px;
      justify-content: center;
      margin-top: 11px;

      @include mobile {
        justify-content: flex-start;
        overflow-x: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      gap: 4px;
      text-transform: uppercase;

      p {
        margin: 0;
      }
    }

    &-block {
      display: flex;
      flex: 1;
      min-height: 137px;
      gap: 4px;
      padding: 5px 0;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border-radius: 4px;
      border: 1px solid rgba($stroke-light, 0.2);
      background: $block-light;

      &-title {
        color: rgba($secondary, 0.75);
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        text-transform: uppercase;
      }

      &-balance {
        color: rgba($secondary, 0.75);
        font-family: $font-tertiary;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-transform: uppercase;
      }

      img {
        width: 63px;
        height: 63px;
      }

      p {
        margin: 0;
      }
    }
  }

  &-crypto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    &-title {
      display: flex;
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      text-transform: uppercase;
      gap: 4px;

      p {
        margin: 0;
      }
    }

    &-wallet {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      height: 40px;
      padding: 0;
      background: $light;

      &-input {
        width: 100%;
        height: 40px;
        border-radius: 0 4px 4px 0;
        padding: 0 16px;
        background: $deep-night;
        outline: none;
        color: rgba($secondary, 0.75);
        text-overflow: ellipsis;
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        border: none;

        &::placeholder {
          text-transform: uppercase;
        }

        &-container {
          border: 1px solid $block-light;
          border-radius: 4px;

          &-edit {
            border: 1px solid $primary;
            border-radius: 4px;
          }
        }
      }

      &-text {
        display: flex;
        width: 82px;
        height: 40px;
        padding: 11px 15px 11px 14px;
        justify-content: center;
        align-items: center;
        background: $block-light;
        border: none;
        border-radius: 2px 0 0 2px;
        color: rgba($secondary, 0.75);
        text-align: center;
        font-family: $font-primary;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        text-transform: uppercase;

        img {
          margin-right: 8px;
        }
      }

      &-btn {
        display: flex;
        width: 40px;
        height: 40px;
        padding: 9px 19px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid rgba($secondary, 0.05);
        background: $block-light;
        margin-left: 16px;

        &-edit {
          display: flex;
          width: 118px;
          height: 40px;
          padding: 18px 32px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background: $primary;
          box-shadow: 0 1px 1px 0 rgba($secondary, 0.25) inset;
          color: rgba($secondary, 0.75);
          font-family: $font-primary;
          font-size: 14px;
          font-weight: 600;
          line-height: 150%;
          text-transform: uppercase;
        }
      }
    }
  }

  &-raffle {
    &-header {
      width: 100%;
      max-width: 1280px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 35px;
    }

    &-select {
      display: flex;
      justify-content: space-between;
      gap: 16px;
    }
  }

  &-connections {
    &-item {
      width: 135px;
    }

    &-wrapper {
      min-height: 220px;
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      margin-top: 15px;
      gap: 29px;
    }

    &-stake {
      width: 100%;
      max-width: 446px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      background: $light;
      height: 40px;
      gap: 16px;
      padding: 0;

      @include mobile {
        max-width: 100%;
      }

      &-input {
        height: 40px;
        border-radius: 0 4px 4px 0;
        padding: 0 16px;
        background: $deep-night;
        outline: none;
        color: rgba($secondary, 0.75);
        text-overflow: ellipsis;
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        border: none;

        &::placeholder {
          text-transform: uppercase;
        }

        &-container {
          width: 100%;
          max-width: 318px;
          border: 1px solid $block-light;
          border-radius: 4px;
          overflow: hidden;

          &-edit {
            border: 1px solid $primary;
            border-radius: 4px;
          }
        }
      }

      &-text {
        display: flex;
        width: 82px;
        height: 40px;
        padding: 11px 15px 11px 14px;
        justify-content: center;
        align-items: center;
        background: $block-light;
        border: none;
        border-radius: 2px 0 0 2px;
        color: rgba($secondary, 0.75);
        text-align: center;
        font-family: $font-primary;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        text-transform: uppercase;

        @include mobile {
          width: 65px;
        }

        img {
          width: 34px;
          height: 17px;
        }
      }

      &-btn-edit {
        display: none;
        width: 118px;
        height: 40px;
        padding: 18px 32px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid $primary;
        background: $primary;
        box-shadow: 0 1px 1px 0 rgba($secondary, 0.25) inset;
        color: rgba($secondary, 0.75);
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        text-transform: uppercase;
      }
    }

    &-input {
      min-width: unset !important;

      &-pading {
        &-discord {
          padding-left: 42px;
          padding-right: 16px;
          border: 1px solid #3b8be9;
        }

        &-kick {
          padding-left: 42px;
          padding-right: 16px;
          border: 1px solid #3b8be9;
        }
      }

      &-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: transparent;

        color: #738192;

        text-align: center;
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.28px;

        &-label {
          position: absolute;
          left: 8px;
          top: -9px;
          color: rgba($daylight, 0.5);
          text-align: center;
          font-family: $font-primary;
          font-size: 12px;
          font-weight: 400;
          line-height: 130%;
          padding: 0 8px;
          z-index: 1;

          &:after {
            position: absolute;
            top: 8px;
            left: 0;
            content: ' ';
            display: flex;
            align-items: center;
            justify-content: center;
            background: $dark-night;
            width: 100%;
            height: 1px;
            z-index: -1;
          }
        }

        &-text {
          display: flex;
          color: rgba($daylight, 0.75);
          text-align: center;
          font-family: $font-primary;
          font-size: 10px;
          font-weight: 500;
          line-height: 150%;
          letter-spacing: 0.2px;
          padding: 0 0 0 10px;
          gap: 8px;

          span {
            width: 50px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        &-connect {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 134px;
          height: 47px;
          padding: 0 4px 0 8px;
          border-radius: 4px;
          border: 1px solid #3b8be9;
          background: $dark-night;

          color: rgba($daylight, 0.75);
          text-align: center;
          font-family: $font-primary;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          letter-spacing: 0.2px;
        }
      }

      &-status {
        color: rgba($secondary, 0.75);
        font-family: $font-primary;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.28px;
        margin: 0;

        &-check {
          width: 14px;
          height: 14px;
          margin-right: 6px;
          margin-top: 2px;
        }

        &-cross {
          width: 10px;
          height: 10px;
          margin-right: 6px;
          margin-top: 4px;
        }
      }

      &-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 33px;
      }
    }

    &-discord {
      padding-left: 7px;
      width: 24px;
      height: 20px;
      color: $discord;
    }

    &-kick {
      padding-left: 7px;
      width: 24px;
      height: 24px;
      color: $kick;
    }
  }

  &-ban-btn {
    transition: all linear 0.2s;
    height: 48px;
    display: flex;
    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgba($stroke-light, 0.2);
    background: $ban-red;
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
  }

  &-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 10px;

    @include mobile {
      overflow-x: auto !important;
    }

    &-stub {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 412px;
    }

    &-container {
      min-height: 450px;
    }

    &-header {
      min-height: 20px !important;
      font-size: 10px !important;
      font-weight: 600 !important;

      &-row {
        display: flex;
        width: 100%;
        border-radius: 10px;
        background: transparent;
        margin-bottom: 10px;
        border: none;

        @include mobile {
          min-width: 630px;
          justify-content: flex-end;
          align-items: flex-end;
        }
      }
    }

    &-row {
      display: flex;
      width: 100%;
      border-radius: 10px;
      background: $admin-table;
      margin-bottom: 10px;
      border: 1px solid rgba($stroke-light, 0.05);

      @include mobile {
        min-width: 630px;
        justify-content: flex-end;
      }
    }

    &-cell {
      min-height: 48px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      &-result {
        color: $discord;
      }

      &-id {
        width: 80px !important;
      }

      &-text {
        width: 105px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        justify-content: flex-start;
        text-align: center;
        margin: 0;
      }

      img {
        width: 30px;
        height: 30px;
        border-radius: 5px;
      }
    }
  }
}

.confirm-ban {
  position: relative;
  top: 30%;
  margin: auto;
  padding: 24px;
  width: 350px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid rgba($stroke-light, 0.2);
  background: $block-hover;

  &-title {
    background: $block-hover;
    border: unset;
    border-radius: 0;
    padding: 0;

    h5 {
      color: rgba($secondary, 0.75);
      font-family: $font-tertiary;
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      text-transform: uppercase;
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    background: $block-hover;
    border: none;
    padding: 20px 0 0 0;

    p {
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      text-transform: uppercase;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    background: $block-hover;
    border: none;
    border-radius: unset;
    padding: 0;
  }

  &-cancel {
    transition: all linear 0.2s;
    display: flex;
    width: 140px;
    height: 48px;
    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgba($stroke-light, 0.2);
    background: rgba($stroke-light, 0.2);
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
  }

  &-btn {
    transition: all linear 0.2s;
    display: flex;
    width: 140px;
    height: 48px;
    padding: 18px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: $ban-red;
    border: none;
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
  }
}

.points {
  position: relative;
  border-radius: 8px;
  top: 40%;
  margin: auto;
  border: 1px solid rgba($stroke-light, 0.2);
  background: $block-hover;
  box-shadow: 0 4px 40px 0 $points-dark;
  width: 340px;

  &-title {
    border: none;
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
    padding: 16px 24px 0;
  }

  &-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;

    :focus-visible {
      outline: 1px solid $primary !important;
    }

    &-label {
      position: absolute;
      background: $block-hover;
      padding: 0 8px 0 8px;
      top: -6px;
      left: 8px;
      color: rgba($secondary, 0.75);
      text-align: center;
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 500;
      line-height: 100%;
      z-index: 1;
    }

    &-input {
      width: 100%;
      height: 40px;
      padding: 0 16px 0 16px;
      background: $block-hover;
      border-radius: 8px;
      color: rgba($secondary, 0.75);
      border: 1px solid rgba($stroke-light, 0.2);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
      box-shadow: none;
      z-index: 0;
    }
  }

  &-cancel {
    display: flex;
    width: 132px;
    height: 50px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid $block-light;
    background: rgba($stroke-light, 0.2);
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
    transition: all linear 0.2s;
  }

  &-confirm-btn {
    display: flex;
    width: 132px;
    height: 50px;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: none;
    background: $primary;
    color: $block-hover;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
  }

  &-footer.modal-footer {
    border: none;
    display: flex;
    justify-content: space-between;
    margin: 0 24px 24px 24px;
    padding: 0;
  }
}

.change-username-modal {
  position: relative;
  border-radius: 8px;
  top: 40%;
  margin: auto;
  border: 1px solid rgba($stroke-light, 0.2);
  background: $block-hover;
  box-shadow: 0 4px 40px 0 $points-dark;
  width: 340px;

  &-title {
    border: none;
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
    padding: 16px 24px 0;
  }

  &-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;

    :focus-visible {
      outline: 1px solid $primary !important;
    }

    &-label {
      position: absolute;
      background: $block-hover;
      padding: 0 8px 0 8px;
      top: -6px;
      left: 8px;
      color: rgba($secondary, 0.75);
      text-align: center;
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 500;
      line-height: 100%;
      z-index: 1;
    }

    &-input {
      width: 100%;
      height: 40px;
      padding: 0 46px 0 16px;
      background: $block-hover;
      border-radius: 8px;
      color: rgba($secondary, 0.75);
      border: 1px solid rgba($stroke-light, 0.2);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
      box-shadow: none;
      z-index: 0;

      &-char-counter {
        position: absolute;
        bottom: 4px;
        right: 6px;
      }
    }
  }

  &-cancel {
    display: flex;
    width: 132px;
    height: 50px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid $block-light;
    background: rgba($stroke-light, 0.2);
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
    transition: all linear 0.2s;
  }

  &-confirm-btn {
    display: flex;
    width: 132px;
    height: 50px;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: none;
    background: $primary;
    color: $block-hover;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
  }

  &-footer.modal-footer {
    border: none;
    display: flex;
    justify-content: space-between;
    margin: 0 24px 24px 24px;
    padding: 0;
  }
}

.content.modal-content {
  background: $block-hover;
  border: none;
}

.profile-select-wrapper {
  min-width: 145px;

  .profile-select {
    &__indicators {
      width: 24px;
      height: 24px;
      border: 1px solid rgba($secondary, 0.75);
      border-radius: 50%;
    }

    &__dropdown-indicator {
      width: 22px;
      height: 22px;
      align-items: center;
      color: rgba($secondary, 0.75);
      transition: all linear 0.2s;
      padding: 2px;
    }

    &__control {
      display: flex;
      cursor: pointer;
      height: 40px;
      padding: 8px;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      border-radius: 4px;
      background: $block-light;

      &:hover {
        border-color: rgba($secondary, 0.05);
      }

      &--menu-is-open {
        .profile-select__dropdown-indicator {
          transform: rotate(-180deg);
        }
      }
    }

    &__value-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
    }

    &__single-value {
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      text-transform: uppercase;
    }

    &__menu {
      background-color: $block-light;
      z-index: 2;
    }

    &__option {
      border-radius: 4px;
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
      text-transform: uppercase;
      cursor: pointer;

      &:active {
        background-color: rgba($secondary, 0.1);
      }

      &--is-selected {
        background-color: rgba($secondary, 0.1);
        color: $headline-white;
      }
    }
  }
}
