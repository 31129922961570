@import "styles/variables";

.select-input-title {
  position: absolute;
  padding: 0 8px;
  color: rgba($secondary, 0.40);
  font-family: $font-primary;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;
  top: -8px;
  left: 8px;
  z-index: 1;

  &:before {
    position: absolute;
    left: 0;
    top: 8px;
    z-index: -1;
    width: 100%;
    height: 1px;
    background: $block-hover;
    content: " ";
  }
}

.select-wrapper {
  width: 100%;

  .select {
    &__indicators {
      svg {
        width: 16px;
        height: 16px;
      }
    }

    &__dropdown-indicator {
      width: 16px;
      height: 16px;
      padding: 0;
      display: flex;
      align-items: center;
      color: $headline-white;
      transition: all linear 0.2s;

      &:hover {
        color: $headline-white;
      }
    }

    &__control {
      display: flex;
      cursor: pointer;
      padding: 0 16px;
      gap: 4px;
      height: 40px;
      min-height: 40px;
      align-items: center;
      justify-content: space-between;
      border: 1px solid transparent;
      border-radius: 4px;
      background: $block-hover;

      &:hover {
        border-color: rgba($secondary, 0.05);
      }

      &--menu-is-open {
        border: 1px solid $primary;

        .select__dropdown-indicator {
          transform: rotate(-180deg);
        }
      }
    }

    &__value-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
    }

    &__single-value {
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;
    }

    &__menu {
      width: 167px;
      right: 0;
      border-radius: 0;
      background-color: $light;
      margin-top: 1px;
      z-index: 3;
    }

    &__menu-list {
      padding: 0;
      overflow-y: auto;
      max-height: 195px;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__input-container {
      display: none;
    }

    &__option {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 45px;
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      text-transform: uppercase;
      cursor: pointer;

      &:active {
        background-color: $block-hover;
      }

      &--is-selected {
        background-color: $block-hover;
        color: $primary;
      }
    }
  }
}