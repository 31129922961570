@import "styles/variables";
@import "styles/mixins";

.countdown {
  display: inline-flex;
  padding: 24px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgba($stroke-light, 0.20);

  @include tablet-max {
    padding: 24px 2px;
  }

  :not(:only-child) {
    padding: 0 24px;

    @include tablet-max {
      padding: 0 6px;
    }
  }

  :not(:last-child) {
    border-right: 1px solid rgba($stroke-light, 0.20);
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $primary;
    text-align: center;
    font-family: $font-secondary;
    font-size: 55px;
    font-weight: 800;
    line-height: 75%;
    gap: 16px;

    span {
      display: flex;
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 700;
      line-height: 100%;
      text-transform: uppercase;
    }
  }
}

.countdown-leaderboard-card {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  div {
    width: 45px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
}

.countdown-raffle-card {
  width: fit-content;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;

  gap: 4px;
  min-width: 132px;

  div {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    color: $headline-white;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
  }
}
