@import "styles/variables";

.live-indicator {
  width: 14px;
  min-width: 13px;
  height: 14px;
  border: 1px solid $grey;
  border-radius: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  &.active {
    border-color: $red;

    .live-indicator-content {
      background: $red;

      animation: blink 1s ease-out 1s infinite;
    }
  }

  &-wrapper {
    display: inline-flex;
    padding: 4px;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: $deep-night;
    text-decoration: none;
  }

  &-text {
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
  }

  &-content {
    border-radius: 100%;

    width: 8px;
    height: 8px;
    background: $grey;
    border: 1.5px solid $deep-night;
  }
}

@keyframes blink {
  0% {
    border-color: $deep-night;
  }
  100% {
    border-color: rgba($red, 0.58);
  }
}
