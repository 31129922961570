@import "styles/variables";
@import "styles/mixins";

.bonuses {
  padding-top: 102px;
  background: $deep-night;
  @include tablet-max {
    background: $dark;
  }

  &-btn-show.shared-btn-secondary {
    margin-top: 32px;
    margin-bottom: 32px;
    height: 40px;
    background: $primary;
    color: $headline-white;

    @include laptop {
      margin-top: 0;
    }
  }

  &-btn-less {
    margin-top: 32px !important;
  }

  &-hero {
    &-img {
      width: 873px;
      z-index: 1;
      margin-top: 65px;

      @include laptop {
        width: 873px;
      }

      @include tablet {
        width: 606px;
        margin-top: 76px;
      }

      @include mobile {
        width: 100%;
        margin-top: 127px;
      }
    }

    &-bg {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }

    &-title {
      z-index: 1;
      width: 654px;
      text-align: center;
      font-family: $font-secondary;
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 150%; /* 45px */
      text-transform: uppercase;
      background: linear-gradient(90deg, #8850FF 26.2%, rgba(251, 80, 255, 0.75) 116.37%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: -20px 0 0;

      @include mobile {
        width: 100%;
        padding: 0 40px;
        margin: 0;
      }
    }
  }

  &-rewards {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;

    @include laptop {
      padding: 0 26px;
    }

    &-wrapper {
      width: 100%;
      // background: $deep-night;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      min-height: 925px;
    }

    &-tab {
      &-wrapper {
        display: flex;
        align-items: center;
        gap: 17px;
        margin-top: 30px;
      }

      &-container {
        display: flex;
        width: 138px;
        height: 41px;
        padding: 2px;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: 8px;
        border: 1px solid $bg-light;
        background: $light;
      }
    }

    &-decoration {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 0;
    }

    &-title {
      margin: 85px 0 0 0;
      color: $headline-white;
      font-family: $font-secondary;
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 1;
      text-transform: uppercase;

      @include laptop {
        margin-top: 44px;
      }

      @include mobile {
        font-size: 25px;
        margin-top: 27px;
      }
    }

    &-subtitle {
      margin: 27px 0 0 0;
      color: $primary;
      font-family: $font-secondary;
      font-size: 75px;
      font-style: normal;
      font-weight: 800;
      line-height: 75px;
      text-transform: uppercase;

      @include mobile {
        font-size: 42px;
        margin-top: 15px;
      }
    }

    &-all-bonus {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;

      @include laptop-max {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        gap: 20px 35px;
      }
    }

    &-bonus {
      width: 405px;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      background: transparent;

      @include mobile {
        min-width: 333px;
        max-width: calc(100% - 40px);
      }

      &--bordered {
        border-radius: 8px;
        min-width: 30%;
        border: 1px solid rgba($stroke-light, 0.20);
        background: $block-light;
      }

      &--horizontal {
        margin-top: 24px;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        @include laptop-max {
          padding: 31px 10px;
          flex-flow: column;
          width: 315px;
          min-height: 456px;
        }

        &-caption {
          bottom: -32px;
        }
      }

      &-list {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: center;
        gap: 32px;

        @include laptop {
          column-gap: 32px;
          overflow-x: scroll;
          margin-bottom: 55px;
        }

        @include laptop-max {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: flex-start;
          gap: 32px;
        }
        @include mobile {
          gap: 24px
        }

        &--horizontal {
          @include laptop {
            flex-flow: row nowrap;
          }
        }

      }

      &-wrapper {
        width: 100%;
        padding: 24px 24px 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        margin: 31px 0 27px;
        border: 1px solid rgba($stroke-light, 0.20);
        background: $block-light;

        @include laptop {
          margin: 42px 0;
          max-width: 679px;
        }
        @include tablet {
          margin: 32px 0;
          max-width: 679px;
        }

        @include mobile {
          margin: 50px 0;
          max-width: calc(100% - 40px);
          padding: 32px 16px 0;
        }

        &-background {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 0;
        }

        &-empty {
          width: 100%;
          height: 246px;
          border-radius: 20px;
          font-size: 50px;
        }
      }

      &-content {
        padding: 0 24px 0;

        @include mobile {
          padding: 0 17px 0;
        }
      }

      &-img {
        max-width: 100%;
        max-height: 100%;

        &-wrapper {
          width: 100%;
          height: 100%;
          max-height: 129px;
          background: $light;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;

          &.small {
            max-width: 295px;
            height: 110px;
          }

          &.full {
            height: 120px;
          }
        }
      }

      &-name {
        color: rgba($secondary, .75);
        margin: 24px 0 0 0;
        font-family: $font-secondary;
        font-size: 22px;
        font-style: normal;
        font-weight: 800;
        line-height: 1;
        text-transform: uppercase;
        text-align: center;
      }

      &-separator {
        width: 100%;
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        &:before, &:after {
          content: ' ';
          position: absolute;
          width: calc(50% - 28px);
          height: 1px;
          background: rgba($stroke-light, 0.20);;
        }

        &:before {
          left: 0;
          top: 32px;
        }

        &:after {
          right: 0;
          top: 32px;
        }

        img {
          margin: 16px 16px 8px;
        }
      }

      &-tips {
        gap: 16px;
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
        width: 572px;

        @include laptop-max {
          width: 100%;
          min-width: unset;
          display: grid;
          justify-content: center;
          grid-template:
                  "A A"
                  "A A";
        }

        &-text {
          max-width: 131px;

          @include laptop-max {
            max-width: unset;
            width: 100% !important;
          }
        }
      }

      &-text {
        min-height: 60px;
        min-width: 100px;
        padding: 7px 6px;
        margin-top: 24px;
        border-radius: 4px;
        width: calc(50% - 12px);
        border: 1px solid rgba($stroke-light, 0.20);
        color: rgba($secondary, .75);
        font-family: $font-primary;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        line-height: 150%;
        text-transform: uppercase;
        text-align: center;
        white-space: nowrap;

        @include mobile {
          padding: 8px;
        }
      }

      &-btn {
        margin-top: 24px;
        width: 100%;
        height: 48px;
        text-transform: uppercase;
        position: relative;

        &-with-caption {
          position: relative;
          margin: 8px;
          cursor: pointer;
          text-transform: capitalize;
          color: rgba($faded, 0.40);
          font-family: $font-primary;
          font-size: 12px;
          font-weight: 500;
          line-height: 150%;

          &:hover + .bonuses-rewards-bonus-btn-with-caption-tooltip {
            visibility: visible;
          }

          &-tooltip {
            position: absolute;
            bottom: 100px;
            display: flex;
            padding: 10px;
            justify-content: center;
            align-items: center;
            visibility: hidden;
            z-index: 2;
            border-radius: 14px;
            background: rgba(0, 0, 0, 0.80);
            color: var(--text, var(--text, rgba(226, 241, 255, 0.75)));
            text-align: center;
            font-family: $primary;
            font-size: 12px;
            font-weight: 500;
            line-height: 150%;
          }

          &--horizontal {
            width: 300px;
            bottom: -20px;
            right: 220px;

            @include laptop-max {
              width: 100%;
              bottom: 60px;
              right: 0;
            }
          }
        }

        &.small {
          width: 207px;
          @include laptop-max {
            margin: 0;
          }
        }
      }

      &--small {
        width: 357px;

        @include mobile {
          width: 100%;
          min-width: unset;
          max-width: unset;
        }
      }
    }

    &-promotion {
      margin-top: 61px;

      @include mobile {
        margin-top: 28px;
      }

      &--small {
        margin-top: 26px;
        width: calc(50% - 12px);

        @include laptop {
          width: calc(50% - 7px);
          margin-top: 70px;
        }

        @include mobile {
          width: 100%;
          margin-top: 15px;
        }

        .bonuses-rewards-promotion-text {
          width: 326px;

          @include laptop-max {
            font-size: 14px;
            width: 277px;
            font-weight: 500;
            text-transform: none;
          }
        }
      }

      &-wrapper {
        max-width: 839px;
        padding: 42px 0;

        @include laptop-max {
          max-width: 666px;
        }

        @include mobile {
          padding: 38px 33px;
        }
      }

      &-content {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: space-between;

        @include mobile {
          flex-flow: column nowrap;
        }
      }

      &-title {
        margin: 15px 0 0;
        color: $headline-white;
        font-family: $font-secondary;
        font-size: 22px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-transform: uppercase;

        @include laptop-max {
          font-size: 22px;
        }
      }

      &-text {
        width: 459px;
        margin: 24px 0 auto;

        color: rgba($secondary, 0.75);
        text-align: center;
        font-family: $font-primary;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */

        @include mobile {
          width: auto;
        }
      }

      &-img {
        width: 170px;
        height: 160px;
        margin-bottom: -17px;

        @include laptop-max {
          height: 150px;
        }
      }

      &-btn {
        margin-top: 51px;
      }
    }
  }

  &-earn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 44px;
    padding-bottom: 84px;
    z-index: 1;
    @include laptop {
      padding: 63px 0 116px;
    }
    @include tablet-max {
      padding: 63px 0 74px;
    }
    @include mobile {
      padding: 38px 0 35px;
    }

    &-wrapper {
      width: 100%;
      background: $deep-night;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      @include wide {
        margin-bottom: 84px;
      }
    }

    &-decoration {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
    }

    &-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
      color: $headline-white;
      font-family: $font-secondary;
      font-size: 30px;
      font-weight: 800;
      line-height: 100%;

      @include mobile {
        font-size: 25px;
        font-weight: 800;
      }

      &-subtitle {
        font-family: $font-secondary;
        color: $primary;
        font-size: 75px;
        font-weight: 800;
        margin: 0;

        @include mobile {
          font-size: 42px;
        }

        &-gold {
          color: $orange;
        }
      }
    }


    &-section {
      display: flex;
      justify-content: center;
      gap: 24px;
      margin-top: 110px;

      @include laptop-max {
        margin-top: 45px;
      }
      @include tablet-max {
        flex-direction: column;
        align-items: center;
      }
      @include mobile {
        gap: 28px;
      }
    }
  }
}

.sign-up {
  display: flex;

  @include laptop-max {
    flex-direction: column;
    align-items: center;
    margin: 133px 0 89px;
  }
  @include tablet {
    margin: 113px 0 40px;
  }

  @include mobile {
    margin: 48px 0 21px;
  }

  &-img {
    width: 414px;
    height: 410px;
    margin-right: 55px;

    @include laptop-max {
      margin: 0;
    }

    @include mobile {
      width: 318px;
      height: 315px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @include laptop-max {
      margin-top: 66px;
    }
  }

  &-title {
    margin: 8px 0 0;

    @include mobile {
      &.shared-headline--small {
        font-size: 25px;
      }
    }
  }

  &-subtitle {
    @include mobile {
      &.shared-headline {
        font-size: 30px;
      }
    }

    span {
      background: var(--GRANDIENT, linear-gradient(90deg, #8850FF 26.2%, rgba(251, 80, 255, 0.75) 116.37%));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &-cta {
    margin-top: 15px;
  }

  &-avatar {
    width: 100px;
    height: 100px;
    border-radius: 100px;

    @include mobile {
      width: 75px;
      height: 75px;
    }
  }

  &-congrats {
    margin-top: 28px;

    &.shared-headline--small {
      font-size: 22px;
    }

    span {
      background: var(--GRANDIENT, linear-gradient(90deg, #8850FF 26.2%, rgba(251, 80, 255, 0.75) 116.37%));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &-caption {
      color: rgba($daylight, 0.75) !important;

      @include mobile {
        &.shared-text {
          font-size: 14px;
        }
      }
    }
  }
}

.margin {
  margin-right: 32px !important;

  @include laptop-max {
    margin: 0 !important;
  }
}
