@import "styles/variables";
@import "styles/mixins";

.admin-users {
  background: $deep-night;

  &-dashboard {
    display: flex;
    width: calc(100% - 50px);
    max-width: 1211px;
    min-height: 96px;
    padding: 28px 10px 26px 10px;;
    justify-content: center;
    align-items: center;
    margin: 83px 20px 0 30px;

    background: $admin-table;
    border: 1px solid rgba($stroke-light, 0.20);
    border-radius: 10px;

    &-cell {
      height: 94px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;

      &-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;

        color: rgba($secondary, 0.75);
        font-family: $font-primary;
        font-size: 20px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
      }

      &:not(:last-child) {
        border-right: 1px solid rgba($stroke-light, 0.20);
      }
    }
  }

  &-search {
    width: 1000px;
    max-width: calc(100% - 60px);
    margin-right: 20px;

    &-label {
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 21px */
      text-transform: uppercase;
      margin-bottom: 17px;
    }

    &-input {
      &-wrapper {
        height: 40px;
        border-radius: 58px !important;
        border: 1px solid $block-light !important;
        background: $deep-night !important;
        overflow: hidden;

        .input-group-text {
          background-color: inherit;
          border: 0;
          border-radius: 0;
        }

        input {
          width: calc(100% - 40px);
          background-color: inherit;
          border: 0;
          border-radius: 0;
          padding-left: 0;
          color: rgba($secondary, 0.75);
          font-family: $font-primary;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          outline: none;

          &::placeholder {
            color: inherit;
            font-family: inherit;
            font-size: inherit;
            font-style: inherit;
            font-weight: inherit;
            line-height: inherit;
          }
        }
      }

    }
  }

  &-table {
    margin-top: 24px;

    &-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    &-cell {
      &--username {
        width: 150px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &--joined {
        width: 98px;
      }

      &--point-spent {
        width: 148px;
      }

      &--point-balance {
        width: 130px;
      }
    }
  }

  &-content {
    max-width: 1211px;
    margin: 22px 20px 0 30px;
    width: calc(100% - 50px);

    &-cta {
      display: flex;
      width: 167px;
      min-width: 167px;
      height: 40px;
      padding: 9px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid rgba($stroke-light, 0.20);
      background: $block-light;

      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 21px */
      text-transform: uppercase;

      img {
        margin-right: 6px;
        width: 24px;
        height: 24px;
        padding: 7px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
        border: 1px solid rgba($secondary, 0.15);
        background: rgba($secondary, 0.05);
      }
    }

    &-footer {
      margin-top: 27px;

      &-caption {
        color: rgba($secondary, 0.75);
        font-family: $font-primary;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 21px */
        text-transform: uppercase;
      }
    }
  }
}
