@font-face {
  font-family: "Rubik";
  src: url("./Rubik/Rubik-Black.ttf") format("truetype"),
  url("./Rubik/Rubik-Bold.ttf") format("truetype"),
  url("./Rubik/Rubik-ExtraBold.ttf") format("truetype"),
  url("./Rubik/Rubik-Light.ttf") format("truetype"),
  url("./Rubik/Rubik-Medium.ttf") format("truetype"),
  url("./Rubik/Rubik-Regular.ttf") format("truetype"),
  url("./Rubik/Rubik-SemiBold.ttf") format("truetype"),
}



