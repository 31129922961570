// Color system

$primary: #8850ff;
$secondary: #e2f1ff;
$dark: #08101b;
$light: #131a2d;
$raffle-bg: #192037;
$success: #6bcf48;
$warning: #ff3c3c;
$bg-light: #1e2737;
$bg-dark: #08101b;
$bg-dark-rich: #0b0e16;
$gradient-gold: linear-gradient(106deg, #f09815 -39.33%, #f6f019 47.23%, #f09815 101.54%);
$leaderboard-gold: linear-gradient(180deg, #ffef5c 0%, #fda624 100%);
$gradient-silver: linear-gradient(106deg, #585857 -39.33%, #d7d6d1 47.23%, #858380 101.54%);
$gradient-bronze: linear-gradient(106deg, #bd7874 -39.33%, #fccea4 47.23%, #ffa15e 101.54%);

$purple: #7942ee;
$c_theme-colors: (
  light: $bg-light,
  dark: $bg-dark
);

$faded: rgb(226, 241, 255);

$facebook: #50abff;
$kick: #70e343;
$kick-secondary: #7cfa92;
$youtube: #f52c00;
$instagram: linear-gradient(155deg, #8850ff 6.36%, #eb0082 53.01%, #f6b700 84.26%);
$discord: #5865f2;

$white: #fff;
$black: #000;

$blue: #4f74f4;
$orange: #f1a718;
$gold: #f09815;
$yellow: #f9e532;
$cyan: #4291ee;
$purple-light: #a39ada;

$headline-white: #e0edfa;
$headline-color: #e0edfa;

$admin-table: #131b26;
$block: #242746;
$block-light: #1c243b;
$block-hover: #08101c;
$gray-light: #2a3350;
$silver: #585857;
$bronze: #bd7874;
$green: #70e343;
$prize-green: #19b016;
$expansive-green: #5ebd3d;
$stroke-light: #9b9b9b;
$orange-accent: #f1a718;

$deep-night: #0b1523;
$points-dark: #090d16;
$dark-night: #0b101b;
$daylight: #d2eaff;

$red: #d43243;
$ban-red: #a44444;

$grey: #acbac8;

// Accordion
$accordion-button-active-color: $primary;
$accordion-button-color: $secondary;
$accordion-button-bg: $bg-light;
$accordion-button-active-bg: $bg-light;

$font-primary: 'Montserrat', sans-serif;
$font-secondary: 'roc-grotesk', sans-serif;
$font-4: 'RocGrotesk', sans-serif;
$font-tertiary: 'Rubick', sans-serif;
$font-roboto: 'Roboto', sans-serif;
