@import "styles/variables";

.rating-selector {
  display: flex;
  cursor: pointer;
  align-items: flex-start;
  padding-right: 12px;

  &-wrapper {
    display: flex;
    align-items: center;
  }

  &-star {
    color: rgba($secondary, 0.20);
    transition: color 0.5s;

    &-hover {
      color: $yellow;
    }
  }
}
