@import 'styles/variables';
@import 'styles/mixins';

.admin-raffles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  padding: 75px 0 0;

  &--ended {
    padding-top: 30px;
  }

  &-wrapper {
    padding: 0 35px;
  }

  &-list {
    &-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 32px;
      margin-top: 35px;

      @media screen and (max-width: #{1621px}) {
        max-width: 960px;
      }

      @include laptop {
        max-width: 624px;
      }

      @include tablet-max {
        max-width: 296px;
      }
    }
  }

  &-search {
    &-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 28px;
      gap: 35px;
    }
  }

  &-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 42px;
  }

  &-cta {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 28px;
    gap: 18px;

    &-btn {
      &-add.shared-btn-primary {
        display: flex;
        width: 190px;
        height: 48px;
        padding: 0 16px;
        border-radius: 4px;
      }

      &-history.shared-btn-secondary {
        display: flex;
        width: 190px;
        height: 48px;
        padding: 0 16px;
        border-radius: 0;
        gap: 8px;
      }

      &-edit.shared-btn-secondary {
        display: flex;
        width: 100%;
        height: 48px;
        padding: 0 16px;
        border-radius: 0;
        gap: 8px;
      }

      &-view.shared-btn-primary {
        display: flex;
        width: 100%;
        height: 48px;
        padding: 0 16px;
      }
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 30px;
    }
  }

  &-modal {
    &-input {
      min-width: unset !important;

      &::placeholder {
        color: #e0edfa !important;
      }

      &-time-wrapper {
        display: flex;
        gap: 18px;
      }

      &-with-switch {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 18px;
        margin-top: 18px;
      }

      &-unlimited {
        &:before {
          position: absolute;
          top: 10px;
          left: 16px;
          text-align: center;
          height: 20px;
          content: 'UNLIMITED';
          color: $primary;
          font-family: $font-primary;
          font-size: 14px;
          font-weight: 600;
          line-height: 150%;
          background: $block-hover;
          z-index: 2;
        }
      }
    }

    &-switch {
      width: 42px;
      height: 24px;
      padding: 0 !important;
    }

    &-edit {
      &-input.shared-input {
        position: relative;
        outline: none;
        border: 1px solid $primary;
        z-index: 1;

        &:disabled {
          color: rgba($secondary, 0.4) !important;
          border: none;
        }

        .shared-input-label {
          color: $primary;
        }

        .shared-input-label:before {
          background: $light;
        }
      }

      &-img-uploader {
        border: 1px solid $primary;
      }
    }
  }

  &-current {
    width: 100%;
    max-width: 1040px;
    min-width: 770px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $deep-night;
    margin-top: 20px;
    padding: 0 20px;

    @include tablet {
      min-width: 430px;
    }

    @include mobile {
      min-width: 315px;
    }
  }

  &-ended {
    &-table {
      &-collapse {
        padding: 0 12px;

        &-content {
          width: 200px;
          display: flex;
          padding-left: 15px;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;
        }

        &-trigger {
          margin-top: 16px;
        }
      }

      &-cell {
        &-wrapper {
          align-items: flex-start !important;
          border-left: 0 !important;
          border-color: rgba($faded, 0.4) !important;
        }

        min-height: 56px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }

      &-winners {
        min-width: 254px;
        max-width: 254px;

        &-column {
          min-width: 232px;
          max-width: 232px;
        }
      }

      &-id {
        max-width: 100px;

        &-column {
          max-width: 78px;
        }
      }

      &-winnerCount {
        max-width: 78px;

        &-column {
          max-width: 122px;
        }
      }
    }
  }

  &-entries {
    &-table {
      &-row {
        border-radius: 10px;
        background: $admin-table;
      }
    }
  }
}
