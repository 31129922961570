@import 'styles/variables';
@import 'styles/mixins';

.admin-loot-box-transactions {
  // max-width: 1280px;
  // margin: 0 auto;
  &-wrapper {
    max-width: 1211px;
    margin: 0 auto;
  }

  max-width: 1211px;
  margin: 22px 20px 0 30px;
  width: calc(100% - 50px);

  &-tabs {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    &-item {
      display: flex;
      height: 40px;
      padding: 8px 24px;
      align-items: center;
      justify-content: center;
      border: 1px solid $light;
      background: $light;
      color: $headline-color;
      font-family: $font-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 21px */
      text-transform: uppercase;
      transition: all linear 0.3s;

      &.active {
        border-color: $primary;
        background: rgba($purple, 0.25);
      }

      &:nth-child(1) {
        border-radius: 4px 0 0 4px;
      }

      &:nth-last-child(1) {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  &-title {
    margin: 32px 0 24px;
  }

  &-select {
    width: 235px;
    min-width: 235px;

    &-text.shared-text {
      color: rgba($headline-color, 0.75);
    }
  }

  &-table {
    &-row {
      border-radius: 4px;
      margin-bottom: 16px;
      border: 1px solid rgba($stroke-light, 0.05);
      background: rgba($stroke-light, 0.05);
    }

    &-cell {
      padding: 0 5px;
      border-right: 0 !important;
      border-color: rgba($faded, 0.4) !important;
    }

    &-id {
      max-width: 124px;
    }

    &-date {
      max-width: 150px;
    }

    &-date {
      max-width: 150px;
      min-width: 80px;
    }

    &-user {
      margin: 0;
      white-space: nowrap;
      max-width: 150px;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    &-item {
      margin: 0;
      white-space: nowrap;
      max-width: 150px;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    &-price {
      max-width: 160px;

      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }

    &-status {
      min-width: 200px;
      padding: 0 20px;

      &-text {
        margin-right: 20px;
      }

      &-btn.shared-btn {
        margin-left: auto;
        width: 98px;
        padding: 0;
        height: 28px;
        border-radius: 7px;
        font-size: 12px;
      }
    }
  }

  &-modal {
    &-content {
      width: calc(100% - 96px - 24px);

      &-name.shared-text {
        color: rgba($secondary, 0.75);
      }
    }

    &-item {
      gap: 25px;

      &-image {
        max-width: 100%;
        max-height: 100%;

        &-wrapper {
          width: 142px;
          height: 142px;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
        }
      }

      &-content {
        gap: 16px;
        width: calc(100% - 142px - 25px);
      }

      &-title {
        color: $headline-color;
        font-family: $font-primary;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        text-transform: uppercase;
      }

      &-description {
      }

      &-price {
        &-cell {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          height: 32px;
          padding: 0 16px;
          margin-left: 14px;
          border-radius: 44px;
          gap: 8px;
          border: 1px solid rgba($secondary, 0.75);
          font-family: $font-primary;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          color: rgba($secondary, 0.75);
          line-height: 150%; /* 24px */
          text-transform: uppercase;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    &-reason {
      p {
        color: $ban-red;
        font-family: $font-primary;
        font-size: 12px;
        margin-bottom: 6px;
        font-weight: 600;
        line-height: 30px; /* 250% */
        text-transform: uppercase;
      }

      &-block {
        min-height: 40px;
        width: 100%;
        background: $deep-night;
        border-radius: 4px;
        padding: 16px;
        color: rgba($faded, 0.4);
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
      }
    }

    &-cta {
      &-approve {
        border-radius: 4px;
        color: $admin-table;
        border: 1px solid rgba($stroke-light, 0.2);
        background: $prize-green;
      }

      &-reject {
        border-radius: 4px;
        color: $admin-table;
        border: 1px solid rgba($stroke-light, 0.2);
        background: $ban-red;
      }

      &-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 36px;
      }
    }

    &-update {
      &-footer {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        gap: 51px;

        &-btn {
          height: 40px;
          width: 172px;
        }
      }
    }
  }
}
