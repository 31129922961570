@import "styles/variables";
@import "styles/mixins";

.custom-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba($stroke-light, 0.20);
  background: $block-light;
  padding: 56px 58px 24px 58px;

  @include laptop-max {
    padding: 37px 48px 42px 32px;
  }

  @include tablet-max {
    padding: 37px 32px 42px;
  }

  @include mobile {
    padding: 35px 25px 24px 25px;
  }

  &-wrapper {
    padding: 0;
    display: flex;
    width: 100%;

    @include laptop {
      padding: 0 131px 0;
    }
    @include tablet-max {
      padding: 0;
    }
  }

  &-content {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    &-explore {
      gap: 13px;
      @include laptop-max {
        margin-left: 41px;
      }
      @include mobile {
        margin: 47px 0 0;
      }
    }

    &-about {
      gap: 13px;

      :nth-child(n) {
        display: flex;
        flex-direction: column;
        gap: 13px;
      }

      @include laptop-max {
        margin-left: 31px;
      }
      @include mobile {
        margin: 60px 0 0;
        gap: 60px;
      }
    }

    &-socials {
      div {
        margin-top: 19px;
      }

      @include mobile {
        margin-top: 53px;
      }
    }
  }

  &-logo {
    height: 48px;

    @include mobile {
      width: 30px;
      height: 30px;
    }

    &-text {
      margin-left: 16px;
      color: rgba($secondary, 0.75);
      font-family: $font-secondary;
      font-size: 55px;
      font-weight: 800;
      line-height: 100%;

      @include mobile {
        font-family: $font-secondary;
        font-size: 25px;
        font-weight: 800;
        margin-top: 5px;
      }
    }
  }

  &-social {
    width: 40px;
    height: 40px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    border: 1px solid $block;
    border-radius: 10px;
    color: rgba($secondary, 0.4);

    &:hover {
      border-color: rgba($secondary, 0.75);
      color: rgba($secondary, 0.75);
    }
  }

  &-title {
    margin: 0;
    color: rgba($secondary, 0.75);
    font-family: $font-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
  }

  &-link {
    text-decoration: none;
    color: rgba($secondary, 0.4);
    font-family: $font-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;

    &:hover {
      color: $headline-white;
    }
  }

  &-text.shared-text {
    margin-top: 16px;
    max-width: 407px;
    color: rgba($stroke-light, 0.20);

    @include laptop-max {
      width: 352px;
    }
    @include mobile {
      width: 303px;
      margin-top: 30px;
    }
  }

  &-separator {
    margin: 53px 0 16px;
    border-top: 1px solid rgba($stroke-light, 0.20);
    @include laptop {
      margin: 16px 45px 0 16px;
    }
    @include tablet-max {
      margin: 16px 56px 0 28px;
    }
    @include mobile {
      margin: 20px 0 0 0;
    }
  }

  &-caption.shared-text {
    margin: 0;
    color: rgba($stroke-light, 0.20);

    @include laptop-max {
      margin-top: 20px;
    }
    @include mobile {
      width: 100%;
      text-align: justify;
    }
  }
}
