@import "styles/variables";

.admin {
  &-wrapper {
    background: $deep-night;
  }

  &-header {
    max-width: unset;
  }

  &-aside {
    display: flex;
    width: 240px;
    height: calc(100vh - 80px);
    flex-direction: column;
    align-items: flex-start;
    background: $light;
  }

  &-content {
    background: $deep-night;
    height: calc(100vh - 80px);
    width: calc(100% - 245px);
    overflow: auto;
  }
}
