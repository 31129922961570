@import 'styles/variables';
@import 'styles/mixins';

.admin-bonuses {
  background: $deep-night;
  padding: 54px 16px 205px;

  &-tab {
    height: 35px;
    padding: 0 14px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;

    color: rgba($secondary, 0.5);
    font-family: $font-primary;
    font-size: 12px;
    font-weight: 500;
    line-height: 100%;

    &-active {
      height: 35px;
      padding: 0 17px;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background: $deep-night;
      border: none;
      color: $secondary;
    }

    &-wrapper {
      width: 100%;
      display: flex;
      padding: 0 0 30px;
    }

    &-container {
      display: flex;
      width: 138px;
      height: 41px;
      padding: 2px;
      justify-content: flex-start;
      align-items: flex-start;
      border-radius: 8px;
      border: 1px solid $bg-light;
      background: $light;
    }
  }

  &-section {
    padding-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ul {
      margin: 0;
      padding: 0;
    }

    &-btn {
      width: 405px;
      height: 404px;
      border-radius: 8px;
    }

    &-top-bonuses {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 32px;
    }

    &-title {
      margin: 0;
      color: $headline-white;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 100%;
      text-transform: uppercase;
    }

    &-cta {
      display: flex;
      width: 100%;
      height: 100%;
      gap: 16px;
      padding: 9px 16px;
      justify-content: center;
      align-items: center;
      border-radius: inherit;
      border: 1px dashed rgba($daylight, 0.25);
      background: rgba($secondary, 0.05);

      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: inherit;
      font-weight: 600;
      line-height: 100%;

      &--big {
        gap: 40px;
      }

      &-img {
        width: 39px;
        height: 39px;
        padding: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
        border: 1px solid rgba($secondary, 0.15);
        background: rgba($secondary, 0.05);

        &--big {
          padding: 18px;
          width: 54px;
          height: 54px;
        }
      }

      &-wrapper {
        &-allBonuses {
          width: 100%;
          height: 112px;
          border-radius: 8px;
          margin-top: 24px;

          @include laptop-max {
            max-width: 315px;
            height: 456px;
          }
        }
      }
    }
  }

  &-btn {
    display: flex;
    align-items: flex-end;
    padding: 0;
    background: transparent;
    border: none;

    &-wrapper {
      display: flex;
      gap: 13px;
    }

    &-group {
      height: 20px;
      position: absolute;
      display: flex;
      gap: 13px;
      right: 10px;
      top: 6px;
    }

    &-edit {
      width: 17px;
      height: 17px;
    }

    &-delete {
      width: 20px;
      height: 20px;
    }
  }

  &-modal {
    &-content {
      width: 402px;
      margin: 0 auto 32px;
    }

    &-title {
      margin: 16px 16px 0 !important;
    }

    &-text {
      color: $secondary;
      font-family: $font-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 21px */
      text-transform: uppercase;
      margin: 24px 0 0;

      &-error {
        color: $red;
      }
    }

    &-space {
      margin-top: 18px;
    }

    &-alert {
      margin-top: 18px;
      padding-top: 6px;
    }

    &-submit {
      margin-top: 24px;
      height: 40px !important;
    }
  }
}
