@import "styles/variables";

.header-user {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  height: 61px;

  &-login {
    color: $headline-white;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    height: 51px;
    padding: 10px 32px;
  }

  &-admin {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  &-avatar {
    width: 48px;
    height: 48px;
    border-radius: 108px;
    border: 2px solid $dark-night;
    margin: 0 16px 0 9px;
    cursor: pointer;

    &-mobile {
      width: 28px;
      height: 28px;
      font-size: 8px;
      margin: 0 4px 0 0;
    }
  }

  &-name {
    max-width: 196px;
    color: $headline-white;
    font-family: $font-primary;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.32px;
    margin-bottom: 4px;
  }

  &-balance {
    display: flex;
    flex-flow: row;
    height: 32px;
    width: 88px;
    gap: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: rgba($block, 0.50);

    p {
      color: rgba($daylight, 0.75);
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
    }

    &-mobile {
      background: $deep-night;
      padding: 8px;
    }
  }

  &-menu {
    position: absolute;
    top: -20px;
    right: 0;
    width: 256px;
    padding: 4px 0;
    background: $light;
    border-radius: 4px;
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden;
    transition: 0.3s;
    font-family: $font-primary;
    color: rgba($secondary, 0.75);
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    text-transform: uppercase;
    z-index: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 48px;
      padding: 0 16px;

      &:hover {
        color: $primary;
        background: $block-hover;
      }
    }

    &-active {
      top: 56px;
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }

    &-btn {
      display: inline-flex;
      height: 32px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid rgba($daylight, 0.05);
      background: transparent;
      margin-left: 16px;

      &-img {
        transform: rotate(0);
        transition: all 0.3s linear;

        &-active {
          transform: rotate(-180deg);
        }
      }
    }
  }
}
