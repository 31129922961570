@import "styles/variables";

.copy-button {
  gap: 8px;
  border-radius: 6px;
  width: 110px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 34px;
  background: transparent;
  border: 1px solid rgba($stroke-light, 0.20);
  color: rgba($faded, 0.40);
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;

  &-icon {
    &--copy {
      width: 24px;
    }

    &--check {
      width: 19px;
    }
  }
}
