@import "styles/variables";
@import "styles/mixins";

.custom-slot-card {
  width: 191px;
  height: 266px;
  border-radius: 6px;
  background: $light;

  &-title {
    h4 {
      color: $secondary;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      margin: 12px 16px 6px 16px;
    }

    h5 {
      color: rgba($secondary, 0.75);
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 600;
      line-height: 150%;
      margin: 0 16px 2px 16px;
    }
  }

  &-stars {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 0 16px 15px 16px;
  }

  &-star-icon {
    margin-right: 8px;
  }
}
