@import 'styles/variables';
@import 'styles/mixins';

.lt-warning {
  color: $warning;
  text-align: center;
  padding-top: 15px;
  text-transform: none;
}

.admin-loot-boxes {
  padding: 0 50px 88px;
  position: relative;

  @include laptop {
    padding: 0 0 88px;
  }

  &-page {
    width: 100%;
    display: flex;

    &-container {
      padding: 10px;
      &.lb-case {
        flex: content;
        max-width: 400px;

        .shared-input-wrapper {
          min-width: auto;
        }
      }
      &.lb-items {
        flex: auto;
        min-height: 500px;
      }
    }

    @include tablet {
      display: block;
    }
  }

  &-case-edit-form {
    padding: 10px;
  }

  &-header {
    color: $headline-white;
    font-family: $font-primary;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 20px;
  }

  &-btn.shared-btn-primary {
    height: 40px;
    display: flex;
    width: 156px !important;
    padding: 0 10px !important;
    &.footer-btn {
      position: absolute;
      right: 0;
    }

    &:disabled {
      cursor: not-allowed;
      background: rgba($white, 0.3);
      box-shadow: 0 1px 1px 0 rgba($white, 0.25) inset;
    }
  }

  &-list-padding {
    padding: 24px 0 36px;

    &.lb-case {
      padding: 0;
    }

    @include laptop {
      padding: 24px 50px 36px;
    }
  }

  &-list {
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }

  &-icon {
    &-add {
      width: 24px;
      height: 24px;
      padding: 6px;
      margin-right: 8px;
      border-radius: 32px;
      border: 1px solid rgba($faded, 0.4);
    }
  }

  &-case {
    &-add-items {
      &-modal {
        width: 800px;
        &-content {
          padding: 10px 40px;
        }
      }
    }
  }

  &-input {
    min-width: unset !important;

    &-label {
      z-index: 1;
      min-height: 40px;
      border: 1px solid #08101c;
      color: #e0edfa;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      display: grid;
      align-items: center;
      text-transform: uppercase;
    }

    &::placeholder {
      color: #e0edfa !important;
    }

    &-time-wrapper {
      display: flex;
      gap: 18px;
    }

    &-with-switch {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 18px;
      margin-top: 18px;
    }

    @mixin disabled-input($content) {
      position: absolute;
      top: 10px;
      left: 16px;
      text-align: center;
      height: 20px;
      content: $content;
      color: $primary;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      background: $block-hover;
      z-index: 2;
    }

    &-disabled {
      &:before {
        @include disabled-input('DISABLED');
      }
    }

    &-unlimited {
      &:before {
        @include disabled-input('UNLIMITED');
      }
    }

    &-free {
      &:before {
        @include disabled-input('FREE');
      }
    }
  }
}
