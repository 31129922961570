@import "styles/variables";
@import "styles/mixins";

.custom-leaderboard {

  &-table {
    display: flex;
    flex-direction: column;
    width: 1009px;
    gap: 10px;

    @include laptop {
      width: 769px;
    }

    @include tablet {
      width: 604px;
    }

    @include mobile {
      width: 360px;
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px 0 40px;
      margin-bottom: 8px;

      @include mobile {
        padding: 0 10px 0 50px;
      }

      h3 {
        color: rgba($secondary, 0.75);
        font-family: $font-primary;
        font-size: 12px;
        font-weight: 600;
        line-height: 100%;
        text-transform: uppercase;
        margin: 0;
      }
    }
  }

  &-btn {
    width: 174px;
    height: 48px;
    margin-top: 30px;
  }
}
