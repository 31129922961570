@import "styles/variables";

.search {
  width: 100%;

  &-input {
    &-wrapper {
      height: 40px;
      border-radius: 58px !important;
      border: 1px solid $block-light !important;
      background: $deep-night !important;
      overflow: hidden;

      .input-group-text {
        background-color: inherit;
        border: 0;
        border-radius: 0;
      }

      input {
        width: calc(100% - 40px);
        background-color: inherit;
        border: 0;
        border-radius: 0;
        padding-left: 0;
        color: rgba($secondary, 0.75);
        font-family: $font-primary;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        outline: none;

        &::placeholder {
          color: inherit;
          font-family: inherit;
          font-size: inherit;
          font-style: inherit;
          font-weight: inherit;
          line-height: inherit;
        }
      }
    }

  }
}