.stream-draggable {
  width: 350px;
  z-index: 999;
  position: fixed;
  height: 225px;
  border-radius: 8px 0 8px 8px;
  background: #2a2a3d;
  filter: drop-shadow(4px 4px 16px rgba(4, 4, 26, .16)) drop-shadow(-4px 4px 16px rgba(4, 4, 26, .16)) drop-shadow(4px 12px 24px rgba(4, 4, 26, .16));

  &-control {
    border-radius: 8px 8px 0 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-100%);
    display: flex;
    gap: 6px;
    justify-content: flex-end;
    align-items: center;
    background: #2a2a3d;
    padding: 6px;
    width: 66px;
    margin-left: auto;
    margin-right: 0;
    z-index: 35;

    &-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      background: rgba(154, 169, 184, .24);
      border-radius: 4px;
      color: #9aa9b8;
      outline: none;
      border: 0;
    }
  }


  &-handle {
    cursor: move;

    img {
      pointer-events: none;
      transform: rotate(45deg);
      width: 14px;
    }
  }

  &-close {
    img {
      pointer-events: none;
      width: 14px;
    }
  }

  &-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background-color: #776cfa;
    border-radius: 50%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .3);
    cursor: pointer;
    user-select: none;
    outline: none;
    border: 0;

    img {
      pointer-events: none
    }

    &-wrapper {
      position: fixed;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      bottom: 0;
      right: 20px;
      width: 84px;
      height: 84px;
      padding: .5em .5em 1em 1em;
      z-index: 2147483639;
      animation: LiveButton_fadeIn .2s ease forwards
    }
  }
}

@keyframes LiveButton_fadeIn {
  0% {
    bottom: -84px
  }
  to {
    bottom: 0
  }
}
